import styled from 'styled-components'

export const Home = styled.div`
  background-image: linear-gradient(
      rgb(64, 8, 40, 0.48),
      rgb(138, 121, 132, 0.5)
    ),
    url('../assets/home-bg.jpg');
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Backdrop = styled.div`
  padding: 1.5rem;
  color: ${({ theme }) => theme.palette.windowBackground};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 35rem;
  line-height: 2rem;
  align-items: flex-start;
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.palette.primary};
  font-size: 2.5rem;
  margin-bottom: 1rem;
`
