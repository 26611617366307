// QuizForm.styles.ts

import styled from 'styled-components';
import { StyledPaper } from '../../../../ui-kit/Paper/Paper.styles';

export const FormPaper = styled(StyledPaper)`
    padding: 0;
    overflow: hidden;
`;

export const Title = styled.h2`
    font-size: 1.5rem;
`;

export const LessonHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
`;

export const Created = styled.div`
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.contrastLow};
`;

export const LessonTitle = styled.h3`
    font-size: 1.2rem;
`;

export const SubPageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 5px 1px rgb(231, 229, 230, 0.5);
    border-bottom: 1px solid rgb(210 210 210 / 50%);
    padding: 0 1rem;
    height: 5.063rem;
`;

export const Content = styled.div`
    padding: 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Text = styled.div`
    line-height: 1.5rem;
`;

export const Actions = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-left: 16px;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    th, td {
        padding: 0.5rem;
        border: 1px solid ${({ theme }) => theme.palette.stroke};
        text-align: left;
    }

    tbody tr:nth-child(odd) {
        background-color: ${({ theme }) => theme.palette.background};
    }
`;

export const TableTitle = styled.div`
    font-size: 1.2rem;
    margin-top: 1rem;
`;
