import React, { useState, useEffect } from 'react';

import { ListContainer, ListItem, Actions, Button, Header } from './JobTitleList.styles';
import { LuFileEdit, LuTrash, LuPlus } from 'react-icons/lu';
import { JobTitlePopup } from './JobTitlePopup';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useDeleteJobTitleMutation, useGetAllJobTitlesQuery } from '../../../../../slices/jobTitleApiSlice'
import ButtonSecondary from '../../../../../ui-kit/Button/ui/components/ButtonSecondary'

interface JobTitleList {
  id: number;
  name: string;
}

export const JobTitleList: React.FC = () => {
  const { intl } = useIntlContext();
  const { data: jobTitleData, error, isLoading, refetch } = useGetAllJobTitlesQuery({});
  const [deleteJobTitle] = useDeleteJobTitleMutation();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedJobTitle, setJobTitle] = useState<JobTitleList| undefined>(undefined);
  const [mode, setMode] = useState<'create' | 'edit'>('create');

  const handleAdd = () => {
    setJobTitle(undefined);
    setMode('create');
    setIsPopupOpen(true);
  };

  const handleUpdate = (jobTitleData: JobTitleList) => {
    setJobTitle(jobTitleData);
    setMode('edit');
    setIsPopupOpen(true);
  };

  const handleDelete = async (jobTitleDataId: number) => {
    try {
      await deleteJobTitle({ id: jobTitleDataId }).unwrap();
      refetch();
      console.log(`Jobtitle with ID ${jobTitleDataId} deleted successfully.`);
    } catch (error) {
      console.error('Error deleting jobTitle:', error);
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setJobTitle(undefined);
  };

  const handlePublish = () => {
    refetch();
    handleClosePopup();
  };

  if (isLoading) return <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>;
  if (error) return <div>{formatMessage({ id: 'jobTitle.errorLoadingJobTitles', defaultMessage: 'Error loading jobTitles' })}</div>;
  
  return (
    <ListContainer>
      {jobTitleData?.data.map((jobTitleData: JobTitleList) => (
        <ListItem key={jobTitleData.id}>
          <span>{jobTitleData.name}</span>
          <Actions>
            <Button onClick={() => handleUpdate(jobTitleData)}><LuFileEdit size={16} /></Button>
            <Button onClick={() => handleDelete(jobTitleData.id)}><LuTrash size={16} /></Button>
          </Actions>
        </ListItem>
      ))}
      {isPopupOpen && (
        <JobTitlePopup
          jobTitle={selectedJobTitle}
          mode={mode}
          onClose={handleClosePopup}
          onPublish={handlePublish}
        />
      )}
        <Header style={{marginTop: '20px'}}>
        <ButtonSecondary onClick={handleAdd}>
          <LuPlus size={20} />
          {formatMessage({ id: 'jobTitle.newJobTitleButton', defaultMessage: 'New Job Title' })}
        </ButtonSecondary>
      </Header>
    </ListContainer>
  );
};
