import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/sv' // Import Swedish locale
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { FilterDropdown } from '../../ui-kit/Filter/FilterDropdown'
import { Paper, Button } from '../../ui-kit'
import { useGetPersonalPlanQuery } from '../../slices/anualPlanApiSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import ImageUploadWrapper from '../../ui-kit/Form/components/FileUpload/ImageUploadWrapper'
import {
  MonthGrid,
  MonthContainer,
  Entry,
  UploadContainer,
  UploadOverlay,
} from './AnnualPlan.styles'
import { handleImageData } from '../../slices/apiSlice'
import { useUpdateHandbookMutation } from '../../slices/handbooksApiSlice'
import { useGetCompanyAnnualPlanQuery, useUploadAnnualPlanMutation } from '../../slices/companiesApiSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../store' // Import styles

export const AnnualPlan = () => {
  const { intl } = useIntlContext()
  const [months, setMonths] = useState<string[]>([])
  const [showUpload, setShowUpload] = useState(false) // State to manage upload overlay visibility
  const navigate = useNavigate()
  const location = useLocation()
  const { userInfo, userCompany, permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  const { data: planData, refetch } = useGetPersonalPlanQuery({})
  const { data: planFileData, refetch: refetchFile } = useGetCompanyAnnualPlanQuery({})

  useEffect(() => {
    refetch()
    refetchFile()
  }, [location.key])

  useEffect(() => {
    moment.locale(intl.locale) // Set the locale for moment
    const localizedMonths = moment.months().map(month => month.charAt(0).toUpperCase() + month.slice(1))
    setMonths(localizedMonths)
  }, [intl.locale])

  const getMonthEntries = (month: number) => {
    if (!planData?.data) return [] // Return an empty array if planData.data is undefined or null
    return planData.data.filter((entry: any) => moment(entry.started_at).month() === month)
  }

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'course':
        return '#D3D3FF'
      case 'lesson':
        return '#FFB6C1'
      case 'training':
        return '#ADD8E6'
      default:
        return '#FFFFFF'
    }
  }

  const [uploadAnnualPlan] = useUploadAnnualPlanMutation()


  // Handle form submission
  const handleSubmit = async (values: any) => {

    let data: any = {}
    data = handleImageData(values, data, 'annual_plan_file')
    await uploadAnnualPlan({ data: data }).unwrap()
    alert('Uploaded')
    console.log('Uploaded values:', values) // For now, just log the values to the console
  }

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <FilterDropdown />
        {canDo('company: upload annual plan') && (
          <Button appearance="primary" onClick={() => setShowUpload(true)}>Upload Annual Plan</Button>
        )}

      </div>


      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>


        {/* Second Column */}
        <div>
          <MonthGrid>
            {months.map((month, index) => (
              <MonthContainer key={index}>
                <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>{month}</h3>
                <div>
                  {getMonthEntries(index).map((entry: any, idx: number) => (
                    <Entry key={idx} backgroundColor={getBackgroundColor('course')}>
                      {entry.title}
                    </Entry>
                  ))}
                </div>
              </MonthContainer>
            ))}
          </MonthGrid>
        </div>

        {/* First Column */}
        {planFileData?.data?.annual_plan_file?.url && (
          <div style={{ textAlign: 'right' }}>
            <img
              src={planFileData?.data?.annual_plan_file?.url}
              alt={planFileData?.data?.annual_plan_file?.name}
              style={{ width: '100%', height:'auto' }}
            />
          </div>
        )}
      </div>


      {/* Upload Overlay */}
      {showUpload && (
        <UploadOverlay>
          <UploadContainer>
            <Formik
              initialValues={{ cover: null }}
              onSubmit={(values) => {
                handleSubmit(values)
                setShowUpload(false) // Close the overlay after submission
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  <ImageUploadWrapper name={'annual_plan_file'} type={'photos'} />
                  <div style={{ marginTop: '20px' }}>
                    <Button type="submit" appearance="primary">Upload</Button>
                    <Button type="button" appearance="secondary" onClick={() => setShowUpload(false)}
                            style={{ marginLeft: '20px' }}>Cancel</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </UploadContainer>
        </UploadOverlay>
      )}
    </Paper>
  )
}

export default AnnualPlan
