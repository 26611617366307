import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionButtons } from '../../components/ActionButtons/ActionButtons'
import { LuPlus } from 'react-icons/lu'
import { Button, Table } from '../../ui-kit/'
import { paths } from '../../config/paths'
import {
  useDeleteCourseMutation,
  useGetAllCoursesQuery,
  useUpdateCourseStartMutation,
} from '../../slices/coursesApiSlice'
import { CourseProps } from '../../interfaces/course'
import { Actions, SubPageHeader, FormPaper, TableContainer, Title } from './Courses.styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import CopyCoursePopup from './CopyCoursePopup'  // Import the new CopyCoursePopup component

export const Courses = () => {
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { data: courses, error, isLoading, refetch } = useGetAllCoursesQuery({})
  const [deleteCourse] = useDeleteCourseMutation()
  const [startCourse] = useUpdateCourseStartMutation()

  const {permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }


  const [isCopyPopupOpen, setCopyPopupOpen] = useState(false)
  const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null)

  const editCourseAction = (courseId: number) => navigate(`/courses/edit/${courseId}`)
  const copyCourseAction = (courseId: number) => navigate(`/courses/copy/${courseId}`)

  const superCopyCourseAction = (courseId: number) => {
    setSelectedCourseId(courseId)
    setCopyPopupOpen(true)
  }

  const closeCopyPopup = () => {
    setCopyPopupOpen(false)
    setSelectedCourseId(null)
  }

  const deleteCourseAction = (courseId: number) => {
    const userConfirmed = window.confirm('Are you sure you want to delete this course?')
    if (!userConfirmed) return
    deleteCourse(courseId).then(() => {
      refetch()
    }).catch((error) => {
      console.error('Failed to delete course:', error)
    })
  }

  const startCourseAction = (courseId: number) => {
    startCourse(courseId).then(() => {
      refetch()
    })
  }

  const columns = [
    formatMessage({ id: 'courses.titleLabel', defaultMessage: 'Title' }),
    formatMessage({ id: 'courses.statusLabel', defaultMessage: 'Status' }),
    formatMessage({ id: 'courses.startedLabel', defaultMessage: 'Started' }),
    formatMessage({ id: 'courses.expiredLabel', defaultMessage: 'Expired' }),
    formatMessage({ id: 'userList.actions', defaultMessage: 'Actions' }),
  ]

  const courseTableData = courses?.data.map((course: CourseProps) => ({
    [columns[0]]: (course.is_inherited ? '*** ' : '') + course.title,
    [columns[1]]: course.status.title,
    [columns[2]]: course.started_at,
    [columns[3]]: course.expired_at,
    [columns[4]]: (
      <ActionButtons
        id={course.id}
        handleEdit={canDo('course: update') ? editCourseAction : undefined}
        handleCopy={canDo('course: update') ? copyCourseAction : undefined}
        handleSuperCopy={canDo('course: copy to subsidiary') ? superCopyCourseAction : undefined}
        handleDelete={canDo('course: delete') ? deleteCourseAction : undefined}
        handleStart={canDo('course: start') && course.status.enum === 'published' ? startCourseAction : undefined}
      />
    ),
  }))

  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    return null
  }

  return (
    <FormPaper>
      <SubPageHeader>
        <Title>
          {formatMessage({ id: 'courses.title', defaultMessage: 'Courses' })}
        </Title>
        <Actions>
          {canDo('course: create') && (
            <Button appearance="primary" onClick={() => navigate(paths.createCourse)}>
              <LuPlus size={20} />
              {formatMessage({ id: 'courses.newCourseButton', defaultMessage: 'New course' })}
            </Button>
          )}
        </Actions>
      </SubPageHeader>
      <TableContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Table columns={columns} rows={courseTableData} />
        )}
      </TableContainer>
      {isCopyPopupOpen && selectedCourseId !== null && (
        <CopyCoursePopup
          courseId={selectedCourseId}
          onClose={closeCopyPopup}
          onCopySuccess={refetch}
        />
      )}
    </FormPaper>
  )
}
