import styled from 'styled-components';

export const UploadsContainer = styled.div`
    margin-top: 20px;
`;

export const UploadsTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
`;

export const TableHeader = styled.th`
    background-color: #f4f4f4;
    padding: 10px;
    text-align: left;
`;

export const TableCell = styled.td`
    padding: 10px;
    border-top: 1px solid #ddd;
`;

export const DeleteButton = styled.button`
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
        background-color: #ff1a1a;
    }
`;

export const UploadForm = styled.form`
  gap: 10px;
  margin-bottom: 20px;
`;

export const UploadInput = styled.input`
    flex: 1;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: '200px';
`;
