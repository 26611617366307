import { apiSlice, convertToFormData } from './apiSlice'

export const handbooksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadVideo: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/files/upload/media`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),

    uploadEntityFile: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/files/upload/entity-files`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
  }),
})

export const {
  useUploadVideoMutation,
  useUploadEntityFileMutation
} = handbooksApiSlice
