export const Platform = {
  NONE: '',
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
}

export function getPlatform() {
  if (typeof navigator === 'undefined') {
    // server-side rendering, return default
    return Platform.NONE
  }

  const userAgent = navigator?.userAgent || navigator?.vendor

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )

  return isMobile ? Platform.MOBILE : Platform.DESKTOP
}
