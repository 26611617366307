import React, { useEffect } from 'react'
import { useFormik, Formik, Form, FieldArray } from 'formik'
import { Button, FormikInput, FormikCheckbox } from '../../../../ui-kit'
import { LuTrash } from 'react-icons/lu'
import {
  FormPaper,
  Content,
  Title,
  Actions,
  Table,
  TableTitle,
} from './QuizForm.styles'
import {
  useCreateCourseQuestionMutation,
  useUpdateCourseQuestionMutation,
  useGetCourseQuestionQuery,
} from '../../../../slices/coursesApiSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { formatMessage } from '../../../../locale'
interface QuizFormProps {
  mode: 'create' | 'edit';
  courseId: number;
  questionId?: number;
  onChanged?: () => void;
  onResetMode?: () => void;
}

const QuizForm: React.FC<QuizFormProps> = ({
                                             mode,
                                             courseId,
                                             questionId,
                                             onChanged,
                                             onResetMode,
                                           }) => {
  const [createCourseQuestion] = useCreateCourseQuestionMutation()
  const [updateCourseQuestion] = useUpdateCourseQuestionMutation()
  const { data: questionData, refetch } = useGetCourseQuestionQuery(
    { courseId, questionId },
    { skip: mode === 'create' },
  )

  const { canEditCourse } = useSelector((state: RootState) => state.auth)
  const initialValues = {
    question_text: '',
    image: null,
    choices: [{ choice_text: '', is_correct: false, image: null }],
  }

  useEffect(() => {
    if (mode === 'edit' && questionId) {
      refetch()
    }
  }, [mode, questionId, refetch])

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const formattedValues = {
        ...values,
        choices: values.choices.map(({ image, ...choice }) => ({
          ...choice,
          is_correct: choice.is_correct ? 1 : 0,
        })),
      }

      // Ensure the formattedValues.image does not exist
      // @ts-ignore
      delete formattedValues.image

      if (mode === 'create') {
        await createCourseQuestion({ id: courseId, data: formattedValues }).unwrap()
      } else if (mode === 'edit') {
        await updateCourseQuestion({ id: courseId, questionId, data: formattedValues }).unwrap()
      }

      onChanged?.()
      onResetMode?.()
    } catch (error) {
      console.error('Failed to create/update question:', error)
    }
  }


  const formikInitialValues = mode === 'edit' && questionData ? questionData.data : initialValues

  return (
    <FormPaper>
      <Formik initialValues={formikInitialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            <Title>
            {mode === 'create' ? 
            formatMessage({ id: 'quiz.createQestion', defaultMessage: 'Create question' }) : 
            formatMessage({ id: 'quiz.editQestion', defaultMessage: 'Edit question' })
            }
            </Title>
            <Content>
              <FormikInput
                label={formatMessage({ id: 'quiz.qestionLabel', defaultMessage: 'Question' })}
                name="question_text"
                placeholder={formatMessage({ id: 'quiz.qestionPlaceholder', defaultMessage: 'Type question here' })}
                autoComplete="off"
                required
              />
              <TableTitle>{formatMessage({ id: 'quiz.questions', defaultMessage: 'Choices' })}</TableTitle>
              <FieldArray name="choices">
                {({ remove, push }) => (
                  <div>
                    <Table>
                      <thead>
                      <tr>
                          <th style={{ width: '20px' }}>{formatMessage({id: 'quiz.correct', defaultMessage: 'Correct'})}</th>
                          <th>{formatMessage({id: 'quiz.choice', defaultMessage: 'Choice'})}</th>
                        {canEditCourse && (
                            <th style={{ width: '20px' }}>{formatMessage({ id: 'quiz.actions', defaultMessage: 'Actions'})} </th>
                        )}
                      </tr>
                      </thead>
                      <tbody>
                      {values.choices.map((choice, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: 'center' }}>
                            <FormikCheckbox name={`choices.${index}.is_correct`} disabled={!canEditCourse} />
                          </td>
                          <td>
                            <FormikInput
                              name={`choices.${index}.choice_text`}
                              label={`${formatMessage({id: 'quiz.choice', defaultMessage: 'Choice'})} ${index + 1}`}
                              placeholder={formatMessage({id: 'quiz.choicePlaceholder', defaultMessage: 'Type choice here'})}
                              autoComplete="off"
                              required
                            />
                          </td>
                          {canEditCourse && (
                            <td style={{ textAlign: 'right' }}>

                              <Button type="button" onClick={() => remove(index)}>
                                <LuTrash size={16} />
                              </Button>

                            </td>

                          )}
                        </tr>
                      ))}
                      </tbody>
                    </Table>
                    {canEditCourse && (
                      <Button
                        type="button"
                        appearance="secondary"
                        onClick={() => push({ choice_text: '', is_correct: false })}
                        style={{ marginTop: '1rem' }}
                      >
                        {formatMessage({ id: 'quiz.newChoice', defaultMessage: 'New choice' })}
                      </Button>
                    )}
                  </div>
                )}
              </FieldArray>
            </Content>
            {canEditCourse && (
              <Actions>
                <Button appearance="primary" type="submit">
                {mode === 'create' ? 
                formatMessage({id: 'quiz.create', defaultMessage: 'Create'}) : 
                formatMessage({id: 'quiz.save', defaultMessage: 'Save'})
                }
                </Button>
              </Actions>
            )}
          </Form>
        )}
      </Formik>
    </FormPaper>
  )
}

export default QuizForm
