import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background: ${({ theme }) => theme.palette.shape};
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 450px;
  padding: 2rem;
  background: ${({ theme }) => theme.palette.windowBackground};
  border-radius: ${({ theme }) => theme.shape.borderRadiusMedium};
  box-shadow: 0 1px 5px 1px rgb(231, 229, 230, 0.5);
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

export const Logo = styled.div`
  max-width: 8.75rem;
  margin: 0 auto 1rem;

  & img {
    max-width: 100%;
  }
`;

export const LinkText = styled.p`
  text-align: center;
  margin-top: 1rem;
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const Message = styled.p<{ error?: boolean; success?: boolean }>`
  color: ${({ error, success, theme }) =>
  error ? theme.palette.statusRed : success ? theme.palette.statusGreen : 'inherit'};
  text-align: center;
  margin-top: 1rem;
`;
