import styled from 'styled-components';

export const Content = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
`;

export const LessonHeader = styled.div`
    margin-bottom: 20px;
`;

export const LessonTitle = styled.h1`
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #2c3e50;
`;

export const Created = styled.div`
    font-size: 0.9rem;
    color: #7f8c8d;
`;

export const LessonContent = styled.div`
    & p {
        font-size: 1rem;
        margin-bottom: 1em;
    }

    & h1, & h2, & h3, & h4, & h5, & h6 {
        color: #2c3e50;
        margin-top: 1.5em;
        margin-bottom: 0.75em;
        line-height: 1.2;
    }

    & h1 {
        font-size: 2.5rem;
    }

    & h2 {
        font-size: 2rem;
    }

    & h3 {
        font-size: 1.75rem;
    }

    & h4 {
        font-size: 1.5rem;
    }

    & h5 {
        font-size: 1.25rem;
    }

    & h6 {
        font-size: 1rem;
    }

    & ol, & ul {
        margin-left: 1.5em;
        margin-bottom: 1em;
        padding-left: 1.5em;
        list-style-position: outside;
    }

    & ol {
        list-style-type: decimal;
    }

    & ul {
        list-style-type: disc;
    }

    & li {
        margin-bottom: 0.5em;
        list-style: inherit;
    }

    & hr {
        border: 0;
        height: 1px;
        background: #ccc;
        margin: 2em 0;
    }

    & blockquote {
        margin: 1.5em 10px;
        padding: 0.5em 10px;
        border-left: 5px solid #ccc;
        color: #555;
        background: #f9f9f9;
    }

    & img {
        max-width: 100%;
        height: auto;
        margin: 1.5em 0;
    }

    & a {
        color: #3498db;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    & table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1.5em;
        background-color: red;

        & th, & td {
            border: 1px solid #ddd;
            padding: 8px;
            vertical-align: top !important;
        }

        & th {
            background-color: #f4f4f4;
            text-align: left;
            vertical-align: top !important;
        }
    }
`;

export const CoverImage = styled.div`
    margin-bottom: 20px;
    img {
        max-width: 100%;
        height: auto;
    }
`;
