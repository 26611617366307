import styled from 'styled-components';

export const QuizContainer = styled.div`
    display: flex;
    gap: 4rem;
`;

export const NoQuiz = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
`;

export const QuizSidebar = styled.div`
    flex: 0 0 350px;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const QuizFormContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const QuizList = styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
`;

export const QuizListItem = styled.li`
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: 180ms ease;

    &:not(:last-child) {
        border-bottom: 0.063rem solid ${({ theme }) => theme.palette.stroke};
    }

    &:hover {
        background: ${({ theme }) => theme.palette.shape};
    }
`;

export const QuizDetailsButton = styled.div`
    cursor: pointer;
    padding: 1rem;
    background-color: ${({ theme, color }) =>  color || theme.palette.primary};
    color: ${({ theme }) => 'white'};
    text-align: center;
    margin-bottom: 1rem;
    border-radius: 4px;
`;

export const Title = styled.h2`
    font-size: 1.5rem;
`;

export const Actions = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
`;
