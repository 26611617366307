import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SettingsState {
  method: string
  locale: string
}

const initialState: SettingsState = {
  method: 'GET',
  locale: 'en',
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setMethod: (state, action: PayloadAction<string>) => {
      state.method = action.payload
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload
    },
  },
})

export const { setMethod, setLocale } = settingsSlice.actions
export default settingsSlice.reducer
