import { apiSlice, convertToFormData } from './apiSlice'
import { LoginResponse, UserInfo } from '../interfaces/auth'

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserInfo, LoginResponse>({
      query: (data: any) => ({
        url: '/login',
        method: 'POST',
        body: data,
      }),
    }),

    recoverPassword: builder.mutation({
      query: (data: any) => ({
        url: '/password/email',
        method: 'POST',
        body: data,
      }),
    }),

    resetPassword: builder.mutation({
      query: (data: any) => ({
        url: '/password/reset',
        method: 'POST',
        body: data,
      }),
    }),

    logout: builder.mutation({
      query: ({}) => ({
        url: '/logout',
        method: 'POST',
      }),
    }),
    changePassword: builder.mutation({
      query: ({ current_password, new_password, new_password_confirmation }) => ({
        url: '/api/v1/profile/change-password',
        method: 'POST',
        body: { current_password, new_password, new_password_confirmation },
      }),
    }),

    getUserInfo: builder.query({
      query: () => ({
        url: '/api/v1/profile',
        method: 'GET',
      }),
    }),
    getUserPermissions: builder.query({
      query: () => ({
        url: '/api/v1/profile/permissions',
        method: 'GET',
      }),
    }),

    getAllUsers: builder.query({
      query: () => ({
        url: '/api/v1/admin/users/all',
        method: 'GET',
      }),
    }),

    getCompanyUsers: builder.query({
      query: () => ({
        url: '/api/v1/admin/users',
        method: 'GET',
      }),
    }),

    createUser: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/users`,
        method: 'POST',
        body: data,
      }),
    }),
    viewUser: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/admin/users/${id}`,
        method: 'GET',
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/users/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/admin/users/${id}`,
        method: 'DELETE',
      }),
    }),

    activateUser: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/admin/users/${id}/activate`,
        method: 'POST',
      }),
    }),
    deactivateUser: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/admin/users/${id}/deactivate`,
        method: 'POST',
      }),
    }),
    getUserDocuments: builder.query({
      query: ({ tag }) => ({
        url: `/api/v1/profile/attachments?tag=${encodeURIComponent(tag)}`,
        method: 'GET',
      }),
    }),
    deleteUpload: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/profile/attachments/${id}`,
        method: 'DELETE',
      }),
    }),
    postUpload: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/profile/attachments`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),

  }),
})
export const {
  useLoginMutation,
  useLogoutMutation,
  useRecoverPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGetUserInfoQuery,
  useGetUserPermissionsQuery,
  useViewUserQuery,
  useGetAllUsersQuery,
  useGetCompanyUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useGetUserDocumentsQuery,
  useDeleteUploadMutation,
  usePostUploadMutation,
} = userApiSlice
