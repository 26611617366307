import React, { RefObject } from 'react'
import { getMonth, getYear } from 'date-fns'
import { Select } from '../../Select/Select'

type MonthsProps = {
  onChange: (value: number) => void
  value: Date
  calendarRef?: RefObject<HTMLDivElement>
  minDate?: Date
  maxDate?: Date
  border?: boolean
}

const options = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((label, value) => ({ label, value: value + 1 }))

const today = getMonth(new Date()) + 1

export const Months = ({
  onChange,
  value,
  calendarRef,
  minDate,
  maxDate,
  border,
  ...props
}: MonthsProps) => {
  const minMonth = minDate ? getMonth(minDate) + 1 : undefined
  const maxMonth = maxDate ? getMonth(maxDate) + 1 : undefined
  const currentYear = value ? getYear(value) : undefined
  const restrictMinMonth =
    minMonth && minDate && currentYear === getYear(minDate)
  const restrictMaxMonth =
    maxMonth && maxDate && currentYear === getYear(maxDate)

  let month = value ? getMonth(value) + 1 : today

  if (restrictMinMonth && month < minMonth) {
    month = minMonth
  }

  if (restrictMaxMonth && month > maxMonth) {
    month = maxMonth
  }

  return (
    <Select
      options={options.filter((month) => {
        if (restrictMinMonth && month.value < minMonth) {
          return false
        }

        if (restrictMaxMonth && month.value > maxMonth) {
          return false
        }

        return true
      })}
      onChange={onChange}
      value={month}
      removable={false}
      border={border}
      dropdownContainerRef={calendarRef}
    />
  )
}

export default Months
