import React from 'react'
import ButtonIconTransparent from '../../ui-kit/Button/ui/components/ButtonIconTransparent'
import { BiEditAlt, BiPlay, BiTrash } from 'react-icons/bi'
import { ActionButtonsContainer } from './ActionButtons.styles'
import { LuCakeSlice, LuCopy, LuCopyCheck, LuSwitchCamera } from 'react-icons/lu'

interface ActionButtonsProps {
  id: number;
  handleEdit?: (courseId: number) => void;
  handleCopy?: (courseId: number) => void;
  handleSuperCopy?: (courseId: number) => void;
  handleDelete?: (courseId: number) => void;
  handleStart?: (courseId: number) => void;
}

export const ActionButtons = ({
                                id,
                                handleEdit,
                                handleCopy,
                                handleSuperCopy,
                                handleStart,
                                handleDelete,
                              }: ActionButtonsProps) => {
  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleEdit?.(id)
  }

  const handleCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleCopy?.(id)
  }

  const handleSuperCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleSuperCopy?.(id)
  }


  const handleStartClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleStart?.(id)
  }

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleDelete?.(id)
  }

  return (
    <ActionButtonsContainer>
      <ButtonIconTransparent onClick={handleEditClick}>
        <BiEditAlt size={20} />
      </ButtonIconTransparent>


      {handleCopy && (
        <ButtonIconTransparent onClick={handleCopyClick}>
          <LuCopy size={20} />
        </ButtonIconTransparent>
      )}

      {handleSuperCopy && (
        <ButtonIconTransparent onClick={handleSuperCopyClick}>
          <LuCopyCheck size={20} />
        </ButtonIconTransparent>
      )}

      {handleStart && (
        <ButtonIconTransparent onClick={handleStartClick}>
          <BiPlay size={20} />
        </ButtonIconTransparent>
      )}
      {handleDelete && (
        <ButtonIconTransparent onClick={handleDeleteClick}>
          <BiTrash size={20} />
        </ButtonIconTransparent>
      )}
    </ActionButtonsContainer>
  )
}
