import React from 'react';
import styled from 'styled-components';
import { formatMessage } from '../../locale';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { useSelector } from 'react-redux';
interface HeroProps {
  imagePath: string;
  header: string;
  subHeader: string;
  description: string;
}

const HeroContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const TextContainer = styled.div`
    max-width: 60%;
`;

const HeroHeader = styled.h1`
    font-size: 1.8rem;
    margin-bottom: 10px;
`;

const HeroHeader2 = styled.span<{ color?: string }>`
  color: ${({ color }) => color || '#A61464'}; /* Default color if userCompany.button_color is not provided */
`;

const HeroSubHeader = styled.h2`
    color: #333;
    font-size: 1rem;
    margin-bottom: 20px;
`;

const HeroImage = styled.img`
    width: 40%;
    height: auto;
    margin-left: 20px;
`;

const HeroDescriptionContainer = styled.div`
    text-align: left;
`;

const HeroDescription = styled.p`
  font-size: 1rem;
  color: #333;
  margin-bottom: 40px;
`;

const Hero: React.FC<HeroProps> = ({ imagePath, header, subHeader, description }) => {
  // Convert \n to <br> tags
  const formattedDescription = description.replace(/\n/g, '<br />');
  const { intl } = useIntlContext();
  const { userCompany } = useSelector((state: any) => state.auth);
  const color = userCompany?.main_color || '#A61464'; 
  return (
    <>
      <HeroContainer>
        <TextContainer>
          <HeroHeader>{header}
          <HeroHeader2 color={color}>{formatMessage({ id: 'hero.name', defaultMessage: 'Work Environment Portal' })}</HeroHeader2>
          </HeroHeader>

          <HeroSubHeader>{subHeader}</HeroSubHeader>
        </TextContainer>
        <HeroImage src={imagePath} alt="Hero Image" />
      </HeroContainer>

    </>
  );
};

export default Hero;
