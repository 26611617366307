import styled from 'styled-components';

export const ListContainer = styled.div`
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
`;

export const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
`;

export const Button = styled.button`
background: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  margin-right: 20px;
`;

export const Actions = styled.div`
    display: flex;
`;

