import styled from 'styled-components'
import ButtonBase from '../base/ButtonBase'
import { hexToRGBA } from '../../../utils/hexToRGBA'

const ButtonIconTransparent = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.palette.transparent};

  &:hover {
    background-color: ${({ theme }) => hexToRGBA(theme.palette.primary, 0.2)};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.contrastHigh};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.transparent};
  }

  width: 2.5rem;
  height: 2.5rem;
  border-radius: ${({ theme }) => theme.shape.borderRadiusSmall};
`

export default ButtonIconTransparent
