import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const baseUrl = process.env.REACT_APP_URL || 'http://localhost';

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: 'include', // Ensure credentials include for CORS
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState;

    // Check if XSRF token is already in cookies
    let xsrfToken = getCookieValue('XSRF-TOKEN');

    if (!xsrfToken) {
      // Fetch XSRF token if not available in cookies
      try {
        await fetch(baseUrl + '/sanctum/csrf-cookie', {
          method: 'GET',
          credentials: 'include',
        });
        // Manually parse the Set-Cookie header if necessary
        xsrfToken = getCookieValue('XSRF-TOKEN');
      } catch (err) {
        console.log(err);
      }
    }

    if (xsrfToken) {
      headers.set('X-XSRF-TOKEN', xsrfToken.replace(/%3D/g, '='));
    }

    // Add Accept header
    headers.set('Accept', 'application/json');
    headers.set('Referer', '127.0.0.1');

    // Add Accept-Language header
    headers.set('Accept-Language', state.settings.locale || 'en');

    // Add X-Company-Id header if available
    const storedCompanyId = localStorage.getItem('selectedCompanyId') || state.company.companyId;

    if (storedCompanyId) {
      headers.set('X-Company-Id', storedCompanyId+'');
    }

    return headers;
  },
});

// Helper function to get the value of a specific cookie
function getCookieValue(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      ?.split(';')
      .shift()
      ?.replace(/\s+/g, ' ')
      .replace(/^\s*|\s$/g, '');
}


export { baseQuery, baseUrl };
