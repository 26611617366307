import styled from 'styled-components'
import { StyledPaper } from '../../ui-kit/Paper/Paper.styles'

export const FormPaper = styled(StyledPaper)`
  padding: 0;
  overflow: hidden;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Search = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const SubPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 5px 1px rgb(231, 229, 230, 0.5);
  border-bottom: 1px solid rgb(210 210 210 / 50%);
  padding: 0 1rem;
  height: 5.063rem;
`

export const Title = styled.h2`
  font-size: 1.5rem;
`

export const TableContainer = styled.div`
  padding: 2rem 1rem 2rem;
`
