import { apiSlice } from './apiSlice'

export const anualPlanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalPlan: builder.query({
      query: () => ({
        url: '/api/v1/admin/annual-plan',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetPersonalPlanQuery,
} = anualPlanApiSlice
