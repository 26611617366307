import styled from 'styled-components';

export const FormPaper = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const FormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:1.8rem;
    margin-bottom: 20px;
`;

export const Actions = styled.div`
    display: flex;
    gap: 10px;
`;

export const TabContent = styled.div`
    display: flex;
`;

export const FormContent = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9997;
`;
export const ColumnTopLevel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 9999;
`;
