import styled, { css } from 'styled-components'
import Label from '../../FieldWrapper'

export const Container = styled.div`
  position: relative;
`

export const StyledLabel = styled(Label)`
  display: flex;
  position: relative;
`

export const StyledAngle = styled.div<{
  isOpen: boolean | undefined
}>`
  transition: 180ms ease;

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: rotate(-180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`

export const InputWrapper = styled.div<{
  disabled: boolean | undefined
}>`
  position: relative;

  & svg {
    color: ${({ theme }) => theme.palette.visualCue};
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:focus-within {
    & svg {
      color: ${({ theme }) => theme.palette.contrastMedium};
    }
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      & svg {
        color: ${theme.palette.stroke};
        cursor: default;
      }

      &:hover,
      &:focus,
      &:focus-within {
        & svg {
          color: ${theme.palette.stroke};
        }
      }
    `}
`
