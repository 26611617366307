import React, { useState, useEffect } from 'react';
import { LuX } from 'react-icons/lu';
import { useGetAllUsersQuery } from '../../../../../slices/usersApiSlices';
import { useCreateDepartmentMutation, useUpdateDepartmentMutation, useGetAllDepartmentsQuery, useGetAllDepartmentsByParentQuery, useGetAllDepartmentsByParentByIdQuery } from '../../../../../slices/departmentsApiSlice';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import {
  Overlay,
  Modal,
  CloseButton,
  Form,
  Label,
  Input,
  ReadOnlyInput,
  Select,
  Button,
} from './DepartmentPopup.styles';
import { useSelector } from 'react-redux';

interface DepartmentPopupProps {
  parentDepartment: { id: number | null; name: string } | null;
  setParentDepartment: React.Dispatch<React.SetStateAction<{ id: number | null; name: string } | null>>;
  department?: { id: number; name: string; head_of_department?: { id: number; fullname: string } | null };
  mode: 'create' | 'edit';
  onClose: () => void;
  onPublish: (departmentName: string, headOfDepartment?: string) => void;
}

const DepartmentPopup: React.FC<DepartmentPopupProps> = ({ setParentDepartment, parentDepartment, department, mode, onClose, onPublish }) => {
  const {userCompany} = useSelector((state: any) => state.auth);
  const { intl } = useIntlContext();
  const [departmentName, setDepartmentName] = useState(department ? department.name : '');
  const [headOfDepartment, setHeadOfDepartment] = useState(department && department.head_of_department ? department.head_of_department.id.toString() : '');

  const [createDepartment] = useCreateDepartmentMutation();
  const [updateDepartment] = useUpdateDepartmentMutation();

  const { data: users, error, isLoading} = useGetAllUsersQuery({});
  const { data: departments } = useGetAllDepartmentsQuery({});
  const { data: parentDepartments, refetch: refetchParentDepartments } = useGetAllDepartmentsByParentQuery({});
  const { data: parentDepartmentsById, refetch: refetchParentDepartmentsById } = useGetAllDepartmentsByParentByIdQuery(
    { id: department?.id || '' },
    { skip: mode === 'create' }
  );

   
  useEffect(() => {
    if (department) {
      setDepartmentName(department.name);
      setHeadOfDepartment(department.head_of_department ? department.head_of_department.id.toString() : '');
    }
  }, [department]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === 'create') {
      await createDepartment({
        data: {
          name: departmentName,
          head_of_department_id: headOfDepartment,
          parent_id: parentDepartment?.id,
        }
      }).unwrap();
      refetchParentDepartments();
    } else {
      await updateDepartment({
        id: department!.id,
        data: {
          name: departmentName,
          head_of_department_id: headOfDepartment,
          parent_id: parentDepartment?.id,
        }
      }).unwrap();
      refetchParentDepartmentsById(); 
    }

    onPublish(departmentName, headOfDepartment);
  };


  const color = userCompany?.button_color || '#A4366D';
  return (
    <Overlay>
      <Modal>
        <CloseButton onClick={onClose}>
          <LuX size={24} />
        </CloseButton>
        <h2>{mode === 'create' ? formatMessage({ id: 'department.createTitle', defaultMessage: 'Create Department' }) : formatMessage({ id: 'department.editTitle', defaultMessage: 'Edit Department' })}</h2>
        <Form onSubmit={handleSubmit}>
          <Label style={{marginTop: 10}}>{formatMessage({ id: 'department.departmentLabel', defaultMessage: 'Department / Business Unit / Team' })}</Label>
          <Input
            type="text"
            value={departmentName}
            onChange={(e) => setDepartmentName(e.target.value)}
            required
          />
         
         <Label>{formatMessage({ id: 'department.headLabel', defaultMessage: 'Head of Department' })}</Label>
          {isLoading ? (
            <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>
          ) : error ? (
            <div>{formatMessage({ id: 'department.errorLoadingUsers', defaultMessage: 'Error loading users' })}</div>
          ) : (
            <Select
              value={headOfDepartment}
              onChange={(e) => setHeadOfDepartment(e.target.value)}
            >
              <option value="">{formatMessage({ id: 'chooseHere', defaultMessage: 'Choose here' })}</option>
              {users?.data.map((user: any) => (
                <option key={user.id} value={user.id}>
                  {user.fullname}
                </option>
              ))}
            </Select>
          )}
          
          <Label>{formatMessage({ id: 'department.parentLabel', defaultMessage: 'Parent Department' })}</Label>
          {isLoading ? (
            <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>
          ) : error ? (
            <div>{formatMessage({ id: 'department.errorLoadingUsers', defaultMessage: 'Error loading users' })}</div>
          ) : (
            <Select
              value={parentDepartment?.id || ''}
              onChange={(e) => setParentDepartment({ id: parseInt(e.target.value), name: '' })}
            >
              <option value="">{formatMessage({ id: 'department.noDepartment', defaultMessage: 'No department' })}</option>
               {mode === 'edit' ? (
             parentDepartmentsById?.data.map((department: any) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))
            ) : (
              parentDepartments?.data.map((department: any) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))
            )}
            </Select>
          )}


          <Button type="submit" color={color} >{mode === 'create' ? formatMessage({ id: 'create', defaultMessage: 'Create' }) : formatMessage({ id: 'save', defaultMessage: 'Save' })}</Button>
        </Form>
      </Modal>
    </Overlay>
  );
};

export default DepartmentPopup;
