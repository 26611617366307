import { apiSlice, convertToFormData } from './apiSlice'

export const investigationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllInvestigations: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/investigations?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
      }),
    }),

    viewInvestigation: builder.query({
      query: ({ id, update }) => ({
        url: `/api/v1/investigations/${id}` +  (update ? '?view=update' : ''),
        method: 'GET',
      }),
    }),

    createInvestigation: builder.mutation({
      query: ({  data }) => ({
        url: `/api/v1/investigations`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    updateInvestigation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/investigations/${id}?_method=PATCH`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    deleteInvestigation: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/investigations/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAllInvestigationsQuery,
  useViewInvestigationQuery,
  useCreateInvestigationMutation,
  useUpdateInvestigationMutation,
  useDeleteInvestigationMutation,
} = investigationsApiSlice
