import Resizer from 'react-image-file-resizer';

const isBrowser = typeof window !== 'undefined';

const defaultOptions = {
  maxWidth: 1920,
  maxHeight: 1080,
  format: 'JPEG', // Ensure format is JPEG to remove transparency
  rotation: 0,
  quality: 100,
  outputType: 'file',
};

const shouldResize =
  typeof globalThis.document !== 'undefined' && process.env.NODE_ENV !== 'test';

export const resizeImage = async (file: File, options = defaultOptions) => {
  if (!file) {
    return undefined;
  }

  if (!shouldResize) {
    return file;
  }

  const { maxWidth, maxHeight, format, quality, rotation, outputType } = options;

  try {
    const res = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Calculate the new width and height while preserving the aspect ratio
          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            if (width / height > maxWidth / maxHeight) {
              width = maxWidth;
              height = (img.height * maxWidth) / img.width;
            } else {
              height = maxHeight;
              width = (img.width * maxHeight) / img.height;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Set the background to white
          ctx!.fillStyle = '#FFFFFF';
          ctx!.fillRect(0, 0, canvas.width, canvas.height);

          // Draw the image onto the canvas
          ctx!.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob
          canvas.toBlob(
            (blob) => {
              const resizedFile = new File([blob!], file.name, {
                type: `image/${format.toLowerCase()}`,
              });
              resolve(resizedFile);
            },
            `image/${format.toLowerCase()}`,
            quality / 100
          );
        };
      };
    });

    return res;
  } catch {
    return file;
  }
};

export function addDialogClosedListener(
  input?: HTMLElement | null,
  callback?: () => void
) {
  if (!isBrowser || !input || !callback) {
    return;
  }

  const onFocus = function () {
    window.removeEventListener('focus', onFocus);
    callback?.();
  };
  const onClick = function () {
    window.addEventListener('focus', onFocus);
  };
  input.addEventListener('click', onClick);
  return function () {
    input.removeEventListener('click', onClick);
    window.removeEventListener('focus', onFocus);
  };
}
