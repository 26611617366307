import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../locale';
import Button from '../../../../ui-kit/Button/Button';
import { Actions, FormContent, FormHeader, FormPaper, TabContent } from './LibraryForm.styles';
import { paths } from '../../../../config/paths';
import { Form, Formik } from 'formik';
import Row from '../../../../ui-kit/grid/Row';
import { useCreateLibraryMutation, useUpdateLibraryMutation, useViewLibraryQuery } from '../../../../slices/libraryApiSlice';
import { useGetSubsidiariesCompaniesQuery } from '../../../../slices/companiesApiSlice';
import { FormikInput, FormikTextarea } from '../../../../ui-kit';
import WangEditor from '../../../../ui-kit/Form/components/Editor/WysiwygEditor';
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper';
import { MultiSelect } from '../../../../ui-kit/MultiSelect/MultiSelect';
import { handleImageData } from '../../../../slices/apiSlice';
import {  useGetRolesCompaniesQuery } from '../../../../slices/companiesApiSlice';
import { useSelector } from 'react-redux';


interface LibraryFormPageProps {
  mode: 'create' | 'edit';
}

export const LibraryForm = ({ mode }: LibraryFormPageProps) => {
  const { userCompany } = useSelector((state: any) => state.auth);

  const [uuids, setUuids] = useState<Array<string>>([]);
  
  const [frontId, setFrontId] = useState<string>('');
  const { libraryId } = useParams<{ libraryId: string }>();
  const navigate = useNavigate();
  const { intl } = useIntlContext();

  const [createLibrary] = useCreateLibraryMutation();
  const [updateLibrary] = useUpdateLibraryMutation();

  const { data: library, error, isLoading, refetch } = useViewLibraryQuery({ id: libraryId, update: true }, { skip: mode === 'create' });
  const { data: companiesData } = useGetSubsidiariesCompaniesQuery({});

  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const { data: rolesCompaniesData} = useGetRolesCompaniesQuery(
    { id: selectedCompanies }
  );

  useEffect(() => {
      return () => {
        localStorage.removeItem('uuids');
        localStorage.removeItem('frontId');
    };
  }, []);

  const initialValues = {
    title: library?.data?.title || '',
    description: library?.data?.description || '',
    content: library?.data?.content || '',
    entity_files: library?.data?.entity_files.uuid || '',
    companies: library?.data?.companies?.map((company: any) => company.id) || [],
    released_at: library?.data?.released_at || '',
    card_color: library?.data?.card_color || userCompany.button_color ,
    cover: library?.data?.cover || null,
    roles: library?.data?.roles?.map((role: any) => role.id) || "", 
  };

  useEffect(() => {
    if (mode === 'edit' && library?.data?.entity_files) {
      const backUID = localStorage.getItem('uuids');
      const prevUUIDs = backUID ? JSON.parse(backUID) : [];
  
      const newUUIDs = library.data.entity_files.map((file: any) => file.uuid);
      const combinedUUIDs = Array.from(new Set([...prevUUIDs, ...newUUIDs]));
  
      localStorage.setItem('uuids', JSON.stringify(combinedUUIDs));
      console.log("new uuid", combinedUUIDs);
    }
  }, [library?.data?.entity_files]);



  useEffect(() => {
    console.log(library?.data?.card_color)
  }, [library?.data?.card_color])

  useEffect(() => {
    if (library?.data?.companies) {
      const initialSelectedCompanies = library.data.companies.map((company: any) => company.id);
      setSelectedCompanies(initialSelectedCompanies);
    }
  }, [library?.data?.companies]);

  useEffect(() => {
    if (mode === 'edit' && libraryId) {
      refetch();
    }
  }, [mode, libraryId]);


  const handleSubmit = async (values: any) => {
    
    try {
    const backUID = localStorage.getItem('uuids');
    const frontUID = localStorage.getItem('frontId');
      
    let data = {
      ...values,
      status: 'published',
    };

    if (backUID) {
      try {
        const parsedBackUID = JSON.parse(backUID);
        if (Array.isArray(parsedBackUID) && parsedBackUID.length > 0) {
          data = {
            ...data,
            entity_files: {
              uuid: frontUID,
              files: parsedBackUID.map((id: any) => ({
                id,
                tag: ""
              })),
            },
          };
        }
      } catch (error) {
        console.error('Failed to parse backUID:', error);
      }
    }
      handleImageData(values, data, 'cover');

      if (mode === 'create') {
        await createLibrary({ data }).unwrap();
        localStorage.removeItem('frontId');
        navigate(paths.library);
      } else if (mode === 'edit' && libraryId) {
        localStorage.removeItem('frontId');
        await updateLibrary({ id: libraryId, data }).unwrap();
        
        navigate(paths.library);
      }
    } catch (error) {
      console.error('Failed to create/update library:', error);
    }
  };

  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({ id: 'library.edit', defaultMessage: 'Edit:' }) + values.title
                    : formatMessage({ id: 'library.title', defaultMessage: 'Create library' })}
                </b>
              </div>
              <Actions>
                <Button
                  appearance="secondary"
                  type="reset"
                  onClick={() => {
                    navigate(paths.library)
                    localStorage.removeItem('uuids');
                    localStorage.removeItem('frontId');
                  }}
                >
                  {formatMessage({ id: 'library.cancel', defaultMessage: 'Cancel' })}
                </Button>
                <Button appearance="primary" type="submit">
                  {mode === 'create'
                    ? formatMessage({ id: 'library.publish', defaultMessage: 'Publish' })
                    : formatMessage({ id: 'library.save', defaultMessage: 'Save' })}
                </Button>
              </Actions>
            </FormHeader>
            <TabContent>
              <FormContent>
                <Row>
                  <FormikInput
                    label={formatMessage({ id: 'library.titleLabel', defaultMessage: 'Title' })}
                    name="title"
                    placeholder={formatMessage({ id: 'library.titlePlaceholder', defaultMessage: 'Enter a library title' })}
                    autoComplete="off"
                    required
                  />
                </Row>
                <Row>
                  <FormikTextarea
                    label={formatMessage({ id: 'library.describtionLabel', defaultMessage: 'Description' })}
                    name="description"
                    placeholder={formatMessage({ id: 'library.descriptionPlaceholder', defaultMessage: 'Enter a library description' })}
                    autoComplete="off"
                    required
                  />
                </Row>
                <Row>
                  <WangEditor name="content" />
                </Row>
                <Row>
                  <FormikInput
                    label={formatMessage({ id: 'library.cardColorLabel', defaultMessage: 'Card color' })}
                    name="card_color"
                    placeholder={formatMessage({ id: 'library.cardColorPlaceholder', defaultMessage: 'Enter a library card color' })}
                    autoComplete="off"
                    type="color"
                  />
                </Row>
                <Row>
                  <ImageUploadWrapper name="cover" type="photos" />
                </Row>
                <Row>
                  <MultiSelect
                   label={formatMessage({ id: 'library.company', defaultMessage: 'Company' })}
                    name="companies"
                    options={companiesData?.data?.map((company: any) => ({ value: company.id, label: company.name })) || []}
                    values={values.companies}
                    onChange={(field, value) => {
                    setFieldValue(field, value);
                    setSelectedCompanies(value); 
                    }}
                  />
                </Row> 
               <Row>
            <MultiSelect
            label={formatMessage({ id: 'library.userRoll', defaultMessage: 'User roles' })}
            name="roles"
            options={rolesCompaniesData?.data?.map((role: any) => ({ value: role.id, label: `${role.title}` })) || null}
            values={values.roles}
            onChange={(field, value) => {
              setFieldValue(field, value === "" || (Array.isArray(value) && value.length === 0) ? "" : value);
            }}
          />
           </Row>
           <Row>
             <FormikInput
               label={formatMessage({ id: 'library.releaseDate', defaultMessage: 'Release Date' })}
               name="released_at"
               placeholder={formatMessage({ id: 'library.releaseDatePlaceholder', defaultMessage: 'Enter a release date' })}
               autoComplete="off"
               type="date"
             />
           </Row>
              </FormContent>
            </TabContent>
          </Form>
        )}
      </Formik>
    </FormPaper>
  );
};

export default LibraryForm;
