import styled, { css } from 'styled-components'

export interface StyledTextareaProps {
  error?: boolean | string
  value?: any
  hint?: boolean
  hasPlaceholder?: boolean
  disabled?: boolean
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const StyledTextarea = styled.textarea<StyledTextareaProps>`
  height: 5.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  outline: none;
  resize: none;
  font-family: inherit;
  position: relative;

  ${({ theme }) => `
    ${theme.typography.bodyRegular}
    color: ${theme.palette.contrastHigh};
    background-color: ${theme.palette.transparent};
    border-radius: ${theme.shape.borderRadiusMedium};
    border: 0.063rem solid ${theme.palette.transparent};
    
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumRegular}
      height: 4.5rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      height: 4rem;
    }
  `}

  &::placeholder {
    color: ${({ theme }) => theme.palette.stroke};
  }

  /* {Placeholder} substituted with span  */
  /* see Placeholder.tsx for details  */
  & + span,
  span {
    top: 1rem;

    ${({ theme }) => `      
      @media (max-width: ${theme.breakPoints.md}) {
        ${theme.typography.mediumRegular}
      }
    `}
  }

  ${({ hint }) =>
    hint &&
    css`
      padding-right: 3.25rem;
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.palette.contrastMedium};
    `}
`

export const IconsContainer = styled.div`
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
`
