import styled from 'styled-components'

const Row2 = styled.div<{ $breakpoint?: any; $columns?: number }>`
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(${({ $columns }) => $columns ?? 2}, 1fr);
  gap: 1.5rem;

  ${({ $breakpoint, theme }) => `
    @media (max-width: ${$breakpoint ?? theme.breakPoint}) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  `}
`

export const Flexbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export default Row2
