import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ActionButtons } from '../../components/ActionButtons/ActionButtons'
import { LuPlus } from 'react-icons/lu'
import { Button, Table } from '../../ui-kit/'

import { paths } from '../../config/paths'

import { CourseProps } from '../../interfaces/course'

import {
  Actions,
  SubPageHeader,
  FormPaper,
  TableContainer,
  Title,
} from './Lessons.styles'
import {
  useDeleteLessonMutation,
  useGetAllLessonsQuery,
} from '../../slices/lessonsApiSlice'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
export const Lessons = () => {
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const { data: lessons, error, isLoading, refetch } = useGetAllLessonsQuery({})
  const [deleteLesson] = useDeleteLessonMutation()

  const editLessonAction = (lessonId: number) =>
    navigate(`/lessons/edit/${lessonId}`)

  const deleteLessonAction = (lessonId: number) => {
    deleteLesson(lessonId).then(() => {
      refetch()
    })
  }

  const viewLessonAction = (lessonId: number | string) => {
    navigate(`/lessons/${lessonId}`)
  }

  const lessonsTableData = lessons?.data.map((course: CourseProps) => ({
    id: course.id,
    Title: course.title,
    Actions: (
      <ActionButtons
        id={course.id}
        handleEdit={editLessonAction}
        handleDelete={deleteLessonAction}
      />
    ),
  }))

  useEffect(() => {
    refetch()
  }, [])

  if (error) {
    return null
  }

  return (
    <FormPaper>
      <SubPageHeader>
        <Title>
        {formatMessage({ id: 'lessons.pageTitle', defaultMessage: 'Lessons' })}  
        </Title>

        <Actions>
          <Button
            appearance="primary"
            onClick={() => navigate(paths.createLesson)}
          >
            <LuPlus size={20} />
            {formatMessage({ id: 'lessons.newLesson', defaultMessage: 'New lesson' })}
          </Button>
        </Actions>
      </SubPageHeader>

      <TableContainer>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <Table
            columns={['Title', 'Actions']}
            rows={lessonsTableData}
            onSelect={(id: number | string) => viewLessonAction(id)}
          />
        )}
      </TableContainer>
    </FormPaper>
  )
}
