import { css } from 'styled-components'
import { ButtonBaseProps } from '../base/ButtonBase'
import typography from '../../../theme/typography'
import shape from '../../../theme/shape'

const medium = {
  typography: 'regularSemiBold' as keyof typeof typography,
  padding: '0 1rem',
  height: '3rem',
  borderRadius: 'borderRadiusMedium' as keyof typeof shape,
}

const sizes = { medium }

export type Size = keyof typeof sizes

export enum ButtonSize {
  medium = 'medium',
}

const size = ({ size = 'medium' }: ButtonBaseProps) => {
  const styles = sizes[size] || sizes.medium

  return css`
    ${({ theme }) => theme.typography[styles.typography]}
    border-radius: ${({ theme }) => theme.shape[styles.borderRadius]};
    padding: ${styles.padding};
    height: ${styles.height};
  `
}

export default size
