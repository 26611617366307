import { useField as useFormikField, useFormikContext } from 'formik'

type UseField = {
  name: string
  onChange?: (value: any) => void
} & any

export const useField = ({
  name,
  onChange,
  ...formikFieldProps
}: UseField): any => {
  const form = useFormikContext()
  const [formikField, formikMeta, { setValue, setTouched }] = useFormikField({
    name,
    ...formikFieldProps,
  })

  const { onBlur, ...field } = formikField
  const { error, ...meta } = formikMeta

  const handleChange = (value: any, meta?: any) => {
    setTouched(value)

    if (value !== formikField.value) {
      setValue(value)

      if (onChange) {
        setTimeout(() => {
          onChange(value, meta)
        })
      }
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLElement, Element>) => {
    handleChange(field.value)

    if (!e.target.id) {
      e.target.id = name
    }

    onBlur(e)
  }

  return [
    {
      ...field,
      meta,
      setTouched,
      onChange: handleChange,
      onBlur: handleBlur,
      error: (meta.touched && error) || '',
    },
    form,
  ]
}
