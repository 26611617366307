import React from 'react'
import { CompaniesList } from './CompaniesList/CompaniesList'

interface CompanyDetailsProps {
  role: string
}

export const CompanyDetails: React.FC<CompanyDetailsProps> = ({ role }) => {
  return (
    <div>
      {role === 'super_admin' && <CompaniesList />}
      {role === 'power_user' && <CompaniesList />}
    </div>
  )
}
