import styled from 'styled-components';

export const Aside = styled.aside`
    background: ${({ theme }) => theme.palette.windowBackground};
    width: 240px;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 0.188rem 0.188rem rgb(231, 229, 230, 0.5);
    padding: 0.5rem 1rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & ul {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    & a {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: ${({ theme }) => theme.palette.contrastMedium};
        ${({ theme }) => theme.typography.mediumRegular};
        padding: 0.5rem 1rem;
        transition: 180ms;

        & svg {
            transition: 180ms;
        }

        &:hover {
            color: ${({ theme }) => theme.palette.contrastHigh};
            gap: 0.8rem;
            background: ${({ theme }) => theme.palette.shape};

            & svg {
                color: ${({ theme, color }) => color || theme.palette.primary};
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        display: none;
    }
`;

export const Title = styled.h3`
    color: ${({ theme }) => theme.palette.contrastHigh};
    ${({ theme }) => theme.typography.regularSemiBold};
    margin-bottom: 0.5rem;
`;
