// Schedule.styles.ts
import styled from 'styled-components';
import { StyledPaper } from '../../../../ui-kit/Paper/Paper.styles';

export const FormPaper = styled(StyledPaper)`
    padding: 0;
    overflow: hidden;
`;

export const Title = styled.h2`
    font-size: 1.5rem;
`;

export const Content = styled.div`
    padding: 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Actions = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
    margin-left: 16px;
`;

export const TableTitle = styled.div`
    font-size: 1.2rem;
    margin-top: 1rem;
`;

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 180px;

    label {
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    input {
        width: 100%;
        padding: 0.5rem;
    }
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    input[type='checkbox'] {
        transform: scale(1.2);
    }

    label {
        font-size: 1rem;
    }
`;
