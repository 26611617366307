import styled from 'styled-components';

export const FormPaper = styled.div`
    padding: 0;
    overflow: hidden;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    flex: 1;
`;

export const Title = styled.h2`
    font-size: 1.5rem;
`;

export const LessonHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
`;

export const Created = styled.div`
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.contrastLow};
`;

export const LessonTitle = styled.h3`
    font-size: 1.2rem;
`;

export const SubPageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 5px 1px rgb(231, 229, 230, 0.5);
    border-bottom: 1px solid rgb(210 210 210 / 50%);
    padding: 0 1rem;
    height: 5.063rem;
`;

export const Content = styled.div`
    padding: 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Text = styled.div`
  line-height: 1.5rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
`;

export const LessonsContainer = styled.div`
  display: flex;
  gap: 4rem;
`;

export const NoLessons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
`;

export const LessonsSidebar = styled.div`
  flex: 0 0 350px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LessonsForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const LessonsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const LessonsListItem = styled.li`
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: 180ms ease;

  &:not(:last-child) {
    border-bottom: 0.063rem solid ${({ theme }) => theme.palette.stroke};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.shape};
  }
`;
