import React, { useState } from 'react'
import { ProfileDetails } from './components/ProfileDetails'
import { ProfileUploads } from './components/ProfileUploads'
import { TabsContainer, TabButton, TabContent } from './ProfileTabs.styles'
import Paper from '../../ui-kit/Paper/Paper'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'

export const Profile: React.FC = () => {
  const [activeTab, setActiveTab] = useState('profile')
  const { intl, setLocale } = useIntlContext()

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return <ProfileDetails />
      case 'files':
        return <ProfileUploads type="certificate" />
      case 'documents':
        return <ProfileUploads type="documents" />
      default:
        return null
    }
  }
  return (
    <Paper>
      <TabsContainer>
        <TabButton
          active={activeTab === 'profile'}
          onClick={() => setActiveTab('profile')}
        >
          {formatMessage({
            id: 'profile.buttonProfile',
            defaultMessage: 'Profile',
          })}
        </TabButton>
        <TabButton
          active={activeTab === 'documents'}
          onClick={() => setActiveTab('documents')}
        >
          {formatMessage({
            id: 'profile.buttonDocuments',
            defaultMessage: 'Documents',
          })}
        </TabButton>
        <TabButton
          active={activeTab === 'files'}
          onClick={() => setActiveTab('files')}
        >
          {formatMessage({
            id: 'profile.buttonCertificates',
            defaultMessage: 'Certificates',
          })}
        </TabButton>
      </TabsContainer>
      <TabContent>{renderTabContent()}</TabContent>
    </Paper>
  )
}
