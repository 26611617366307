import React, { useState, useMemo } from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper'; // Adjust import based on your intl context location
import { formatMessage } from '../../locale'; // Adjust import if necessary

interface FilterOption {
  label: string;
  value: string;
}

export const FilterDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<string[]>([]);
  const { intl } = useIntlContext();

  const filterOptions: FilterOption[] = useMemo(() => [
    { label: formatMessage({ id: 'filters.withExpiry', defaultMessage: 'Courses with expiry date' }), value: 'withExpiry' },
    { label: formatMessage({ id: 'filters.withoutExpiry', defaultMessage: 'Courses without expiry date' }), value: 'withoutExpiry' }
  ], [intl.locale]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value: string) => {
    setFilter(prevFilter => {
      if (prevFilter.includes(value)) {
        return prevFilter.filter(item => item !== value);
      } else {
        return [...prevFilter, value];
      }
    });
  };

  const renderSelectedOptions = () => {
    return filter
      .map(value => {
        const option = filterOptions.find(option => option.value === value);
        return option ? option.label : '';
      })
      .filter(label => label !== '')
      .join(', ');
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', minWidth: '240px' }}>
      <div onClick={handleToggle} style={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{filter.length ? renderSelectedOptions() : formatMessage({ id: 'filters.quickFilter', defaultMessage: 'Quick filter' })}</span>
        <span style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }}>▼</span>
      </div>
      {isOpen && (
        <div style={{ position: 'absolute', top: '100%', left: '0', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: 'white', boxShadow: '0 8px 16px rgba(0,0,0,0.2)', zIndex: 1, width: '100%' }}>
          {filterOptions.map(option => (
            <label key={option.value} style={{ display: 'block', padding: '8px', cursor: 'pointer' }}>
              <input
                type="checkbox"
                checked={filter.includes(option.value)}
                onChange={() => handleOptionClick(option.value)}
                style={{ marginRight: '8px' }}
              />
              {option.label}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
