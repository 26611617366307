import styled from 'styled-components';
import { Button as UIButton } from '../../ui-kit';

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Button = styled(UIButton)`
  margin: 0 10px;
`;

export const Content = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const LessonHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`;

export const LessonTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

export const Created = styled.p`
  font-size: 14px;
  color: #777;
  margin: 5px 0;
`;

export const CoverImage = styled.div`
  margin-bottom: 20px;

  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }
`;
