import { createContext } from 'react'

type FormContextValue = {
  disabled?: boolean
  viewMode?: boolean
}

export const FormContext = createContext<FormContextValue>({
  disabled: false,
  viewMode: false,
})

export default FormContext
