// index.ts
import { createIntl, createIntlCache, IntlShape } from '@formatjs/intl';
import en from './en/en';
import sv from './sv/sv';
import { flatObject } from './utils';

type Locale = 'en' | 'sv';

const messages: Record<Locale, Record<string, string>> = {
  en: flatObject(en),
  sv: flatObject(sv),
};


const cache = createIntlCache();

let intl: IntlShape = createIntl(
  {
    locale: 'en',
    messages: messages['en'],
  },
  cache
);

export const setLocale = (locale: Locale) => {
  intl = createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

//export const formatMessage = intl.formatMessage

export const formatMessage = (
  descriptor: Parameters<IntlShape['formatMessage']>[0],
  values?: Parameters<IntlShape['formatMessage']>[1]
): string => {
  const result = intl.formatMessage(descriptor, values);
  return Array.isArray(result) ? result.join('') : result;
};

export const formatErrorMessage = (errorId: string, ...args: any) =>
  formatMessage({ id: `Error.${errorId}` }, ...args)


export default intl;
export { messages };
