import React, {
  MutableRefObject,
  ForwardedRef,
  ForwardRefRenderFunction,
  forwardRef,
  useMemo,
  useState,
  useLayoutEffect,
  useEffect,
} from 'react'
import { createPortal } from 'react-dom'
import { usePopper, Modifier } from 'react-popper'
import { Options, createPopper } from '@popperjs/core'

type PopoverOptions = Omit<Partial<Options>, 'modifiers'> & {
  createPopper?: typeof createPopper
  modifiers?: ReadonlyArray<Modifier<unknown>>
}

type PopoverProps = {
  options?: PopoverOptions
  className?: string
  referenceElement?: HTMLElement | null
  containerElement?: HTMLElement | null
  open?: boolean
  children: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export type PopoverElement = HTMLDivElement | null

export const customModifiers: Modifier<unknown>[] = [
  {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn({ state }) {
      state.styles.popper.width = `${state.rects.reference.width}px`
    },
    effect({ state }) {
      const el = state.elements.reference
      const { width } = el.getBoundingClientRect()

      state.elements.popper.style.width = `${width}px`
    },
  },
]

const Popover: ForwardRefRenderFunction<PopoverElement, PopoverProps> = (
  {
    className,
    containerElement,
    referenceElement,
    open = true,
    children,
    options,
    ...props
  }: PopoverProps,
  ref: ForwardedRef<PopoverElement>
) => {
  const [popperElement, setPopperElement] = useState<PopoverElement>(null)

  const canUseDOM = typeof window !== 'undefined'
  const useIsomorphicLayoutEffect = canUseDOM ? useLayoutEffect : useEffect

  useIsomorphicLayoutEffect(() => {
    if (typeof ref === 'function') {
      ref(popperElement)
      return
    }

    if (ref) {
      ;(ref as MutableRefObject<PopoverElement>).current = popperElement
    }
  }, [open, ref, popperElement])

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: customModifiers,
    ...options,
  })

  const style = useMemo(
    () => ({
      ...styles.popper,
      zIndex: 1000000,
      ...(!open ? { display: 'none' } : null),
    }),
    [styles, open]
  )

  return globalThis.document
    ? createPortal(
        open ? (
          <div
            ref={setPopperElement}
            className={className}
            {...attributes.popper}
            style={style}
            {...props}
          >
            {children}
          </div>
        ) : null,
        containerElement || globalThis.document.body
      )
    : null
}

Popover.displayName = 'Popover'

export default forwardRef(Popover)
