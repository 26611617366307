import styled from 'styled-components';

export const ListContainer = styled.div`
    padding: 20px;
`;

export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
`;

export const Actions = styled.div`
    display: flex;
    gap: 10px;
`;

export const Button = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;

    &:hover {
        color: #aa1167;
    }
`;

export const AddButton = styled(Button)`
    margin-bottom: 20px;
    background-color: #aa1167;
    color: white;
    padding: 10px;
    border-radius: 4px;

    &:hover {
        background-color: #881054;
    }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;
