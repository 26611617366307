import styled, { css } from 'styled-components'

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`

export const GroupContainer = styled.div<{ isRow?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  ${({ isRow }) =>
    isRow &&
    css`
      flex-direction: row;
    `}
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & svg {
    color: ${({ theme }) => theme.palette.visualCue};
  }
`

export const Label = styled.label<{ disabled?: boolean; hasError?: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${(p) => p.theme.typography.bodyRegular};
  color: ${(p) => p.theme.palette.contrastHigh};

  &:before {
    box-sizing: border-box;
    content: '';
    height: 1.25rem;
    width: 1.25rem;
    flex: 0 0 1.25rem;
    border: 0.0625rem solid ${({ theme }) => theme.palette.stroke};
    background: ${({ theme }) => theme.palette.windowBackground};
    border-radius: 50%;
    transition: 180ms ease;
  }

  &:hover:before {
    background: ${({ theme }) => theme.palette.shape};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0.25rem;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 0.75rem;
    height: 0.75rem;
    background: ${({ theme }) => theme.palette.contrastHigh};
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;
    transition: 180ms ease;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${(p) => p.theme.palette.contrastMedium};
      pointer-events: none;

      &:before {
        border-color: ${theme.palette.stroke};
      }

      &:after {
        background: ${theme.palette.contrastMedium};
      }
    `}

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:before {
        border-color: ${theme.palette.statusRed};
      }
    `}
`

export const StyledRadio = styled.input<{
  hasError?: boolean
  disabled?: boolean
  checked?: boolean
}>`
  position: absolute;
  left: -9999px; // hide native input

  ${({ disabled, theme }) => css`
    &:checked + label {
      &:before {
        border-color: ${disabled
          ? theme.palette.stroke
          : theme.palette.contrastHigh};
      }

      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }
  `}

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:checked + label {
        &:before {
          border-color: ${theme.palette.statusRed};
        }
      }
    `}
`
