import styled from 'styled-components'

export const Days = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 0.75rem;
`

export default Days
