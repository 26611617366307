import styled, { css } from 'styled-components'

export const StyledAccordion = styled.div`
  overflow: hidden;

  ${({ theme }) => `
    border: 0.063rem solid ${theme.palette.stroke};
    border-radius: ${theme.shape.borderRadius};
  
    @media (max-width: ${theme.breakPoints.md}) {
      border-radius: ${theme.shape.borderRadiusMedium};
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      border-radius: ${theme.shape.borderRadiusSmall};
    }
  `}
`

export const StyledAccordionHeading = styled.div`
  background-color: ${({ theme }) => theme.palette.shape};
  padding: 1.5rem;
  border-bottom: 0.063rem solid ${({ theme }) => theme.palette.stroke};

  ${({ theme }) => `
    ${theme.typography.weight.bold}
    
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.sizes.medium}
      padding: 1rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      padding: 0.75rem;
    }
  `}
`

export const StyledAccordionItem = styled.div`
  &:not(:last-child) {
    border-bottom: 0.063rem solid ${({ theme }) => theme.palette.stroke};
  }
`

export const StyledToggle = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1.5rem;
  transition: 180ms ease;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumRegular}
      padding: 1rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      padding: 0.75rem;
    }
  `}

  & svg {
    transition: 180ms ease;

    ${({ theme }) => `      
      @media (max-width: ${theme.breakPoints.sm}) {
        width: 1.25rem;
        height: 1.25rem;
      }
    `}
  }

  ${({ active }) =>
    active &&
    css`
      & svg {
        transform: rotate(-180deg);
      }
    `}
`

export const StyledContent = styled.div`
  height: 0;
  overflow: hidden;
  transition: 180ms ease;
  background-color: ${({ theme }) => theme.palette.shape};
`

export const StyledAccordionBody = styled.div`
  padding: 1.5rem;
  border-top: 0.063rem solid ${({ theme }) => theme.palette.stroke};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumRegular}
      padding: 1rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      padding: 0.75rem;
    }
  `}
`
