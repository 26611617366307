import styled from 'styled-components'
import PrimaryBase from '../base/PrimaryBase'

const ButtonDanger = styled(PrimaryBase)`
  width: 2.5rem;
  height: 2.5rem;
    background-color: #d10000;
  border-radius: ${({ theme }) => theme.shape.borderRadiusSmall};
`

export default ButtonDanger
