import styled from 'styled-components'
import { Button } from '../../../'

export const FileUploadWrapper = styled.div<{ $showButton: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ $showButton }) => ($showButton ? '1.5rem;' : 'none')};
`

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
`

export const FileName = styled.div`
  ${({ theme }) => theme.typography.mediumBold}
`

export const FileSize = styled.div`
  ${({ theme }) => theme.typography.mediumRegular}
  color: ${({ theme }) => theme.palette.contrastMedium};
`

export const FileIconWrapper = styled.div``
export const FileMetaInfo = styled.div``

export const Spacer = styled.div`
  margin-left: auto;
`
export const FileButton = styled.a`
  cursor: pointer;
  padding: 0.3125rem;
`

export const RemoveButton = styled(Button)`
  cursor: pointer;
  padding: 0.3125rem;
`

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`

export const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.3125rem;
  margin-top: 1.5rem;
`

export const FilePreview = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 37.5rem;
  height: auto;
`

export const UploadLabelContainer = styled.div``
