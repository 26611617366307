import styled from 'styled-components';

export const Layout = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Main = styled.main`
    background: ${({ theme }) => theme.palette.windowBackground};
    padding-left: 240px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */

    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        padding: 10px; /* Adjust padding for smaller screens */
        padding-top: 1px;
    }
`;

export const ContentContainer = styled.div`
    max-width: 1600px; /* Set the maximum width of the content */
    width: 100%; /* Ensure the container takes up full width up to max-width */
    margin: 0 auto; /* Center the container */
    border-left: 1px solid #ddd; /* Add matching borders on the sides */
    border-right: 1px solid #ddd;
    background-color: white;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow */

`;

export const HideOnMobile = styled.span`
  
    @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
        display: none;
    }
`;