import React from 'react'
import { JobTitleList } from './components/JobTitleList'
export function JobTitle() {
  return (
    <div>
      <JobTitleList />
    </div>
  )
}

