import camelCase from 'lodash/camelCase'
import capitalize from 'lodash/capitalize'
import React, { HTMLAttributes, ReactNode } from 'react'
import { StyledMessage } from './ui'

export type MessageType = 'error' | 'info' | undefined

export interface MessageProps extends HTMLAttributes<HTMLDivElement> {
  name?: string
  type?: MessageType
  infoWithIcon?: boolean
  errorWithIcon?: boolean
  icon?: boolean
  children: ReactNode
}

const Message = ({
  name = '',
  type = 'error',
  infoWithIcon,
  errorWithIcon,
  children,
  ...rest
}: MessageProps) => {
  return (
    <StyledMessage
      {...rest}
      type={type}
      data-testid={`test${capitalize(camelCase(name))}${capitalize(type)}Block`}
    >
      {children}
    </StyledMessage>
  )
}

export default Message
