import styled, { css } from 'styled-components'

export const StyledPaper = styled.div<{ size?: 'md' }>`
  background-color: ${({ theme }) => theme.palette.windowBackground};
  padding: 1rem;
  flex: 1;
    
    width: 100%;

  ${({ theme }) => `
    background-color: ${theme.palette.windowBackground};
  
    @media (max-width: ${theme.breakPoints.sm}) {
      padding: 1rem;
    }
  `}

  ${({ size, theme }) =>
    size === 'md' &&
    css`
      @media (max-width: ${theme.breakPoints.sm}) {
        padding: 1.5rem 1rem;
      }
    `};
`
