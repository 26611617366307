import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Header } from '../../ui-kit'
import { Sidebar } from '../Sidebar/Sidebar'
import { paths } from '../../config/paths'
import { useLogoutMutation } from '../../slices/usersApiSlices'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from '../../store'
import { IntlProviderWrapper } from '../../locale/IntlProviderWrapper'
import Toast from '../../ui-kit/Toast/Toast'
import { ContentContainer, Layout, Main } from './BaseLayout.styles'

interface ActionItem {
  caption: string;
  type: 'link' | 'dropdown' | 'button' | 'label' | 'heading';
  onClick?: () => void;
}

const BaseLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const [logout] = useLogoutMutation()

  const { permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }

  const navigationItems: ActionItem[] = []
  if (canDo('company: view') || canDo('company: view own')) {
    navigationItems.push({
      caption: 'company',
      type: 'link',
      onClick: () => navigate(paths.company),
    })
  }

  if (canDo('handbook: view')) {
    navigationItems.push(
      {
        caption: 'handbook',
        type: 'link',
        onClick: () => navigate(paths.handbook),
      })
  }

  if (canDo('course: view') || canDo('course: viewing attached courses')) {
    navigationItems.push({
      caption: 'courses',
      type: 'link',
      onClick: () => navigate(paths.clientCourses),
    })
  }


  navigationItems.push({
      caption: 'library',
      type: 'link',
      onClick: () => navigate(paths.library),
    },
  )

  const toastMessage = useSelector((state: RootState) => state.toast.message)

  return (
    <div style={{ background: '#efefef' }}>
      <IntlProviderWrapper>
        <ContentContainer>
          {toastMessage && <Toast message={toastMessage} />}
          <Layout>
            <Sidebar />
            <Header
              navigation={navigationItems.map((item, index) => ({
                ...item,
                key: index.toString(),
              }))}
            />
            <Main>

              <Outlet />
              <div style={{ width: '100%', height: '100%' }}>
                {children}
              </div>
            </Main>
          </Layout>

        </ContentContainer>
      </IntlProviderWrapper>
    </div>
  )
}

export default BaseLayout
