import React, { ComponentPropsWithoutRef, forwardRef } from 'react'
import { getComponent, ComponentProps } from './ui/getComponent'
import { omit } from 'lodash'

export { ButtonSize } from './ui/css/size'

export type ButtonProps = ComponentProps & ComponentPropsWithoutRef<'button'>

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const Component = getComponent(props)
  return <Component type="button" {...omit(props, 'appearance')} ref={ref} />
})

Button.displayName = 'Button'

export default Button
