import React from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import default calendar styles
import './CalendarStyles.css'; // Import custom calendar styles
import { useIntlContext } from '../../locale/IntlProviderWrapper'; // Adjust import based on your intl context location
import styled from 'styled-components';

const CalendarContainer = styled.div`
    flex: 1 1 33%;
    min-width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const CustomCalendar: React.FC = () => {
  const { intl } = useIntlContext();
  const locale = intl.locale; // Get the current locale from the intl context

  return (
    <CalendarContainer>
      <Calendar locale={locale} />
    </CalendarContainer>
  );
};

export default CustomCalendar;
