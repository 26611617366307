import React from 'react'
import styled from 'styled-components'
import { ListContainer } from '../Select/components/DropdownMenu/ui'

export const CalendarContainer = styled(ListContainer)`
  padding: 1.25rem;
  max-height: max-content;
`

export const Pickers = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 1.5625rem;
`

export const Weeks = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(7, 1fr);
`

export const Week = styled.div`
  color: ${({ theme }) => theme.palette.stroke};
`

export const SelectLabel = styled.div<{ error?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.5rem;
  cursor: pointer;
`
export const Arrow = () => <>{'->'}</>

export const Remove = (props: React.HTMLAttributes<HTMLElement>) => <>{'X'}</>

export const PopoverRef = styled.div`
  position: relative;
  top: -1.25rem;
`
