import React from 'react';
import { LessonContent } from './HtmlView.styles';

interface HtmlViewProps {
  content: string;
}

export const HtmlView: React.FC<HtmlViewProps> = ({ content }: HtmlViewProps) => {
  const sanitizeContent = (htmlContent: string) => {
    // Create a temporary DOM element to manipulate the HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Select all video tags
    const videos = doc.querySelectorAll('video');

    videos.forEach((video) => {
      // Remove download controls
      video.removeAttribute('controlsList');
      video.setAttribute('controlsList', 'nodownload');

      // Remove download attribute if present
      video.removeAttribute('download');
    });

    // Serialize the modified HTML back to a string
    return doc.body.innerHTML;
  };

  return (
    <LessonContent
      className="lesson_content"
      dangerouslySetInnerHTML={{ __html: sanitizeContent(content || '') }}
    />
  );
};

export default HtmlView;
