import { apiSlice, convertToFormData } from './apiSlice'

export const clientCoursesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllClientCourses: builder.query({
      query: () => ({
        url: '/api/v1/courses',
        method: 'GET',
      }),
    }),
    getClientCourse: builder.query({
      query: (id) => ({
        url: `/api/v1/courses/${id}`,
        method: 'GET',
      }),
    }),
    getClientCourseLesson: builder.query({
      query: ({ courseId, lessonId }) => ({
        url: `/api/v1/courses/${courseId}/lessons/${lessonId}`,
        method: 'GET',
      }),
    }),
    completeClientCourseLesson: builder.mutation({
      query: ({ courseId, lessonId }) => ({
        url: `/api/v1/courses/${courseId}/lessons/${lessonId}/complete`,
        method: 'POST'
      }),
    }),

    getClientCourseQuiz: builder.query({
      query: ( courseId ) => ({
        url: `/api/v1/courses/${courseId}/quiz`,
        method: 'GET',
      }),
    }),
    completeClientCourseQuiz: builder.mutation({
      query: ({ courseId, data }) => {
        return {
          url: `/api/v1/courses/${courseId}/quiz`,
          method: 'POST',
          body: data
        }
      },
    }),
  }),
})

export const {
  useGetAllClientCoursesQuery,
  useGetClientCourseLessonQuery,
  useGetClientCourseQuery,
  useCompleteClientCourseLessonMutation,
  useGetClientCourseQuizQuery,
  useCompleteClientCourseQuizMutation
} = clientCoursesApiSlice
