import styled, { css } from 'styled-components'

export const ListContainer = styled.div<{
  isHidden?: boolean
  isOpen?: boolean
}>`
  outline: none;
  margin-top: 0.5rem;
  padding: 0.5rem;
  max-height: 11.5rem;
  overflow-y: auto;

  ${({ theme }) => ` 
    background-color: ${theme.palette.windowBackground};
    border-radius: ${theme.shape.borderRadius};
    color: ${theme.palette.contrastHigh};
    border: 1px solid ${theme.palette.stroke};
  
    @media (max-width: ${theme.breakPoints.md}) {
      max-height: 13rem;
      border-radius: ${theme.shape.borderRadiusMedium};
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      max-height: 13.5rem;
    }
  `}

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    margin: 12px 0;
    background: ${({ theme }) => theme.palette.transparent};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    border-right: 8px solid white;
    background: ${({ theme }) => theme.palette.shape};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.palette.stroke};
  }

  ${({ isOpen, isHidden }) =>
    (!isOpen || isHidden) &&
    css`
      display: none;
    `}
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

type ListItemProps = {
  isHighlighted: boolean
  isSelected: boolean
  isHovered?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-height: 3.25rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.shape.borderRadiusMedium};

  ${({ theme }) => ` 
    @media (max-width: ${theme.breakPoints.md}) {
      min-height: 2.75rem;
      border-radius: ${theme.shape.borderRadiusSmall};
      ${theme.typography.mediumRegular}
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      min-height: 2.25rem;
    }
  `}

  ${({ isHighlighted, isSelected }) =>
    (isHighlighted || isSelected) &&
    css`
      background-color: ${(p) => p.theme.palette.shape};
    `}

  ${({ isHovered }) =>
    isHovered &&
    css`
      &:hover {
        background-color: ${(p) => p.theme.palette.shape};
      }
    `}
`
