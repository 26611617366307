import React from 'react'
import {
  MobileContent,
  MobileContentItem,
  MobileTitle,
  StyledCell,
  StyledHeader,
  StyledTable,
  StyledTr,
  TableContainer,
} from './Table.styles'
import { omit } from 'lodash'
import useMediaQuery from '../hooks/useMediaQuery'
import { lightTheme } from '../theme/theme'
import { Accordion } from '../'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper' 
interface TableProps {
  columns: string[]
  rows: {
    [key: string]: string
  }[]
  collapseOnMedia?: string
  collapseBy?: string
  onSelect?: (id: number | string) => void
}
const Table = ({
  columns,
  rows,
  collapseOnMedia = lightTheme.breakPoints.sm,
  collapseBy,
  onSelect,
}: TableProps) => {
  const isMedia = useMediaQuery(`(max-width: ${collapseOnMedia})`)
  const { intl, setLocale } = useIntlContext();
  const getOmittedRow = (row: any) => {
    return collapseBy
      ? omit(row, [collapseBy])
      : omit(row, [Object.keys(row)[0]])
  }

  const onItemClick = (id: number | string) => {
    onSelect && onSelect(id)
  }

  const formatData = (rows: any) => {
    return rows.map((row: any, index: number) => ({
      id: index,
      header: collapseBy ? row[collapseBy] : row[Object.keys(row)[0]],
      content: (
        <MobileContent>
          {Object.entries(getOmittedRow(row)).map(
            ([key, value]: any, idx: number) => (
              <MobileContentItem key={idx}>
                <MobileTitle>{key}</MobileTitle>
                <div>{value}</div>
              </MobileContentItem>
            )
          )}
        </MobileContent>
      ),
    }))
  }


  return (
    <>
      {isMedia ? (
        <Accordion heading={collapseBy || columns[0]} data={formatData(rows)} />
      ) : (
        <TableContainer>
          <StyledTable>
            <thead>
                <tr>
             
                <StyledHeader role="columnheader">
            <p>{formatMessage({ id: 'lessons.title', defaultMessage: 'Title' })}</p>
          </StyledHeader>
          {columns.slice(1, -1).map((column, index) => (
            <StyledHeader key={index} role="columnheader">
              {column}
            </StyledHeader>
          ))}
          <StyledHeader role="columnheader">
            <p>{formatMessage({ id: 'lessons.actions', defaultMessage: 'Actions' })}</p>
          </StyledHeader>
         
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <StyledTr
                  key={row['id'] || index}
                  onClick={() => onItemClick(row['id'] || index)}
                  onSelect={onSelect}
                >
                  {columns.map((column, index) => (
                    <StyledCell key={index} role="cell">
                      {row[column]}
                    </StyledCell>
                  ))}
                </StyledTr>
              ))}
            </tbody>
          </StyledTable>
        </TableContainer>
      )}
    </>
  )
}

export default Table
