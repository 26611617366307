import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from './baseQueryWithErrorHandling';

export const apiSlice = createApi({
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['User'],
  endpoints: (builder) => ({}),
});

export const convertToFormData = (data:any) => {
  const formData = new FormData();

  // Helper function to append FormData correctly
  const appendFormData = (formData : any, data: any, parentKey = '') => {
    if (data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        appendFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      if (parentKey) {
        formData.append(parentKey, data);
      }
    }
  };

  // Append each key-value pair to the FormData object
  appendFormData(formData, data);
  return formData;
}

export const handleImageData = (values:any, data:any, key:string) => {
  delete data[key];

  if (values[key] !== 'xxx') {
    if (values[key+'_file']) {
      data[key] = values[key+'_file']
    }
  } else {
    data[key] = ''
  }

  return data;
}


//create a function to convert the flat data to a tree structure 
export const transformToTree = <T extends { id: number; parent?: { id: number }; children?: T[] }>(items: T[]): T[] => {
  const map = new Map<number, T>();
  const roots: T[] = [];

  items.forEach(item => {
    map.set(item.id, { ...item, children: [] });
  });

  map.forEach(item => {
    if (item.parent && item.parent.id) {
      const parent = map.get(item.parent.id);
      if (parent) {
        parent.children!.push(item);
      }
    } else {
      roots.push(item);
    }
  });

  return roots;
};
//give the tree strecture a _ fro eaach level in hiraky
export const flattenOptions = (departments: any[], level: number = 0): { value: string | number; label: string }[] => {
  let options: { value: string | number; label: string }[] = [];

  departments.forEach(department => {
    options.push({ value: department.id, label: `${'-'.repeat(level * 1)} ${department.name}` });

    if (department.children && department.children.length > 0) {
      options = options.concat(flattenOptions(department.children, level + 1));
    }
  });

  return options;
};