import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../interfaces/auth';

interface AuthState {
  userInfo: UserInfo | null;
  userCompany: any;
  isAdmin: boolean;
  isSuper: boolean;
  courseManager: boolean;
  canEditCourse: boolean;
  isLoaded: boolean;
  permissions: any;
}

// utils/safeJsonParse.js

export const safeJsonParse = (data:any, defaultValue = {}) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON:', error);
    return defaultValue;
  }
};


const initialState: AuthState = {
  userInfo: null,
  userCompany: null,
  isAdmin: false,
  isSuper: false,
  courseManager: false,
  canEditCourse: false,
  isLoaded: false,
  permissions: safeJsonParse(localStorage.getItem('permissions'), {}), // Use safe parsing here
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
      const userRole = action.payload?.data?.role?.name;
      state.isAdmin = userRole === 'super_admin' || userRole === 'admin' || userRole === 'power_user';
      state.isSuper = userRole === 'super_admin' || userRole === 'power_user';
      state.courseManager = userRole === 'course_manager' || state.isAdmin;
      state.canEditCourse = state.isSuper;
      state.isLoaded = true;

      state.userCompany = state.userInfo.data.selected_company ? state.userInfo.data.selected_company : state.userInfo.data.company;
    },
    setPermissions(state, action) {
      let map: any = {};
      action.payload.data.forEach((permission: any) => {
        map[permission.name] = true;
      });
      localStorage.setItem('permissions', JSON.stringify(map));
      state.permissions = map;
    },
    setLoaded(state) {
      state.isLoaded = true;
    },
    logout(state) {
      state.userInfo = null;
      state.isAdmin = false;
      state.isSuper = false;
      state.courseManager = false;
      state.canEditCourse = false;
      state.isLoaded = false;
      state.permissions = {};
      localStorage.removeItem('permissions');
      localStorage.removeItem('companyOptions');
    },
  },
});

// Selector for checking permissions
export const canDo = (state: AuthState, permission: string): boolean => {
  return !!state.permissions[permission];
};

export const { setCredentials, setPermissions, logout, setLoaded } = authSlice.actions;
export default authSlice.reducer;
