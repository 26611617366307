import React, {
  ForwardedRef,
  forwardRef,
  TextareaHTMLAttributes,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import {
  Container,
  // IconsContainer,
  StyledTextarea,
  StyledTextareaProps,
} from './ui'
import { FieldWrapper } from '../../FieldWrapper'
// import Placeholder from '../../Placeholder'
import { useField } from '../hooks'
import Message from '../ErrorMessage/Message'
import { lightTheme } from '../../../theme/theme'
import { useMedia } from '../../../utils/media'
import { Label } from '../Input/ui'
import { Asterix } from '../../../'

export type TextareaProps = StyledTextareaProps &
  Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    // overridded since we are not passing event object _anywhere_
    onChange?: (value: any) => void
    components?: {
      Input?: typeof React.Component
    }
    showCustomPlaceholder?: boolean
    hint?: string
    disabled?: boolean
    name?: string
    required?: boolean
    info?: string
    infoWithIcon?: boolean
    errorWithIcon?: boolean
    label: string
  }

export const Textarea = forwardRef(
  (
    {
      className,
      components = {},
      hint,
      placeholder,
      showCustomPlaceholder = true,
      error,
      name,
      required,
      info,
      infoWithIcon,
      errorWithIcon,
      label,
      ...props
    }: TextareaProps,
    ref: ForwardedRef<HTMLTextAreaElement | null>
  ) => {
    const { disabled, value } = props
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const [textareaHeight, setTextareaHeight] = useState<number>(
      lightTheme.size.textarea.lg
    )
    const { isMobile, isTablet } = useMedia()

    useEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(textareaRef.current)
        } else {
          ref.current = textareaRef.current
        }
      }
    }, [ref])

    useEffect(() => {
      if (isTablet && !isMobile) {
        setTextareaHeight(lightTheme.size.textarea.sm)
      } else if (isMobile) {
        setTextareaHeight(lightTheme.size.textarea.md)
      } else if (!isTablet && !isMobile) {
        setTextareaHeight(lightTheme.size.textarea.lg)
      }
    }, [isTablet, isMobile])

    useLayoutEffect(() => {
      if (textareaRef.current) {
        const textareaElem = textareaRef.current as HTMLTextAreaElement
        textareaElem.style.height = `${textareaHeight}px`

        const scrollHeight = textareaElem?.scrollHeight
        textareaElem.style.height = scrollHeight + 'px'
      }
    }, [textareaRef, value, textareaHeight])

    return (
      <>
        <Container className={className}>
          {label && (
            <Label>
              {label}
              {required && <Asterix />}
            </Label>
          )}

          <FieldWrapper multiline disabled={disabled} error={!!error}>
            <StyledTextarea
              ref={textareaRef}
              {...props}
              hasPlaceholder={!!placeholder}
              placeholder={placeholder}
              value={value || ''}
              hint={!!hint}
              name={name}
            />

            {/*{showCustomPlaceholder && placeholder ? (*/}
            {/*  <Placeholder>*/}
            {/*    {placeholder}*/}
            {/*    {required && <Asterix />}*/}
            {/*  </Placeholder>*/}
            {/*) : null}*/}
          </FieldWrapper>

          <Message
            name={name}
            type={(error && 'error') || (info && 'info') || undefined}
            infoWithIcon={infoWithIcon}
            errorWithIcon={errorWithIcon}
          >
            {error ? error : info}
          </Message>
        </Container>
      </>
    )
  }
)

export const FormikTextarea = ({ name, onChange, ...props }: TextareaProps) => {
  const [field] = useField({ name, onChange, ...props })

  return (
    <Textarea
      {...props}
      {...field}
      name={name}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
        return field.onChange(e.target.value)
      }}
    />
  )
}

export default FormikTextarea
