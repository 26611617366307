// src/features/Profile/components/ProfileTabs.styles.tsx

import styled from 'styled-components';

export const TabsContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

export const TabButton = styled.button<{ active?: boolean }>`
    padding: 10px 20px;
    border: none;
    background-color: ${({ active }) => (active ? '#007bff' : '#f1f1f1')};
    color: ${({ active }) => (active ? 'white' : 'black')};
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    transition: background-color 0.3s;

    &:hover:not([disabled]) {
        background-color: ${({ active }) => (active ? '#0056b3' : '#ddd')};
    }
`;

export const TabContent = styled.div`
    margin-top: 20px;
`;
