import styled from 'styled-components';
import { StyledPaper } from '../../../../ui-kit/Paper/Paper.styles';

export const FormPaper = styled(StyledPaper)`
    padding: 0;
    overflow: hidden;
`;

export const Title = styled.h2`
    font-size: 1.5rem;
`;

export const Content = styled.div`
    padding: 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const Actions = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
`;

export const TableTitle = styled.div`
    margin-bottom: 0.5rem;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1rem;
`;
