import styled from 'styled-components';
import ButtonBase from './ButtonBase';

const PrimaryBase = styled(ButtonBase)<{ userCompany?: any }>`
    border-color: transparent;
    color: ${({ theme }) => theme.palette.windowBackground};
    background-color: ${({ active, theme, userCompany }) =>
            userCompany?.button_color && active
                    ? userCompany.button_color.active
                    : userCompany?.button_color
                            ? userCompany.button_color.default
                            : theme.palette[active ? 'primaryActive' : 'primary']};

    :hover {
        background-color: ${({ theme, userCompany }) =>
                userCompany?.button_color
                        ? userCompany.button_color.hover
                        : theme.palette.primaryHover};
    }

    :active {
        background-color: ${({ theme, userCompany }) =>
                userCompany?.button_color
                        ? userCompany.button_color.active
                        : theme.palette.primaryActive};
    }

    :disabled {
        background-color: ${({ theme }) => theme.palette.shape};
        color: ${({ theme }) => theme.palette.contrastMedium};
    }
`;

export default PrimaryBase;
