import styled from 'styled-components';
import { Button } from '../../ui-kit'

export const FormPaper = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const SubPageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: #333;
`;

export const CourseImage = styled.div`
    margin-bottom: 20px;

    img {
        width: 100%;
        max-height: 300px;
        object-fit: cover;
        border-radius: 10px;
    }
`;

export const CourseDescription = styled.p`
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
    max-width: 100%; /* Ensure the description takes up the full width */
`;

export const PublisherInfo = styled.div`
    font-size: 14px;
    color: #777;
    margin-bottom: 20px;
`;

export const LessonList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    max-width: 100%; /* Ensure the lesson list takes up the full width */
`;

export const LessonItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
`;

export const LessonTitle = styled.span`
    font-size: 16px;
    color: #333;
    flex: 1; /* Allow the title to take up remaining space */
`;

export const LessonStatus = styled.div`
    font-size: 14px;
    color: #777;
    text-align: right; /* Align the status to the right */
    width: 200px;
    padding-left: 20px;
`;

export const QuizSection = styled.div`
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
`;

export const QuizButton = styled(Button)`
    display: block;
    margin: 0 auto;
`;
