import styled, { css } from 'styled-components'
import { MessageProps } from './Message'

export const StyledMessage = styled.div<MessageProps>`
  ${(p) => p.theme.typography.smallRegular}
  padding-top: 0.25rem;
  min-height: 1.25rem;
  display: flex;
  align-items: center;

  ${({ type, theme }) => {
    if (type === 'info') {
      return css`
        color: ${theme.palette.visualCue};
      `
    }

    if (type === 'error') {
      return css`
        color: ${theme.palette.statusRed};
      `
    }

    return css`
      color: ${theme.palette.contrastMedium};
    `
  }}
`

export const StyledIcon = styled.div`
  padding-right: 0.5rem;
`
