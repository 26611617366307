import styled from 'styled-components'
import PrimaryBase from '../base/PrimaryBase'

const ButtonIconPrimary = styled(PrimaryBase)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: ${({ theme }) => theme.shape.borderRadiusSmall};
`

export default ButtonIconPrimary
