import React, { RefObject } from 'react'
import { useMemo } from 'react'
import { getYear } from 'date-fns'
import { getYearsItems } from '../utils'
import { Select } from '../../Select/Select'

type YearsProps = {
  onChange: (value: number) => void
  minDate?: Date
  maxDate?: Date
  value: Date
  border?: boolean
  calendarRef?: RefObject<HTMLDivElement>
}

const today = getYear(new Date())

export const Years = ({
  onChange,
  minDate,
  maxDate,
  border,
  value,
  calendarRef,
  ...props
}: YearsProps) => {
  const min = minDate ? getYear(minDate) : undefined
  const max = maxDate ? getYear(maxDate) : undefined

  const options = useMemo(
    () =>
      getYearsItems({ min, max }).map((value) => ({
        value: value,
        label: value.toString(),
      })),
    [min, max]
  )

  let year = value ? getYear(value) : today

  if (min && year && year < min) {
    year = min
  }

  if (max && year && year > max) {
    year = max
  }

  return (
    <Select
      options={options}
      onChange={onChange}
      value={year}
      removable={false}
      border={border}
      dropdownContainerRef={calendarRef}
    />
  )
}

export default Years
