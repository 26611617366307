import styled, { css } from 'styled-components'

export const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

interface FileUploaderContainerProps {
  hasValue?: boolean
  isDraggingOver: boolean
  selectedFile: any
  error?: string
}

export const Browse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  ${({ theme }) => `
    ${theme.typography.regularSemiBold};
    color: ${theme.palette.primary};
    
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumSemiBold};
    }
  `}
`

export const FileUploaderContainer = styled.div<FileUploaderContainerProps>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 6rem;

  ${({ theme }) => `
    border: 1px dashed ${theme.palette.stroke};
  
    ${theme.typography.bodyRegular}
    color: ${theme.palette.contrastHigh};
    background-color: ${theme.palette.transparent};
    border-radius: ${theme.shape.borderRadius};
    
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumRegular}
      border-radius: ${theme.shape.borderRadiusMedium};
      min-height: 11rem;
      padding: 0.75rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      min-height: 6.5rem};
    }
  `}

  ${({ selectedFile, theme }) =>
    selectedFile
      ? css`
          background-color: ${theme.palette.windowBackground};
          background-image: none;
          border: 0.063rem solid ${theme.palette.stroke};
        `
      : css`
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23A5A9AFFF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
          align-items: center;
        `}
  }

  ${({ hasValue, error, theme }) =>
    error &&
    (hasValue
      ? css`
          border: 0.063rem solid ${theme.palette.statusRed};
          min-height: auto;
          max-width: 24rem;

          @media (max-width: ${theme.breakPoints.md}) {
            min-height: auto;
          }
        `
      : css`
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23C52C1AFF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        `)}
`

export const DragAndDropArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  & button {
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.sm}) {
        padding: 1rem;
      }
    `}
  }
`

export const DragAndDropAreaDisclaimer = styled.div`
  //position: absolute;
  bottom: 0.5rem;
  //left: 0;
  text-align: center;
  //width: 100%;
  margin-top: 1rem;

  ${({ theme }) => `
    ${theme.typography.mediumRegular}
    color: ${theme.palette.stroke};
    
    @media (max-width: ${theme.breakPoints.md}) {
      display: none;
    }
  `}
`

export const FileInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      & svg {
        width: 1.25rem;
      }
    }
  `}
`

export const FileDetails = styled.div<{ hasLimitError: boolean | undefined }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 1.5rem;

  ${({ hasLimitError }) =>
    hasLimitError &&
    css`
      margin-bottom: 0;
    `}
`

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1.5rem;

  ${({ theme }) => `
    color: ${theme.palette.visualCue};
    
    @media (max-width: ${theme.breakPoints.sm}) {
      gap: 0.5rem;
    }
  `}
`

export const FileInfoText = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      gap: 0.5rem;
    }
  `}
`

interface FileInfoProps {
  hasLimitError: boolean | undefined
}

export const FileName = styled.div`
  ${({ theme }) => `
    ${theme.typography.regularSemiBold};
    color: ${theme.palette.contrastHigh};
    
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumSemiBold};
    }
  `}
`

export const FileSize = styled.div<FileInfoProps>`
  ${({ theme, hasLimitError }) => `
    color: ${
      hasLimitError ? theme.palette.statusRed : theme.palette.contrastMedium
    };
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumRegular}
    }
  `}
`

export const DeleteButton = styled.button`
  border: 0;
  cursor: pointer;

  ${({ theme }) => `
    background-color: ${theme.palette.transparent};
    color: ${theme.palette.contrastHigh};
    border: none;
    position: absolute;
    right: 0;
  `}
`

interface FilePreviewProps {
  backgroundImage: string
}

export const FilePreview = styled.div<FilePreviewProps>`
  display: flex;
  justify-content: center;
  height: 12rem;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${({ backgroundImage, theme }) => `
    background-image: ${backgroundImage ? `url(${backgroundImage})` : 'none'};
    
    @media (max-width: ${theme.breakPoints.md}) {
      height: 10rem;
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      height: 8.6rem;
    }
  `}
`

export const ErrorMessage = styled.div`
  padding-top: 0.5rem;

  ${({ theme }) => `
    ${theme.typography.smallRegular}
    color: ${theme.palette.statusRed};
  `}
`

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`
