import styled, { css } from 'styled-components'

export const TabListWrapper = styled.div`
    display: flex;
    height: 100%;
`

export const TabsWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
`

export const tabButtonPrimaryCss = css<{ color?: string }>`
    color: ${({ theme }) => theme.palette.contrastLow};
    background-color: ${({ theme }) => theme.palette.windowBackground};
    ${({ theme }) => theme.typography.mediumSemiBold};
    padding: 0 0.5rem;
    position: relative;
    font-weight: bold;

    &:hover,
    &:focus,
    &:active {
        color: ${({ color}) => color};
        cursor: pointer;
    }

    &:hover::after,
    &:focus::after,
    &:active::after {
        width: 100%;
        opacity: 1;
    }

    &[aria-selected='true'] {
        color: ${({color}) => color};
        cursor: default;
        pointer-events: none;

        &::after {
            width: 100%;
            opacity: 1;
        }
    }

    &::after {
        content: '';
        width: 0;
        opacity: 0;
        height: 2px;
        background-color: ${({ color}) => color};
        transition: width 0.3s ease-in-out;
        position: absolute;
        bottom: -5px;
        left: 0;
    }
`

export const tabButtonSecondaryCss = css<{ color?: string }>`
    color: ${({ theme }) => theme.palette.contrastLow};
    background-color: ${({ theme }) => theme.palette.transparent};
    border-bottom: 0.063rem solid ${({ theme }) => theme.palette.stroke};
    font-weight: bold;

    &:hover,
    &:focus,
    &:active {
        color: ${({ color }) => color};
        cursor: pointer;
    }

    &:hover::after,
    &:focus::after,
    &:active::after {
        width: 100%;
        opacity: 1;
    }

    &[aria-selected='true'] {
        color: ${({ color}) => color};
        ${({ theme }) => theme.typography.regularSemiBold};
        cursor: default;
        pointer-events: none;

        &::after {
            width: 100%;
            opacity: 1;
        }
    }

    &::after {
        content: '';
        width: 0;
        opacity: 0;
        height: 2px;
        background-color: ${({ color}) => color};
        transition: width 0.3s ease-in-out;
        position: absolute;
        bottom: -5px;
        left: 0;
    }
`

export const TabButton = styled.button<{
  selected: boolean
    appearance: 'primary' | 'secondary'
    color?: string
}>`
    flex: none;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ${({ theme }) => theme.typography.bodyRegular};
    outline: none;
    transition: 180ms ease-in;
    border: none;
    height: 100%;

    ${({ appearance, color }) => {
        switch (appearance) {
            case 'secondary':
                return css`
          ${tabButtonSecondaryCss}
        `
            default:
                return css`
          ${tabButtonPrimaryCss}
        `
        }
    }}
`

export const TabList = styled.div<{ appearance: 'primary' | 'secondary' }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    height: 100%;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    gap: 0.5rem;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    ${({ theme }) => `      
    @media (max-width: ${theme.breakPoints.sm}) {
      height: 2rem;
    }
  `}

    ${({ appearance, theme }) => {
        switch (appearance) {
            case 'secondary':
                return css`
          background: ${theme.palette.transparent};
          border-radius: 0;
        `
            default:
                return css`
          background: ${theme.palette.windowBackground};
        `
        }
    }}
`


export const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`

export const TabContent = styled.div`
    flex: 1;
    height: 100%;
    width: 100%;
    outline: none;
`
