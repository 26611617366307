import styled from 'styled-components';
import ButtonBase from './ButtonBase';

const SecondaryBase = styled(ButtonBase)<{ userCompany?: any }>`
    border-color: ${({ theme, userCompany }) => userCompany?.button_color || theme.palette.primary};
    color: ${({ theme, userCompany }) => userCompany?.button_color || theme.palette.primary};
    background-color: ${({ active, theme, disabled, userCompany }) =>
            disabled
                    ? theme.palette.stroke
                    : userCompany?.button_color
                            ? theme.palette.windowBackground
                            : theme.palette[active ? 'stroke' : 'windowBackground']};

    :hover {
        background-color: ${({ theme }) => theme.palette.shape};
    }

    :active {
        background-color: ${({ theme, userCompany }) =>
                userCompany?.button_color || theme.palette.stroke};
    }

    :disabled {
        background-color: ${({ theme }) => theme.palette.windowBackground};
        color: ${({ theme }) => theme.palette.contrastMedium};
    }
`;

export default SecondaryBase;
