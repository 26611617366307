import styled from 'styled-components'

export const ListContainer = styled.div`
    width: 100%;
    overflow-x: auto;
`

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

export const Thead = styled.thead`
    background-color: #efefef;
    color: #000;
`

export const Tbody = styled.tbody`
    background-color: #fff;
`

export const Tr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`

export const Th = styled.th`
    padding: 10px;
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
`

export const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`
