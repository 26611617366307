import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetLessonQuery } from '../../../../slices/lessonsApiSlice';
import { Content, Created, LessonHeader, LessonTitle, LessonContent, CoverImage } from './LessonView.styles';
import { HtmlView } from '../../../../ui-kit/HtmlView/HtmlView';
import { update } from 'lodash';

interface LessonViewProps {
  lessonId?: string;
}

export const LessonView = ({ lessonId }: LessonViewProps) => {
  const navigate = useNavigate();
  const { data, error, isLoading, refetch } = useGetLessonQuery({ id: lessonId, update: false }, {
    skip: false,
  });

  const lessonData = data?.data || {};

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div>
      <Content>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <LessonHeader>
              <LessonTitle>{lessonData?.title}</LessonTitle>
              <Created>Created: {new Date(lessonData?.created_at).toLocaleDateString()}</Created>
              {lessonData?.cover_image && (
                <CoverImage>
                  <img src={lessonData.cover_image.url} alt={lessonData.cover_image.name} />
                </CoverImage>
              )}
            </LessonHeader>
            <HtmlView content={lessonData?.content || ''}></HtmlView>
          </>
        )}
      </Content>
    </div>
  );
};