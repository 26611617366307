import styled, { css } from 'styled-components'

export const Day = styled.div<{
  isSelected?: boolean
  isHighlighted?: boolean
  isEmpty?: boolean
  selectable?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  cursor: ${({ isEmpty, selectable }) =>
    isEmpty || !selectable ? 'default' : 'pointer'};
  border-radius: 1rem;

  ${({ isSelected, isHighlighted, selectable, theme }) =>
    selectable &&
    (isSelected || isHighlighted) &&
    css`
      background-color: ${theme.palette.primary};
      ${theme.typography.regularSemiBold}
    `};

  ${({ selectable, theme }) =>
    !selectable &&
    css`
      color: ${theme.palette.visualCue};
    `};

  ${({ isEmpty, selectable, theme }) =>
    isEmpty || !selectable
      ? ''
      : css`
          &:hover {
            background-color: ${theme.palette.primaryHover};
            ${theme.typography.regularSemiBold}
          }
        `}
`

export default Day
