import { Backdrop, Home, Title } from './Index.styles'
import  Button  from '../../ui-kit/Button/Button'

export const Index = () => {
  return (
    <Home>
      <Backdrop>
        <Title>Arbetsmiljöportalen</Title>
        <div>
          Welcome to your workplace management hub, where all essential
          resources are centralized for your convenience. From policies and
          procedures to task management, risk assessment, action plans, training
          materials, and knowledge resources - everything you require for
          effective workplace management is conveniently located here.
        </div>
        <Button appearance="primary">Training and activities</Button>
      </Backdrop>
    </Home>
  )
}
