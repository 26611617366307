import React from 'react';
import { LuFileEdit, LuTrash } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface CardProps {
  id: number;
  title: string;
  cover: { url: string };
  bgColor: string;
  description: string;
  viewLink: string;
  handleUpdate: (id: number) => void;
  handleDelete: (id: number) => void;
  canEdit: boolean;
}

const CardContainer = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensures the card takes up full width of its container */
    max-width: 428px; /* Limits the maximum width to 500px */
    position: relative;
    transition: transform 0.3s;
    &:hover {
        transform: scale(1.05);
    }
`;

const ImageContainer = styled.div<{ bgColor: string }>`
    background-color: ${(props) => props.bgColor || '#ac1167'};
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
    width: 100%;
    height: 250px;
    object-fit: cover;
`;

const TitleContainer = styled.div`
    padding: 10px;
    min-height: 60px;
    background-color: #eaffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Title = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
`;

const Actions = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
`;

const IconButton = styled.button`
    background: #efefef;
    border-radius: 3px;
    padding: 3px;
    padding-bottom: 1px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #000;
`;

const CardsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: start; /* Centers the flex items within the container */
    width: 100%;

    @media (max-width: 400px) {
        justify-content: center; /* Center the cards on smaller screens */
    }
`;

export const Card: React.FC<CardProps> = ({
                                            id,
                                            title,
                                            bgColor,
                                            cover,
                                            viewLink,
                                            handleUpdate,
                                            handleDelete,
                                            canEdit,
                                          }) => {
  return (
    <CardContainer>
      <Link to={viewLink} style={{ textDecoration: 'none' }}>
        <ImageContainer bgColor={bgColor}>
          {cover && cover.url && <Image src={cover.url} alt={title} />}
          {canEdit && (
            <Actions>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate(id);
                }}
              >
                <LuFileEdit size={16} />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(id);
                }}
              >
                <LuTrash size={16} />
              </IconButton>
            </Actions>
          )}
        </ImageContainer>
      </Link>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
    </CardContainer>
  );
};

export const CardGrid: React.FC<{ cards: CardProps[] }> = ({ cards }) => {
  return (
    <CardsWrapper>
      {cards.map((card) => (
        <Card key={card.id} {...card} />
      ))}
    </CardsWrapper>
  );
};
