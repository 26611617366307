import { apiSlice } from './apiSlice'

export const departmentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDepartments: builder.query({
      query: () => ({
        url: '/api/v1/admin/companies/departments',
        method: 'GET',
      }),
    }),
    getAllDepartmentsByParent: builder.query({
      query: () => ({
        url: `/api/v1/admin/companies/departments/departments-as-parents/`,
        method: 'GET',
      }),
    }),
    getAllDepartmentsByParentById: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/departments/${id}/departments-as-parents/`,
        method: 'GET',
      }),
    }),
    viewDepartment: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/departments/${id}`,
        method: 'GET',
      }),
    }),
    createDepartment: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/admin/companies/departments`,
        method: 'POST',
        body: data,
      }),
    }),
    updateDepartment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/companies/departments/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteDepartment: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/admin/companies/departments/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAllDepartmentsQuery,
  useGetAllDepartmentsByParentQuery,
  useGetAllDepartmentsByParentByIdQuery,
  useViewDepartmentQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentsApiSlice
