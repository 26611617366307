import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { RedesignedFormikFileUpload } from './RedesignedFileUpload';
import { FileWrapperContainer, FilePreviewContainer, FilePreview, DeleteIcon, IconsContainer } from './FileWrapper.styles';
import { LuTrash } from 'react-icons/lu';
import { Button } from '../../../index';

interface FileWrapperProps {
  name: string;
  label?: string;
  type?: 'photos' | 'documents';
  noResize?: boolean;
}

const FileWrapper: React.FC<FileWrapperProps> = ({ name, label, type, noResize}) => {
  const [field, , helpers] = useField(name);
  const [initialFile, setInitialFile] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (field.value && field.value.url) {
      setInitialFile(field.value);
    } else if (field.value && typeof field.value === 'object' && field.value instanceof File) {
      setInitialFile(URL.createObjectURL(field.value));
    } else {
      setInitialFile(null);
    }
  }, [field.value]);

  const handleChange = (file?: File | null) => {
    if (file) {
      helpers.setValue(file);
      setInitialFile(URL.createObjectURL(file));
    } else {
      helpers.setValue('');
      setInitialFile(null);
    }
    setIsEditing(false);
  };

  const handleRemove = () => {
    helpers.setValue('xxx');
    setInitialFile(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    helpers.setValue(initialFile);
    setIsEditing(false);
  };

  return (
    <div>
      {initialFile && !isEditing ? (
        <FileWrapperContainer>
          <FilePreviewContainer>
            <FilePreview
              src={typeof initialFile === 'string' ? initialFile : initialFile.url}
              alt={initialFile.name || 'Selected file'}
            />
          </FilePreviewContainer>
          <IconsContainer>
            <DeleteIcon onClick={handleRemove}>
              <LuTrash size={20} />
            </DeleteIcon>
          </IconsContainer>
        </FileWrapperContainer>
      ) : (
        <RedesignedFormikFileUpload
          name={`${name}_file`}
          type={type}
          noResize={noResize}
          onChange={handleChange}
        />
      )}
      {isEditing && initialFile && (
        <Button type="button" onClick={handleCancel} style={{ marginTop: '20px' }}>
          Cancel
        </Button>
      )}
    </div>
  );
};

export default FileWrapper;
