import { apiSlice, convertToFormData } from './apiSlice'; 


export const virtualAssistantApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllVirtualAssistant: builder.query({
            query: ({ search = '', page = 1 }) => ({
                url: `/api/v1/virtual-assistants?search=${encodeURIComponent(search)}&page=${page}`,
                method: 'GET',
            }),
        }),
        viewVirtualAssistants: builder.query({
            query: ({ id, update }) => ({
                url: `/api/v1/virtual-assistants/${id}` + (update ? '?view=update' : ''),
                method: 'GET',
            }),
        }),
        createVirtualAssistants: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/virtual-assistants`,
                method: 'POST',
                body: convertToFormData(data),
            }),
        }),
        updateVirtualAssistants: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api/v1/virtual-assistants/${id}?_method=PATCH`,
                method: 'POST',
                body: convertToFormData(data),
            }),
        }),
        deleteVirtualAssistants: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/virtual-assistants/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllVirtualAssistantQuery,
    useViewVirtualAssistantsQuery,
    useCreateVirtualAssistantsMutation,
    useUpdateVirtualAssistantsMutation,
    useDeleteVirtualAssistantsMutation,
} = virtualAssistantApiSlice;