import React from 'react'
import './index.css'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { lightTheme } from './ui-kit/theme/theme'
import AppRouter from './routing'
import { Provider } from 'react-redux'
import { store } from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <AppRouter />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)
