import palette from './palette'
import typography from './typography'
import shape from './shape'

export interface Theme {
  palette: typeof palette
  typography: typeof typography
  shape: typeof shape

  size: {
    icon: {
      xs: 16
      sm: 20
      md: 24
      xxl: 100
    }
    textarea: {
      sm: 64
      md: 72
      lg: 88
    }
  }

  breakPoints: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    xxl: string
  }
}

export const lightTheme: Theme = {
  palette,
  typography,
  shape,

  size: {
    icon: {
      xs: 16,
      sm: 20,
      md: 24,
      xxl: 100,
    },
    textarea: {
      sm: 64,
      md: 72,
      lg: 88,
    },
  },

  breakPoints: {
    xs: '0',
    sm: '36rem', // 576px
    md: '48rem', // 768px
    lg: '62rem', // 992px
    xl: '75rem', // 1200px
    xxl: '87.5rem', // 1400px
  },
}

export const darkTheme: Theme = lightTheme // We dont support dark theme yet
