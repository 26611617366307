import styled from 'styled-components'
import { Size } from '../css/size'

export type ButtonBaseProps = {
  appearance?: string
  active?: boolean
  size?: Size
  disabled?: boolean
  fullWidth?: boolean
}

const ButtonBase = styled.button<ButtonBaseProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 0.75rem;
  border: 0.063rem solid transparent;
  cursor: pointer;
  text-decoration: none;
  transition: 180ms ease;
  white-space: nowrap;
  ${({ theme }) => theme.typography.weight.semiBold};

  :disabled {
    cursor: default;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
      width: 100%;
    `};
`

export default ButtonBase
