import styled from 'styled-components'
import SecondaryBase from '../base/SecondaryBase'

const ButtonIconSecondary = styled(SecondaryBase)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: ${({ theme }) => theme.shape.borderRadiusSmall};
`

export default ButtonIconSecondary
