import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useIntlContext } from '../../locale/IntlProviderWrapper'; // Adjust import based on your intl context location
import { formatMessage } from '../../locale'; // Adjust import if necessary
import { Link, Navigate } from 'react-router-dom';
import { paths } from '../../config/paths';
import { logout as logoutAction } from '../../slices/authSlice';
import { useDispatch } from 'react-redux';
import { useLogoutMutation } from '../../slices/usersApiSlices';
import { useNavigate } from 'react-router-dom';
import useClickOutside from './ClickOutsideToClose';

const Circle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #666;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    position: relative;
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 1000;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const MenuItem = styled.div`
    padding: 10px 20px;
    display: block;
    color: #333;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        background-color: #f1f1f1;
    }
`;

interface ProfileCircleProps {
  name: string;
}

const getInitials = (name: string): string => {
  const names = name.split(' ');
  const initials = names.map((n) => n.charAt(0).toUpperCase()).join('');
  return initials;
};

const ProfileCircle: React.FC<ProfileCircleProps> = ({ name }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { intl } = useIntlContext();
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      await logout({}).unwrap();
      dispatch(logoutAction());
      window.dispatchEvent(new Event('userLoggedOut'));
      navigate(paths.root);
      window.location.reload();  
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div ref={dropdownRef}>
      <Circle onClick={toggleDropdown}>
        {getInitials(name)}
      </Circle>
      <DropdownMenu isOpen={isOpen}>
      <MenuItem as={Link} to={paths.profile} onClick={() => setIsOpen(false)}>{formatMessage({ id: 'profileCircle.profile', defaultMessage: 'Profile' })}</MenuItem>
        <MenuItem onClick={handleLogout}>{formatMessage({ id: 'profileCircle.logout', defaultMessage: 'Logout' })}</MenuItem>
      </DropdownMenu>
    </div>
  );
};

export default ProfileCircle;