import { apiSlice, convertToFormData } from './apiSlice'


export const actionsPlansApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
      getAllActionsPlan: builder.query({
        query: ({ search = '', page = 1 }) => ({
          url: `/api/v1/action-plans?search=${encodeURIComponent(search)}&page=${page}`,
          method: 'GET',
        }),
      }),

      viewActionsPlan: builder.query({
        query: ({ id, update = false }) => ({
          url: `/api/v1/action-plans/${id}` + (update ? '?view=update' : ''),
          method: 'GET',
        }),
      }),
  
      createActionsPlan: builder.mutation({
        query: ({  data }) => ({
          url: `/api/v1/action-plans/`,
          method: 'POST',
          body: convertToFormData(data),
        }),
      }),
      updateActionsPlan: builder.mutation({
        query: ({ id, data }) => ({
          url: `/api/v1/action-plans/${id}?_method=PATCH`,
          method: 'POST',
          body: convertToFormData(data),
        }),
      }),
      deleteActionsPlan: builder.mutation({
        query: ({ id }) => ({
          url: `/api/v1/action-plans/${id}`,
          method: 'DELETE',
        }),
      }),
    }),
  })
  
  export const {
    useGetAllActionsPlanQuery,
    useViewActionsPlanQuery,
    useCreateActionsPlanMutation,
    useUpdateActionsPlanMutation,
    useDeleteActionsPlanMutation,
  } = actionsPlansApiSlice