import React, { ReactNode } from 'react'
import { StyledPaper } from './Paper.styles'

interface PaperProps {
  children: ReactNode
  size?: 'md'
}

const Paper = ({ children, size }: PaperProps) => {
  return <StyledPaper size={size}>{children}</StyledPaper>
}

export default Paper
