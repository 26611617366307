import React, { useEffect, useState, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setCredentials, setPermissions } from '../../../slices/authSlice'
import {
  useGetUserInfoQuery, useGetUserPermissionsQuery,
  useLoginMutation,
  useRecoverPasswordMutation,
} from '../../../slices/usersApiSlices'
import { setMethod, setLocale } from '../../../slices/settingsSlice';

import { Form, FormikInput, Button } from '../../../ui-kit/';

import { loginSchema } from './Login.validation';
import { LoginResponse, UserInfo } from '../../../interfaces/auth';

import {
  Buttons,
  FormContainer,
  LoginContainer,
  Logo,
  Title,
  LinkText,
  Message,
} from './Login.styles';

const Login: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRecoveringPassword, setIsRecoveringPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [login, { isLoading }] = useLoginMutation();
  const [recoverPassword, { isLoading: isRecovering }] = useRecoverPasswordMutation();

  const [shouldFetch, setShouldFetch] = useState(false); // State to control fetching

  const {
    data: user,
    error: userInfoError,
    refetch: refetchUser,
  } = useGetUserInfoQuery({}, {  }); // Skip until shouldFetch is true

  const {
    data: permissions,
    error: userPermissionsError,
    refetch: refetchPermissions,
  } = useGetUserPermissionsQuery({}, {  }); // Skip until shouldFetch is true


  useEffect(() => {
    if (user) {
      dispatch(setCredentials(user as UserInfo));
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  }, [user, dispatch, navigate]);

  useEffect(() => {
    if (userInfoError) {
      console.error('Fetching user info error:', userInfoError);
    }
  }, [userInfoError]);

  const handleSubmit = async (values: LoginResponse) => {


    dispatch(setMethod('POST'));
    dispatch(setLocale('en'));

    try {
      const res =(await login(values).unwrap()) as UserInfo;
      setShouldFetch(true); // Allow fetch after login is successful
      const res2 = (await refetchUser().unwrap()) as UserInfo; // Explicitly refetch user data
      const permissions = await refetchPermissions().unwrap(); // Explicitly refetch permissions


      dispatch(setCredentials({ ...res2 }));
      dispatch(setPermissions(permissions));
      window.dispatchEvent(new Event('userLoggedIn'));
      navigate('/dashboard');
    } catch (err: any) {
      console.log(err);
      handleErrors(err);
    }
  };

  const handleRecoverPassword = async (values: { email: string }) => {
    try {
      await recoverPassword({email: values.email}).unwrap();
      setSuccessMessage('Password recovery email was sent');
      setErrorMessage(''); // Clear any previous error message
    } catch (err: any) {
      setSuccessMessage(''); // Clear any previous success message
      handleErrors(err);
    }
  };

  const handleErrors = (err: any) => {
    if (err.status === 401) {
      setErrorMessage(err?.data?.message || 'Unauthorized');
    } else if (err.status === 404) {
      setErrorMessage(err?.data?.message || 'Not Found');
    } else if (err.status === 500) {
      setErrorMessage(err?.data?.message || 'Server Error');
    } else {
      setErrorMessage('An unexpected error occurred.');
    }
  };

  return (
    <LoginContainer>
      <FormContainer>
        <Logo>
          <img src="/assets/logo.svg" alt="Logo" />
        </Logo>

        <Title>{isRecoveringPassword ? 'Recover Password' : 'Log in'}</Title>

        {isRecoveringPassword ? (
          <Form onSubmit={handleRecoverPassword}>
            <FormikInput
              label="Your email"
              name="email"
              placeholder="Email"
              autoComplete="off"
              required
            />
            {errorMessage && <Message error>{errorMessage}</Message>}
            {successMessage && <Message success>{successMessage}</Message>}
            <Buttons>
              <Button type="submit" disabled={isRecovering}>
                {isRecovering ? 'Sending...' : 'Send Recovery Email'}
              </Button>
            </Buttons>
            <LinkText onClick={() => setIsRecoveringPassword(false)}>
              Back to login
            </LinkText>
          </Form>
        ) : (
          <Form onSubmit={handleSubmit} validationSchema={loginSchema}>
            <FormikInput
              label="Your email"
              name="email"
              placeholder="Email"
              autoComplete="off"
              required
            />
            <FormikInput
              label="Your password"
              name="password"
              type="password"
              placeholder="Password"
              autoComplete="off"
              required
            />
            {errorMessage && <Message error>{errorMessage}</Message>}
            <Buttons>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Log in'}
              </Button>
            </Buttons>
            <LinkText onClick={() => setIsRecoveringPassword(true)}>
              Forgot password?
            </LinkText>
          </Form>
        )}
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
