import React, { useEffect, useState } from 'react'

import {
  DeleteButton,
  FileDetails,
  FileInfo,
  FileName,
  FileSize,
  FileInfoContainer,
  FileInfoText,
  FilePreview,
  TopSection,
} from './RedesignedFileUpload.styles'
import { formatBytes } from '../../../utils/formatBytes'
import { BiTrash } from 'react-icons/bi'
import { BiImage } from 'react-icons/bi'

type ValueProps = {
  name: string
  file?: File | string
  type?: 'photos' | 'documents'
  onDownload?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  onRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void
  children?: React.ReactNode
  hasLimitError?: any
}

export const RedesignedPreviewFile = ({
  file,
  onRemove,
  hasLimitError,
}: // ...props
ValueProps) => {
  const fileData = ((file as File)?.name ? file : null) as File
  const remoteURL = ((file as File)?.name ? '' : file) as string
  const [fileDataURL, setFileDataURL] = useState(null)
  const isImage = (file as File)?.type?.startsWith?.('image/')

  useEffect(() => {
    let fileReader: FileReader

    if (file && !remoteURL) {
      fileReader = new FileReader()

      fileReader.onload = (e) => {
        const { result } = e.target as any

        if (result) {
          setFileDataURL(result)
        }
      }

      fileReader.readAsDataURL(fileData)
    }

    return () => {
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort()
      }
    }
  }, [fileData, file, remoteURL])

  return (
    <TopSection>
      {fileData ? (
        <FileInfoContainer data-testid="file-details-container">
          <FileDetails hasLimitError={hasLimitError}>
            <FileInfo>
              <BiImage size={20} />
              <FileInfoText>
                <FileName>{fileData.name}</FileName>
                <FileSize hasLimitError={hasLimitError}>
                  {formatBytes(fileData.size)}
                </FileSize>
              </FileInfoText>
            </FileInfo>
            <DeleteButton onClick={onRemove} aria-label="Delete file">
              <BiTrash size={20} />
            </DeleteButton>
          </FileDetails>

          {isImage && !hasLimitError && (fileDataURL || remoteURL) && (
            <FilePreview
              backgroundImage={fileDataURL || remoteURL}
              aria-label="File Preview"
            />
          )}
        </FileInfoContainer>
      ) : null}
    </TopSection>
  )
}

export default RedesignedPreviewFile
