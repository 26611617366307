import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useIntlContext } from '../../../locale/IntlProviderWrapper'
import { formatMessage } from '../../../locale'
import {
  useGetAllUsersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useActivateUserMutation,
  useDeactivateUserMutation, useGetCompanyUsersQuery,
} from '../../../slices/usersApiSlices'
import {
  ListContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Actions,
} from './UsersList.styles'
import { LuFileEdit, LuTrash, LuUserCheck, LuUserX } from 'react-icons/lu'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

export const UsersList: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { intl } = useIntlContext()

  const { permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }
  const {
    data: users,
    error,
    isLoading,
    refetch,
  } = useGetCompanyUsersQuery({})


  useEffect(() => {
    refetch()
  }, [location.key])

  const [updateUser] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [activateUser] = useActivateUserMutation()
  const [deactivateUser] = useDeactivateUserMutation()

  const handleUpdate = (id: number) => {
    navigate(`/users/edit/${id}`)
  }

  const handleDelete = async (userId: number) => {
    try {
      const userConfirmed = window.confirm('Are you sure you want to delete this user?')
      if (!userConfirmed) return
      deleteUser({ id: userId }).then(() => {
        refetch()
      }).catch((error) => {
        console.error('Failed to delete user:', error)
      })

    } catch (error) {
      console.error('Error deleting user:', error)
    }
  }

  const handleActivateDeactivate = async (userId: number, isActive: boolean) => {
    try {
      if (isActive) {
        await deactivateUser({ id: userId }).unwrap()
      } else {
        await activateUser({ id: userId }).unwrap()
      }
      refetch()
      console.log(`User with ID ${userId} ${isActive ? 'deactivated' : 'activated'} successfully.`)
    } catch (error) {
      console.error(`Error ${isActive ? 'deactivating' : 'activating'} user:`, error)
    }
  }

  if (isLoading) return <div>{formatMessage({ id: 'users.loading', defaultMessage: 'Loading...' })}</div>
  if (error) return <div>{formatMessage({ id: 'users.errorLoading', defaultMessage: 'Error loading users' })}</div>

  return (
    <ListContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>{formatMessage({ id: 'userList.name', defaultMessage: 'Name' })}</Th>
            <Th>{formatMessage({ id: 'userList.email', defaultMessage: 'Email' })}</Th>
            <Th>{formatMessage({ id: 'userList.role', defaultMessage: 'Role' })}</Th>
            <Th>{formatMessage({ id: 'userList.status', defaultMessage: 'Status' })}</Th>
            <Th>{formatMessage({ id: 'userList.action', defaultMessage: 'Actions' })}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users?.data.map((user: any) => (
            <Tr key={user.id}>
              <Td>{user.firstname} {user.lastname}</Td>
              <Td>{user.email}</Td>
              <Td>{formatMessage({
                id: `users.roleDescriptions.${user.role.name}`,
                defaultMessage: user.role.name,
              })}</Td>
              <Td style={{ color: user.is_active ? 'green' : 'red' }}>
                {user.is_active
                  ? formatMessage({ id: 'userStatus.active', defaultMessage: 'Active' })
                  : formatMessage({ id: 'userStatus.inactive', defaultMessage: 'Inactive' })}
              </Td>
              <Td>
                <Actions>
                  {canDo('user: update') && (
                    <Button onClick={() => handleUpdate(user.id)}><LuFileEdit size={16} /></Button>
                  )}

                  {canDo('user: delete') && (
                    <Button onClick={() => handleDelete(user.id)}><LuTrash size={16} /></Button>
                  )}

                  {canDo('user: deactivate') && (
                    <Button onClick={() => handleActivateDeactivate(user.id, user.is_active)}>
                      {user.is_active ? <LuUserX size={16} /> : <LuUserCheck size={16} />}
                    </Button>
                  )}
                </Actions>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </ListContainer>
  )
}
