import React, { useState, useEffect } from 'react';

import { Overlay, Modal, CloseButton, Form, Label, Input, Button } from './JobTitlePopup.styles';
import { LuX } from 'react-icons/lu';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useCreateJobTitleMutation, useUpdateJobTitleMutation } from '../../../../../slices/jobTitleApiSlice'
import { useSelector } from 'react-redux';
interface JobTitlePopupProps {
  jobTitle?: { id: number; name: string };
  mode: 'create' | 'edit';
  onClose: () => void;
  onPublish: () => void;
}

export const JobTitlePopup: React.FC<JobTitlePopupProps> = ({ jobTitle, mode, onClose, onPublish }) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { intl } = useIntlContext();
  const [jobTitleName, setJobTitleName] = useState(jobTitle ? jobTitle.name : '');

  const [createJobTitle] = useCreateJobTitleMutation();
  const [updateJobTitle] = useUpdateJobTitleMutation();

  useEffect(() => {
    if (jobTitle) {
      setJobTitleName(jobTitle.name);
    }
  }, [jobTitle]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === 'create') {
      await createJobTitle({ data: { name: jobTitleName } }).unwrap();
    } else {
      await updateJobTitle({ id: jobTitle!.id, data: { name: jobTitleName } }).unwrap();
    }
    onPublish();
  };
  const color = userCompany?.button_color || '#A4366D';
  return (
    <Overlay>
      <Modal>
        <CloseButton onClick={onClose}>
          <LuX size={24} />
        </CloseButton>
        <h2>{mode === 'create' ? formatMessage({ id: 'jobTitle.createTitle', defaultMessage:  'Create Job Title' }) : formatMessage({ id: 'jobTitle.editTitle', defaultMessage: 'Edit Job Title' })}</h2>
        <Form onSubmit={handleSubmit}>
          <Label>{formatMessage({ id: 'jobTitle.nameLabel', defaultMessage: 'Job Title' })}</Label>
          <Input
            type="text"
            value={jobTitleName}
            onChange={(e) => setJobTitleName(e.target.value)}
            required
          />
          <Button type="submit" color={color}>{mode === 'create' ? formatMessage({ id: 'create', defaultMessage: 'Create' }) : formatMessage({ id: 'save', defaultMessage: 'Save' })}</Button>
        </Form>
      </Modal>
    </Overlay>
  );
};


