import { get } from 'lodash';
import { apiSlice, convertToFormData } from './apiSlice'; 


export const InstructionsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllInstructions: builder.query({
        query: () => ({
                url: '/api/v1/instructions',
                method: 'GET',
            }),
        }),
        viewInstructions: builder.query({
            query: ({ id, update }) => ({
                url: `/api/v1/instructions/${id}` + (update ? '?view=update' : ''),
                method: 'GET',
            }),
        }),
        createInstructions: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/instructions`,
                method: 'POST',
                body: convertToFormData(data),
            }),
        }),
        updateInstructions: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api/v1/instructions/${id}?_method=PATCH`,
                method: 'POST',
                body: convertToFormData(data),
            }),
        }),
        deleteInstructions: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/instructions/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllInstructionsQuery,
    useViewInstructionsQuery,
    useCreateInstructionsMutation,
    useUpdateInstructionsMutation,
    useDeleteInstructionsMutation,
} = InstructionsApiSlice;