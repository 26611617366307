import React from 'react';
import styled from 'styled-components';
import SecondaryBase from '../base/SecondaryBase';
import { buttonDefaultMedia } from '../css/media';
import size from '../css/size';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const ButtonSecondary = styled(SecondaryBase)`
    ${buttonDefaultMedia};
    ${size};

    ${({ theme, userCompany }: { theme: any; userCompany?: any }) =>
            userCompany?.button_color
                    ? `border-color: ${userCompany.button_color}; color: ${userCompany.button_color};`
                    : `
    border-color: ${theme.palette.primary};
    color: ${theme.palette.primary};
  `}

    background-color: ${({ active, theme, userCompany }) =>
            userCompany?.button_color
                    ? active
                            ? `${userCompany.button_color}`
                            : 'transparent'
                    : active
                            ? `${theme.palette.stroke}`
                            : `${theme.palette.windowBackground}`};

    :hover {
        background-color: ${({ theme, userCompany }) =>
                userCompany?.button_color
                        ? `${userCompany.button_color}33`
                        : theme.palette.shape};
        color: ${({ theme, userCompany }) =>
                userCompany?.button_color
                        ? `${userCompany.button_color}`
                        : theme.palette.primary};
        border-color: ${({ theme, userCompany }) =>
                userCompany?.button_color
                        ? `${userCompany.button_color}`
                        : theme.palette.primary};
        cursor: pointer;
    }

    :active {
        background-color: ${({ theme, userCompany }) =>
                userCompany?.button_color
                        ? `${userCompany.button_color}`
                        : theme.palette.stroke};
    }

    :disabled {
        background-color: ${({ theme }) => theme.palette.windowBackground};
        color: ${({ theme }) => theme.palette.contrastMedium};
        cursor: not-allowed;
    }
`;

const ButtonSecondaryWrapper: React.FC<React.ComponentPropsWithoutRef<'button'>> = (props) => {
  const { userCompany } = useSelector((state: RootState) => state.auth);
  return <ButtonSecondary {...props} userCompany={userCompany} />;
};

export default ButtonSecondaryWrapper;
