import React, { useState, useEffect } from 'react';

import { Overlay, Modal, CloseButton, Form, Label, Input, Button } from './OfficePopup.styles';
import { LuX } from 'react-icons/lu';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useCreateOfficeMutation, useUpdateOfficeMutation } from '../../../../../slices/officeApiSlice'
import { useSelector } from 'react-redux';
interface OfficePopupProps {
  office?: { id: number; name: string };
  mode: 'create' | 'edit';
  onClose: () => void;
  onPublish: () => void;
}

const OfficePopup: React.FC<OfficePopupProps> = ({ office, mode, onClose, onPublish }) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { intl } = useIntlContext();
  const [officeName, setOfficeName] = useState(office ? office.name : '');

  const [createOffice] = useCreateOfficeMutation();
  const [updateOffice] = useUpdateOfficeMutation();

  useEffect(() => {
    if (office) {
      setOfficeName(office.name);
    }
  }, [office]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === 'create') {
      await createOffice({ data: { name: officeName } }).unwrap();
    } else {
      await updateOffice({ id: office!.id, data: { name: officeName } }).unwrap();
    }
    onPublish();
  };
  const color = userCompany?.button_color || '#A4366D';
  return (
    <Overlay>
      <Modal>
        <CloseButton onClick={onClose}>
          <LuX size={24} />
        </CloseButton>
        <h2>{mode === 'create' ? formatMessage({ id: 'office.createTitle', defaultMessage: 'Create Office' }) : formatMessage({ id: 'office.editTitle', defaultMessage: 'Edit Office' })}</h2>
        <Form onSubmit={handleSubmit}>
          <Label>{formatMessage({ id: 'office.nameLabel', defaultMessage: 'Office Name' })}</Label>
          <Input
            type="text"
            value={officeName}
            onChange={(e) => setOfficeName(e.target.value)}
            required
          />
          <Button type="submit" color={color}>{mode === 'create' ? formatMessage({ id: 'create', defaultMessage: 'Create' }) : formatMessage({ id: 'save', defaultMessage: 'Save' })}</Button>
        </Form>
      </Modal>
    </Overlay>
  );
};

export default OfficePopup;
