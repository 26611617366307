import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetClientCourseQuizQuery,
  useCompleteClientCourseQuizMutation,
  useGetClientCourseQuery,
} from '../../slices/clientCoursesApiSlice'
import {
  Content,
  Actions,
  QuestionContainer,
  QuestionTitle,
  ChoicesContainer,
  ChoiceItem,
  NextButton,
  PreviousButton,
  SubmitButton,
  SubPageHeader,
  Title,
  SubTitle,
  QuestionNumber,
  Divider,
  FormPaper,
  PassingScoreText,
  ResultContainer,
  ResultText,
} from './ClientCourseQuizPage.styles'
import { paths } from '../../config/paths'
import { Button } from '../../ui-kit'
import { Formik, Form } from 'formik'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'

export const ClientCourseQuizPage = () => {
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { courseId } = useParams<{ courseId: string }>()
  const { data: quizData, error: quizError, isLoading: quizLoading, refetch: refetchQuiz } = useGetClientCourseQuizQuery(courseId!)
  const { data: courseData, error: courseError, isLoading: courseLoading, refetch: refetchCourse } = useGetClientCourseQuery(courseId!)
  const [completeQuiz] = useCompleteClientCourseQuizMutation()
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)
  const [answers, setAnswers] = useState<{ [key: number]: { [key: number]: boolean } }>({})
  const [quizCompleted, setQuizCompleted] = useState<boolean>(false)
  const [quizResult, setQuizResult] = useState<any>(null)

  useEffect(() => {
    refetchQuiz()
    refetchCourse()
  }, [refetchQuiz, refetchCourse])

  const handleChoiceChange = (questionId: number, choiceId: number) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        [choiceId]: !prev[questionId]?.[choiceId]
      }
    }))
  }

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(prev => prev + 1)
  }

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex(prev => prev - 1)
  }

  const handleQuizSubmit = async () => {
    try {
      const formattedAnswers = {
        questions: quizData.data.questions.map((question: any) => ({
          id: question.id,
          choices: question.choices.map((choice: any) => ({
            id: choice.id,
            selected: !!answers[question.id]?.[choice.id]
          }))
        }))
      };

      const result = await completeQuiz({ courseId: courseId!, data: formattedAnswers }).unwrap();
      setQuizResult(result.data);
      setQuizCompleted(true);
    } catch (error) {
      console.error('Failed to complete quiz:', error);
    }
  };

  const currentQuestion = quizData?.data.questions[currentQuestionIndex]
  const isLastQuestion = currentQuestionIndex === quizData?.data.questions.length - 1

  if (quizLoading || courseLoading) return <div>Loading...</div>
  if (quizError || courseError) return <div>Error loading quiz or course</div>

  return (
    <FormPaper>
      <SubPageHeader>
        <Title>{courseData?.data.title}</Title>
        <Button appearance="secondary" onClick={() => navigate(quizCompleted?`/client-courses`:`/client-courses/${courseId}`)}>
          {formatMessage({ id: 'quiz.returnCourse', defaultMessage: 'Return to course' })}
        </Button>
      </SubPageHeader>
      <Content>
        {quizCompleted ? (
          <ResultContainer isPassed={quizResult.is_passed}>
            <ResultText isPassed={quizResult.is_passed}>
              {quizResult.is_passed ? 
                formatMessage({ id: 'quiz.isPassed', defaultMessage: 'Congratulations, you have passed the quiz!' }) :
                formatMessage({ id: 'quiz.isFailed', defaultMessage: 'Sorry, you have not passed the quiz.' })
              }
            </ResultText>
            <ResultText>
              {formatMessage({
                id: 'quiz.yourScore', defaultMessage: 'Your score:'})} {quizResult.score}%
            </ResultText>
          </ResultContainer>
        ) : (
          <Formik
            initialValues={{ choices: currentQuestion?.choices.map(() => ({ is_correct: false })) || [] }}
            onSubmit={async (_, { setSubmitting }) => {
              setSubmitting(false);
              if (isLastQuestion) {
                await handleQuizSubmit();
              } else {
                handleNextQuestion();
              }
            }}
          >
            {({ values }) => (
              <Form>
                <QuestionContainer>
                    <QuestionNumber>{formatMessage({ id: 'quiz.question', defaultMessage: 'Question' })} {currentQuestionIndex + 1}/{quizData?.data.questions.length}</QuestionNumber>
                  <PassingScoreText>
                      {formatMessage({ id: 'quiz.needScore', defaultMessage: 'You need a score of' })} {quizData?.data.passing_percentage}% {formatMessage({ id: 'quiz.toPass', defaultMessage: 'to pass this quiz. Good luck!' })}
                  </PassingScoreText>
                  <QuestionTitle>{currentQuestion?.question_text}</QuestionTitle>
                    <SubTitle>
                      {formatMessage({ id: 'quiz. selectAnswears', defaultMessage: 'Please select the answers that apply:' })}
                  </SubTitle>
                  <Divider />
                  <ChoicesContainer>
                    {currentQuestion?.choices.map((choice: any, index: number) => (
                      <ChoiceItem key={choice.id}>
                        <input
                          type="checkbox"
                          checked={answers[currentQuestion.id]?.[choice.id] || false}
                          onChange={() => handleChoiceChange(currentQuestion.id, choice.id)}
                        />
                        {choice.choice_text}
                      </ChoiceItem>
                    ))}
                  </ChoicesContainer>
                </QuestionContainer>
                <Actions>
                  {currentQuestionIndex > 0 && (
                    <PreviousButton appearance="secondary" type="button" onClick={handlePreviousQuestion}>
                      {formatMessage({ id: 'quiz.prvsQuestion', defaultMessage: 'Previous Question' })}
                    </PreviousButton>
                  )}
                  {isLastQuestion ? (
                    <SubmitButton appearance="primary" type="submit">
                      {formatMessage({ id: 'quiz.sendQuiz', defaultMessage: 'Send Quiz' })}
                    </SubmitButton>
                  ) : (
                    <NextButton appearance="primary" type="submit">
                      {formatMessage({ id: 'quiz.nextQuestion', defaultMessage: 'Next Question' })}
                    </NextButton>
                  )}
                </Actions>
              </Form>
            )}
          </Formik>
        )}
      </Content>
    </FormPaper>
  )
}

export default ClientCourseQuizPage
