export const paths = Object.freeze({
  root: '/',
  profile: '/profile',
  resetPassword: '/reset-password',

  company: '/company',
  createCompany: '/company/create/:parentId',
  editCompany: '/company/edit/:companyId',

  handbook: '/handbook',
  viewHandbook: '/handbook/:id',
  createHandbook: '/handbook/create',
  editHandbook: '/handbook/edit/:handbookId',

  claim: '/claim',
  viewClaim: '/claim/:id',
  createClaim: '/claim/create',
  editClaim: '/claim/edit/:claimId',

  investigation: '/investigation',
  viewInvestigation: '/investigation/:id',
  createInvestigation: '/investigation/create',
  editInvestigation: '/investigation/edit/:investigationId',

  clientCourses: '/client-courses',
  clientCoursesPage: '/client-courses/:courseId',
  clientCoursesLessonPage: '/client-courses/:courseId/:lessonId',
  clientCoursesQuizPage: '/client-courses-quiz/:courseId',

  courses: '/courses',
  createCourse: '/courses/create',
  editCourse: '/courses/edit/:courseId',
  copyCourse: '/courses/copy/:courseId',

  lessons: '/lessons',
  viewLesson: '/lessons/:lessonId',
  createLesson: '/lessons/create',
  editLesson: '/lessons/edit/:lessonId',

  library: '/library',
  viewLibrary: '/library/:id',
  createLibrary: '/library/create',
  editLibrary: '/library/edit/:libraryId',

  group: '/group',
  dashboard: '/dashboard',

  annual_plan: '/annual-plan',

  actionsPlan: '/actions',
  viewActionsPlan: '/actions/:id',
  createActionsPlan: '/actions/create',
  editActionsPlan: '/actions/edit/:actionsPlanId',

  users: '/users',
  viewUser: '/users/:id',
  createUser: '/users/create',
  editUser: '/users/edit/:userId',

  virtualAssistant: '/virtualAssistant',
  viewVirtualAssistant: '/virtualAssistant/:id',
  createVirtualAssistant: '/virtualAssistant/create',
  editVirtualAssistant: '/virtualAssistant/edit/:virtualAssistantId',

  instructions: '/instructions',
  viewInstructions: '/instructions/:id',
  createInstructions: '/instructions/create',
  editInstructions: '/instructions/edit/:instructionsId',

  support: '/support',
  viewSupport: '/support/:id',
  createSupport: '/support/create',
  editSupport: '/support/edit/:supportId',
})
