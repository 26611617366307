import styled from 'styled-components';

export const Container = styled.div`
    margin: 20px;
`;

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    gap: 20px;
`;

export const CalendarContainer = styled.div`
    max-width: 300px;
    height: 100%;
`;
