import { apiSlice } from './apiSlice'

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonalDashboard: builder.query({
      query: () => ({
        url: '/api/v1/dashboard/personal',
        method: 'GET',
      }),
    }),
    getCompanyDashboard: builder.query({
      query: () => ({
        url: '/api/v1/dashboard/company',
        method: 'GET',
      }),
    })
  }),
})

export const {
  useGetPersonalDashboardQuery,
  useGetCompanyDashboardQuery,
} = dashboardApiSlice
