import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import settingsReducer from './slices/settingsSlice';
import companyReducer from './slices/companySlice';
import toastReducer from './slices/toastSlice';
import { apiSlice } from './slices/apiSlice'; // Import apiSlice after fixing circular dependency
import { handleApiError } from './slices/errorHandler';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    settings: settingsReducer,
    toast: toastReducer,
    company: companyReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

// Custom middleware to handle API errors
store.subscribe(() => {
  const state = store.getState();
  apiSlice.middleware({
    dispatch: store.dispatch,
    getState: store.getState,
  });
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
