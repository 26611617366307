import styled, { css } from 'styled-components'
import Label from './FieldWrapper'

import { Label as CheckboxLabel } from './components/Checkbox/ui'
import { Label as RadioLabel } from './components/Radio/ui'
import { IconsContainer as InputIcons } from './components/Input/ui'
import { StyledCard as PreviewFileContainer } from './components/FileUpload/ui'
import { Icons as TimeIcons } from './components/Time/ui'
import { RemoveButton, UploadLabelContainer } from './components/FileUpload/ui'
type FieldsetProps = { disabled?: boolean; viewMode?: boolean }

const Fieldset = styled.fieldset`
  border: none;
  outline: none;
  padding: 0;
  margin: 0;

  ${({ disabled = false, viewMode = false }: FieldsetProps) =>
    disabled || viewMode
      ? css`
          ${Label}, ${CheckboxLabel}, ${RadioLabel} {
            pointer-events: none;

            ${() =>
              disabled &&
              css`
                background-color: ${(p) => p.theme.palette.shape};
              `}
          }

          ${UploadLabelContainer} {
            label {
              pointer-events: none;

              ${() =>
                disabled &&
                css`
                  background-color: ${(p) => p.theme.palette.shape};
                `}
            }
          }

          ${InputIcons},
          ${TimeIcons} {
            display: none;
          }

          ${() =>
            disabled &&
            css`
              ${PreviewFileContainer} {
                background-color: ${(p) => p.theme.palette.shape};
              }
            `}

          ${RemoveButton} {
            display: none;
          }
        `
      : ''}

  ${({ viewMode = false }: FieldsetProps) =>
    viewMode
      ? css`
          ${UploadLabelContainer} {
            display: none;
          }
        `
      : ''}
`

export default Fieldset
