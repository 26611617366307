import palette from './palette'
const baseFontFamily = 'Plus Jakarta Sans'
const baseFontSize = 1
const baseLineHeight = '1.25rem'

const sizes = {
  small: `
    font-size: 0.75rem;
    line-height: 1rem;
  `,

  medium: `
    font-size: 1.0rem;
    line-height: 1rem;
  `,

  regular: `
    font-size: 1rem;
    line-height: 1.25rem;
  `,

  subTitle: `
    font-size: 1.5rem;
    line-height: 1.875rem;
  `,

  title: `
    font-size: 2.25rem;
    line-height: 2.875rem;
  `,
}

// new typography styles
const variants = {
  // 36px/44px
  heading1: `
    font-size: 2.25rem;
    line-height: 2.75rem;
  `,
  // 24px/32px
  heading2: `
    font-size: 1.5rem;
    line-height: 2rem;
  `,
  // 16px/20px
  text: `
    font-size: 1rem;
    line-height: 1.25rem;
  `,
}

// @TODO: change after font-face implementation
const weight = {
  regular: 'font-weight: 400;',
  semiBold: 'font-weight: 500;',
  bold: 'font-weight: 600;',
  black: 'font-weight: 700;',
}

const bodyBase = `
  font-family: ${baseFontFamily};
  font-size: ${baseFontSize}rem;
  line-height: ${baseLineHeight};
  color: ${palette.contrastHigh};
  ${weight.regular}
`

const typography = {
  baseFontFamily,
  baseFontSize,
  bodyBase,
  sizes,
  weight,

  smallRegular: `
    ${sizes.small}
    ${weight.regular}
  `,

  smallSemiBold: `
    ${sizes.small}
    ${weight.semiBold}
  `,

  smallBold: `
    ${sizes.small}
    ${weight.bold}
  `,

  mediumRegular: `
    ${sizes.medium}
  `,

  mediumSemiBold: `
    ${sizes.medium}
    ${weight.semiBold}
  `,

  regularSemiBold: `
    ${sizes.regular}
    ${weight.semiBold}
  `,

  mediumBold: `
    ${sizes.medium}
    ${weight.bold}
  `,

  smallItalic: `
    ${sizes.small}
    text-decoration: italic;
  `,

  bodyRegular: `
    ${sizes.regular}
    ${weight.regular}
  `,

  bodyBold: `
    ${sizes.regular}
    ${weight.bold}
  `,

  bodyItalic: `
    ${sizes.regular}
    text-decoration: italic;
  `,

  successRegular: `
    ${sizes.regular}
    color: ${palette.statusGreen};
  `,

  successBold: `
    ${sizes.regular}
    ${weight.bold}
    color: ${palette.statusGreen};
  `,

  successItalic: `
    ${sizes.regular}
    text-decoration: italic;
    color: ${palette.statusGreen};
  `,

  errorRegular: `
    ${sizes.regular}
    color: ${palette.statusGreen};
  `,

  errorBold: `
    ${sizes.regular}
    ${weight.bold}
    color: ${palette.statusGreen};
  `,

  errorItalic: `
    ${sizes.regular}
    text-decoration: italic;
    color: ${palette.statusGreen};
  `,

  subTitleRegular: `
    ${sizes.subTitle}
    color: ${palette.statusGreen};
  `,

  subTitleBold: `
    ${sizes.subTitle}
    ${weight.bold}
    color: ${palette.statusGreen};
  `,

  subTitleItalic: `
    ${sizes.subTitle}
    text-decoration: italic;
    color: ${palette.statusGreen};
  `,

  titleRegular: `
    ${sizes.title}
    color: ${palette.statusGreen};
  `,

  titleBold: `
    ${sizes.title}
    ${weight.bold}
    color: ${palette.statusGreen};
  `,

  titleItalic: `
    ${sizes.title}
    text-decoration: italic;
    color: ${palette.statusGreen};
  `,

  // new typography styles
  heading1: `
    ${variants.heading1}
  `,

  heading2: `
    ${variants.heading2}  
  `,

  text: `
    ${variants.text}
  `,

  textBold: `
    ${weight.semiBold}
    ${variants.text}
  `,
} as const

export default typography
