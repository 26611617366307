import React, { useState } from 'react'
import { useIntlContext } from '../../locale/IntlProviderWrapper' // Adjust import based on your intl context location
import { formatMessage } from '../../locale' // Adjust import if necessary
import { useNavigate } from 'react-router-dom'
import {
  CourseCardWrapper,
  ProgressBar,
  Progress,
  Button,
  Title,
  DateText,
  LessonsText,
  ProgressContainer,
  ImageContainer,
  Image,
} from './CourseCard.styles'

interface CourseCardProps {
  course: any;
}

const CourseCard: React.FC<CourseCardProps> = ({ course }) => {

  const completed_quiz_attempts = course.get_completed_quiz_attempts || 0
  const allowed_quiz_attempts = course.get_allowed_quiz_attempts || 1
  const passed_lessons = course.number_of_passed_lessons || 0

  const navigate = useNavigate()
  const progressPercent = ((passed_lessons / course.number_of_lessons) * 100).toFixed(1)
  const quizPercent = ((completed_quiz_attempts / allowed_quiz_attempts) * 100).toFixed(1)
  const { intl } = useIntlContext()
  let buttonText = formatMessage({ id: 'courseCard.continue', defaultMessage: 'CONTINUE' })

  let isQuiz = false

  if (passed_lessons === 0) {
    buttonText = formatMessage({ id: 'courseCard.start', defaultMessage: 'START' })
  } else if (passed_lessons === course.number_of_lessons) {

    if (completed_quiz_attempts < allowed_quiz_attempts) {
      buttonText = formatMessage({ id: 'courseCard.quiz', defaultMessage: 'START QUIZ' })
      isQuiz = true
    }
  }

  let canStart = course.status && course.status.enum && course.status.enum !== 'published'

  if (course.progress_status === 'completed' || course.progress_status === 'failed') {
    //  canStart = false;
  }

  const [isHovered, setIsHovered] = useState(false)

  return (
    <CourseCardWrapper
      isHovered={isHovered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {course.image && (
        <ImageContainer>
          {course.image.url && <Image src={course.image.url} alt={course.image.name} />}
        </ImageContainer>
      )}
      <Title>{course.title}</Title>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <DateText>
          {formatMessage({
            id: 'courseCard.startDate',
            defaultMessage: 'Start date',
          })}: {course.started_at}
        </DateText>

        {course.expired_at && (
          <DateText>
            {formatMessage({
              id: 'courseCard.endDate',
              defaultMessage: 'Expiry date',
            })}: {course.expired_at}
          </DateText>
        )}
      </div>


      {canStart && !isQuiz && (
        <div>
          <Button type="button" onClick={() => navigate('/client-courses/' + course.id)}>
            {buttonText}
          </Button>
          <ProgressContainer>
            <ProgressBar>
              <Progress percent={parseFloat(progressPercent)} />
            </ProgressBar>
            <LessonsText>{parseInt(progressPercent)}%</LessonsText>
          </ProgressContainer>

          <LessonsText>
            {formatMessage({
              id: 'courseCard.lessons',
              defaultMessage: 'Lessons',
            })}: {passed_lessons} / {course.number_of_lessons}
          </LessonsText>
        </div>

      )}

      {canStart && isQuiz && (
        <div>
          <Button type="button" onClick={() => navigate('/client-courses/' + course.id)}>
            {buttonText}
          </Button>
          <ProgressContainer>
            <ProgressBar>
              <Progress percent={parseFloat(quizPercent)} />
            </ProgressBar>
            <LessonsText>{parseInt(quizPercent)}%</LessonsText>
          </ProgressContainer>

          <LessonsText>
            {formatMessage({
              id: 'courseCard.attempts',
              defaultMessage: 'Attempts',
            })}: {completed_quiz_attempts} / {allowed_quiz_attempts}
          </LessonsText>
        </div>
      )}

      {course.progress_status === 'completed' && (
        <div style={{ marginTop: '20px', color: 'green' }}>
          <b>Completed!</b>
        </div>
      )}

      {course.progress_status === 'failed' && (
        <div style={{ marginTop: '20px', color: 'red' }}>
          <b>Failed</b>
        </div>
      )}

    </CourseCardWrapper>
  )
}

export default CourseCard
