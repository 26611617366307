import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { ChartWrapper, ChartTitle } from './Chart.styles';
import './chartSetup'; // Ensure this is the correct path to chartSetup.ts
interface ChartData {
  title: string;
  percent: number;
}

interface PerformanceChartProps {
  data: ChartData[];
  title: string;
}

export const PerformanceChart: React.FC<PerformanceChartProps> = ({ data, title }) => {
  const chartData = {
    labels: data.map(item => item.title),
    datasets: [
      {
        data: data.map(item => item.percent),
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384']
      }
    ]
  };

  const options: ChartOptions<'doughnut'> = {
    cutout: '70%',
    plugins: {
      legend: {
        display: true,
        position: 'bottom'
      },
      datalabels: {
        formatter: (value: number) => `${value}%`,
        color: '#fff',
        anchor: 'end',
        align: 'start'
      }
    }
  };

  return (
    <ChartWrapper>
      <ChartTitle>{title}</ChartTitle>
      <Doughnut data={chartData} options={options} />
    </ChartWrapper>
  );
};
