import { css } from 'styled-components'

export const buttonDefaultMedia = () => {
  return css`
    ${({ theme }) => `  
    @media (max-width: ${theme.breakPoints.md}) {
      border-radius: ${theme.shape.borderRadiusMedium};
      height: 3rem;
      ${theme.typography.sizes.medium}
      
      & svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      border-radius: ${theme.shape.borderRadiusSmall};
      height: 2.25rem;
      padding: 0 1rem;
      ${theme.typography.sizes.small}
      
      & svg {
        width: 1rem;
        height: 1rem;
      }
    }
  `}
  `
}

export const buttonPrimaryMedia = () => {
  return css`
    ${({ theme }) => `  
    @media (max-width: ${theme.breakPoints.md}) {
      border-radius: ${theme.shape.borderRadiusMedium};
      height: 3rem;
      ${theme.typography.sizes.medium}
      
      & svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
      height: 3.25rem;
      ${theme.typography.sizes.regular}
    }
  `}
  `
}
