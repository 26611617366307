import styled from 'styled-components';

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
`;

export const Modal = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 9999;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
    zIndex: 9999;
`;

export const Label = styled.label`
    font-weight: bold;
`;

export const Button = styled.button`
    padding: 10px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
        background: #0056b3;
    }
`;

export const Message = styled.div<{ success: boolean }>`
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    background-color: ${({ success }) => (success ? 'green' : 'red')};
`;
