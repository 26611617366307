import styled, { css } from 'styled-components'
import { inputCSS } from '../Input'
import Label from '../../FieldWrapper'
import { Button } from '../../../'

export const Container = styled.div`
  position: relative;
`

export const StyledLabel = styled(Label)`
  display: flex;
  position: relative;
  ${inputCSS};
  background-color: ${(p) => p.theme.palette.windowBackground};
  height: 3.5rem;
`

export const Icons = styled.div`
  position: absolute;
  height: 0.75rem;
  top: calc(50% - 0.375rem);
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const ListContainer = styled.ul<{ isHidden: boolean; isOpen: boolean }>`
  background-color: transparent;
  color: ${(p) => p.theme.palette.contrastHigh};
  outline: none;
  margin-top: 0.25rem;
  padding: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1.25rem;

  ${({ isOpen, isHidden }) =>
    (!isOpen || isHidden) &&
    css`
      display: none;
    `}
`

type ListItemProps = {
  isHighlighted: boolean
  isSelected: boolean
  isHovered?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const StyledButton = styled(Button)`
  width: 5.875rem;
`
