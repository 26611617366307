// AnnualPlan.styles.tsx
import styled from 'styled-components';

export const MonthGrid = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakPoints.sm}) {
    grid-template-columns: repeat(1, 2fr);
  }
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${(props) => props.theme.breakPoints.lg}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: ${(props) => props.theme.breakPoints.xl}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const MonthContainer = styled.div`
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  min-height: 200px;
`;

export const Entry = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  margin: 4px 0;
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
`;

export const UploadOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const UploadContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;
