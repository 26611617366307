export const hexToRGBA = (hex: string, opacity: number) => {
  let r, g, b
  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16)
    g = parseInt(hex.slice(3, 5), 16)
    b = parseInt(hex.slice(5, 7), 16)
  } else if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16)
    g = parseInt(hex[2] + hex[2], 16)
    b = parseInt(hex[3] + hex[3], 16)
  } else {
    throw new Error('Invalid HEX color.')
  }
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

