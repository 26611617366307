import { apiSlice, convertToFormData } from './apiSlice';

export const libraryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllLibraries: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/library?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
      }),
    }),
    viewLibrary: builder.query({
      query: ({ id, update = false }) => ({
        url: `/api/v1/library/${id}` +   (update ? '?view=update' : ''),
        method: 'GET',
      }),
    }),

    createLibrary: builder.mutation({
      query: ({ data }) => ({
        url: `/api/v1/library`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    updateLibrary: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/library/${id}?_method=PATCH`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    deleteLibrary: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/library/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllLibrariesQuery,
  useViewLibraryQuery,
  useCreateLibraryMutation,
  useUpdateLibraryMutation,
  useDeleteLibraryMutation,
} = libraryApiSlice;
