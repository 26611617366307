import { apiSlice, convertToFormData } from './apiSlice'

export const claimsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllClaims: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/claims?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
      }),
    }),

    viewClaim: builder.query({
      query: ({ id, update = false }) => ({
        url: `/api/v1/claims/${id}` + (update ? '?view=update' : ''),
        method: 'GET',
      }),
    }),
    createClaim: builder.mutation({
      query: ({  data }) => ({
        url: `/api/v1/claims`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    updateClaim: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/claims/${id}?_method=PATCH`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    deleteClaim: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/claims/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAllClaimsQuery,
  useViewClaimQuery,
  useCreateClaimMutation,
  useUpdateClaimMutation,
  useDeleteClaimMutation,
} = claimsApiSlice
