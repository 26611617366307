import { Link, useNavigate } from 'react-router-dom'
import  Paper  from '../../ui-kit/Paper/Paper'
import { paths } from '../../config/paths'
import { LuArrowLeft, LuPlus } from 'react-icons/lu'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useViewInvestigationQuery } from '../../slices/investigationsApiSlice'
import { HtmlView } from '../../ui-kit/HtmlView/HtmlView'
import { CoverImage } from '../Lessons/components/LessonView/LessonView.styles'
import { formatMessage } from '../../locale'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'
export const InvestigationPage = () => {
  const { userCompany } = useSelector((state: any) => state.auth)
  const { intl } = useIntlContext()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: investigation,
    error,
    isLoading,
    refetch,
  } = useViewInvestigationQuery({ id })

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error loading investigation</div>

  const investigationData = investigation?.data
  const color = userCompany?.button_color || 'rgba(164, 54, 109, 1)'
  return (
    <Paper>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid silver',
        marginBottom: '20px',
      }}>
        <div style={{ paddingBottom: '15px', paddingTop: '15px' }}>
          <h2>{investigationData?.title}</h2>
        </div>

        <div>
          <Link to={paths.investigation} className={'link'}>
           <p style={{color: color}}> <LuArrowLeft size={12}></LuArrowLeft> {formatMessage({ id: 'investigation.goBackToInvestigation', defaultMessage: 'BACK TO RISK ASSESSMENT LIST' })}</p>
          </Link>
        </div>
      </div>


      <div style={{marginBottom: 10}}>
        <h3>{investigationData?.description || ''}</h3>
      </div>

      <div>
        <HtmlView content={investigationData?.content || ''}></HtmlView>
      </div> 

    </Paper>
  )
}
