import { apiSlice } from "./apiSlice"; 

const projectGroupApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllProjectGroups: builder.query({
            query: () => ({
                url: "/api/v1/admin/companies/project-groups",
                method: "GET",
            }),
        }),
        viewProjectGroup: builder.query({
            query: ({ id }) => ({
                url: `/api/v1/admin/companies/project-groups/${id}`,
                method: "GET",
            }),
        }),
        createProjectGroup: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/admin/companies/project-groups`,
                method: "POST",
                body: data,
            }),
        }),
        updateProjectGroup: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api/v1/admin/companies/project-groups/${id}`,
                method: "PUT",
                body: data,
            }),
        }),
        deleteProjectGroup: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/admin/companies/project-groups/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useGetAllProjectGroupsQuery,
    useViewProjectGroupQuery,
    useCreateProjectGroupMutation,
    useUpdateProjectGroupMutation,
    useDeleteProjectGroupMutation,
} = projectGroupApiSlice;