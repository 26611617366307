import React, { useEffect } from 'react'
import {
  TabContent,
  TabsWrapper,
  TabList,
  TabButton,
  Content,
  TabListWrapper,
} from './Tabs.styles'
import { omit } from 'lodash'
import { useSelector } from 'react-redux'
type TabPanelProps = React.ComponentPropsWithoutRef<typeof TabContent>

export const TabPanel = ({ children }: TabPanelProps) => (
 
  <TabContent role="tabpanel" tabIndex={0}>
    {children}
  </TabContent>
)

type Tab = {
  label: React.ReactNode
  content: React.ReactNode
}

type TabsProps = React.ComponentPropsWithoutRef<typeof TabsWrapper> & {
  selectedTab?: number
  onTabSelect?: (x: number) => void
  isContentEmbedded?: boolean
  appearance?: 'primary' | 'secondary'
  tabs: Tab[]
}

const Tabs = ({
  tabs,
  appearance = 'primary',
  selectedTab = 0,
  onTabSelect,
  isContentEmbedded = true,
}: TabsProps) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const color = userCompany?.main_color || '#A4366D';

  return (
    <TabsWrapper>
      <TabListWrapper data-testid="tabs">
        <TabList role="tablist" appearance={appearance}>
          {tabs.map(({ label }, index) => (
            <TabButton
              key={index}
              appearance={appearance}
              role="tab"
              selected={selectedTab === index}
              aria-selected={selectedTab === index ? 'true' : 'false'}
              onClick={() => onTabSelect && onTabSelect(index)}
              data-testid={`${label}TabButton`}
              color={color} //match ompany color the tabs
            >
              {label}
            </TabButton>
          ))}
        </TabList>
      </TabListWrapper>

      {isContentEmbedded ? (
        <Content>{tabs[selectedTab].content}</Content>
      ) : null}
    </TabsWrapper>
  )
}

export const useTabs = (props: TabsProps) => {
  return {
    Buttons: () => (
      <Tabs {...omit(props, 'appearance')} isContentEmbedded={false} />
    ),

    Content: () => (
      <TabPanel>{props.tabs[props.selectedTab || 0].content}</TabPanel>
    ),
  }
}

export default Tabs
