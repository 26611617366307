import { apiSlice, convertToFormData } from './apiSlice'

let formData
export const lessonsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllLessons: builder.query({
      query: () => ({
        url: '/api/v1/admin/courses/lessons/all',
        method: 'GET',
      }),
    }),
    getLesson: builder.query({
      query: ({id, update = false}) => ({
        url: `/api/v1/admin/courses/lessons/${id}` +   (update ? '?view=update' : ''),
        method: 'GET',
      }),
    }),
    createLesson: builder.mutation({
      query: (data) => ({
        url: `/api/v1/admin/courses/lessons`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    createCourseLesson: builder.mutation({
      query: ({ courseId, data }) => ({
        url: `/api/v1/admin/courses/${courseId}/lessons`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    updateLesson: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/admin/courses/lessons/${id}?_method=PATCH`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    deleteLesson: builder.mutation({
      query: (id) => ({
        url: `/api/v1/admin/courses/lessons/${id}`,
        method: 'DELETE',
      }),
    }),
    uploadMedia: builder.mutation({
      query: ({ file }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('entity_type', 'lesson')

        return {
          url: `/api/v1/admin/files/upload/image`,
          method: 'POST',
          body: formData,
        }
      },
    }),
  }),
})

export const {
  useGetAllLessonsQuery,
  useGetLessonQuery,
  useCreateLessonMutation,
  useCreateCourseLessonMutation,
  useUpdateLessonMutation,
  useDeleteLessonMutation,
  useUploadMediaMutation
} = lessonsApiSlice
