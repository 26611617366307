import styled from 'styled-components';

export const FileWrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 6rem;
    border: 1px dashed ${({ theme }) => theme.palette.stroke};
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    padding: 1rem;
    position: relative;
`;

export const FilePreviewContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.windowBackground};
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    padding: 3px;
`;

export const FilePreview = styled.img`
    max-width: 100%;
    max-height: 300px;
    border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

export const IconsContainer = styled.div`
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    gap: 10px;
`;

export const EditIcon = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary};

    &:hover {
        color: ${({ theme }) => theme.palette.primary};
    }
`;

export const DeleteIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.statusRed};

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
  }
`;
