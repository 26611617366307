import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntlContext } from '../../locale/IntlProviderWrapper' // Adjust import based on your intl context location
import { formatMessage } from '../../locale' // Adjust import if necessary
import Paper from '../../ui-kit/Paper/Paper'
import Button from '../../ui-kit/Button/Button'
import { paths } from '../../config/paths'
import { LuPlus } from 'react-icons/lu'
import { SearchInputForm } from '../../ui-kit/Form/components/Input/SearchInput'
import { UsersList } from './components/UsersList'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export const Users: React.FC = () => {
  const navigate = useNavigate()
  const { intl } = useIntlContext()

  const { permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }


  const initialValues = {
    search: '',
  }

  const handleSubmit = async (values: any) => {
    // Handle form submission
  }

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <SearchInputForm
          name="search"
          placeholder={formatMessage({ id: 'users.searchPlaceholder', defaultMessage: 'Search' })}
          label=""
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
        <div style={{ paddingBottom: '20px' }}>
          {canDo('user: create') && (
            <Button
              appearance="primary"
              onClick={() => navigate(paths.createUser)}
            >
              <LuPlus size={20} />
              {formatMessage({ id: 'users.newUserButton', defaultMessage: 'New user' })}
            </Button>
          )}
        </div>
      </div>
      <UsersList />
    </Paper>
  )
}
