import styled from 'styled-components'

// export const Table = styled.table`
//   display: table;
//   width: 100%;
//   border-collapse: separate;
//   border-spacing: 0rem 0.5rem;
// `
//
// export const TableRow = styled.tr`
//   color: inherit;
//   display: table-row;
//   vertical-align: middle;
//   outline: 0;
//
//   & td {
//     &:first-child {
//       border-top-left-radius: 1rem;
//       border-bottom-left-radius: 1rem;
//     }
//     &:last-child {
//       border-top-right-radius: 1rem;
//       border-bottom-right-radius: 1rem;
//     }
//   }
// `
//
// export const TableHead = styled.thead`
//   display: table-header-group;
//
//   & tr td {
//     padding: 0.5rem 1rem;
//     height: auto;
//   }
// `
//
// export const TableFooter = styled.tfoot`
//   display: table-footer-group;
// `
//
// export const TableContainer = styled.div`
//   width: 100%;
//   overflow-x: auto;
// `
//
// export const TableCell = styled.td`
//   display: table-cell;
//   vertical-align: inherit;
//   text-align: left;
//   padding: 0.75rem 1rem;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   height: 3.5rem;
//   ${({ theme }) => theme.typography.mediumRegular}
// `
//
// export const TableBody = styled.tbody`
//   display: table-row-group;
//
//   & tr td {
//     background-color: ${({ theme }) => theme.palette.white};
//   }
// `

export const TableContainer = styled.div`
  overflow-x: auto;

  ${({ theme }) => `
    border: 0.063rem solid ${theme.palette.stroke};
    border-radius: ${theme.shape.borderRadius};
    
  
    @media (max-width: ${theme.breakPoints.md}) {
      border-radius: ${theme.shape.borderRadiusMedium};
    }
    
    @media (max-width: ${theme.breakPoints.sm}) {
    }
  `}
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const StyledHeader = styled.th`
  padding: 1.25rem 1.5rem;
  text-align: left;
  white-space: nowrap;

  ${({ theme }) => `
    background-color: ${theme.palette.shape};
    ${theme.typography.sizes.medium};
    ${theme.typography.weight.black};
  `}
`

export const StyledCell = styled.td`
  padding: 1rem 1.5rem;
  text-align: left;
  border-top: 0.063rem solid ${({ theme }) => theme.palette.stroke};
`

export const StyledTr = styled.tr<{ onSelect?: (id: number | string) => void }>`
  cursor: pointer;
  transition: 180ms ease;

  &:hover {
    ${({ onSelect, theme }) =>
      onSelect &&
      `
        background: ${theme.palette.shape};
      `}
  }
`

export const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.sizes.medium};
    }
  `}
`

export const MobileContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.sm}) {
      flex-direction: column;
    }
  `}
`

export const MobileTitle = styled.div`
  ${({ theme }) => `
      ${theme.typography.weight.bold};
  `}
`
