// ui.ts

import styled, { css } from 'styled-components';

const defaultCheckboxStyles = css`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: 180ms ease;

    ${({ theme }) => `
    border: 0.063rem solid ${theme.palette.stroke};
    background-color: ${theme.palette.windowBackground};
    
    @media (max-width: ${theme.breakPoints.md}) {
      width: 1rem;
      height: 1rem;
      
      & svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  `}
`;

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 0.5rem;

    & svg {
        color: ${({ theme }) => theme.palette.visualCue};
    }
`;

export const CheckboxWrapper = styled.div`
    position: relative;
`;

export const CheckboxPlaceholder = styled.input`
    ${defaultCheckboxStyles}

    margin: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
`;

export const StyledCheckbox = styled.div<{
  checked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
}>`
    ${defaultCheckboxStyles}

    display: flex;
    justify-content: center;
    align-items: center;

    ${({ hasError, theme }) =>
            hasError &&
            css`
                border-color: ${theme.palette.statusRed};
            `}
`;

export const Label = styled.label<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;

    &:hover ${StyledCheckbox} {
        ${({ theme }) => `
      border-color: 0.063rem solid ${theme.palette.visualCue};
      background-color: ${theme.palette.shape};
    `}
    }

    ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.md}) {
      ${theme.typography.mediumRegular}
    }
  `}

    ${({ disabled, theme }) =>
            disabled &&
            css`
                pointer-events: none;
                color: ${theme.palette.contrastMedium};

                & ${StyledCheckbox} {
                    pointer-events: none;

                    ${({ theme }) => `
          border-color: 0.063rem solid ${theme.palette.stroke};
          background-color: ${theme.palette.background};
          color: ${theme.palette.visualCue};
        `}
            `}
`;

export const StyledIcon = styled.div<{
  checked?: boolean;
}>`
    opacity: 0;
    visibility: hidden;
    transition: 200ms ease;

    ${({ checked }) =>
            checked &&
            css`
                opacity: 1;
                visibility: visible;
            `}
`;
