import React, { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import Toast from '../../ui-kit/Toast/Toast'
import { IntlProviderWrapper } from '../../locale/IntlProviderWrapper'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const AuthLayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`

interface AuthLayoutProps {
  children?: ReactNode
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {


  const toastMessage = useSelector((state: RootState) => state.toast.message)

  return (
    <IntlProviderWrapper>
      {toastMessage && <Toast message={toastMessage} />}
      <AuthLayoutContainer>
        {children}
        <Outlet />
      </AuthLayoutContainer>

    </IntlProviderWrapper>
  )
}

export default AuthLayout
