import styled from 'styled-components';
import { StyledPaper } from '../../../../ui-kit/Paper/Paper.styles';

export const FormPaper = styled(StyledPaper)`
    padding: 0;
    overflow: hidden;
`;

export const FormHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 5px 1px rgb(231, 229, 230, 0.5);
    border-bottom: 1px solid rgb(210 210 210 / 50%);
    padding: 0 1rem;
    height: 5.063rem;
    font-size:1.8rem;
`;

export const TabContent = styled.div`
    padding: 2rem 1rem 1rem;
`;

export const FormContent = styled.div`
    gap: 1rem;
    display: flex;
    flex-direction: column;
`;

export const Actions = styled.div`
    display: flex;
    gap: 1rem;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
`;

export const StyledSelect = styled.select`
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    appearance: none;
    background: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23999' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
    background-color: #fff;
    background-clip: padding-box;
    &::placeholder {
        color: #999;
    }
    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        outline: none;
    }
`;

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
