import React, { useState, useEffect } from 'react';

import { Overlay, Modal, CloseButton, Form, Label, Input, Button } from './ProjectGroupPopup.styles';
import { LuX } from 'react-icons/lu';
import { useIntlContext } from '../../../../../locale/IntlProviderWrapper';
import { formatMessage } from '../../../../../locale';
import { useCreateProjectGroupMutation, useUpdateProjectGroupMutation } from '../../../../../slices/projectGroupApiSlice'
import { useSelector } from 'react-redux';


interface ProjectGroupPopupProps {
  projectGroup?: { id: number; name: string };
  mode: 'create' | 'edit';
  onClose: () => void;
  onPublish: () => void;
}

export const ProjectGroupPopup: React.FC<ProjectGroupPopupProps> = ({ projectGroup, mode, onClose, onPublish }) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { intl } = useIntlContext();
  const [projectGroupName, setProjectGroupName] = useState(projectGroup ? projectGroup.name : '');

  const [createProjectGroup] = useCreateProjectGroupMutation();
  const [updateProjectGroup] = useUpdateProjectGroupMutation();

  useEffect(() => {
    if (projectGroup) {
      setProjectGroupName(projectGroup.name);
    }
  }, [projectGroup]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (mode === 'create') {
      await createProjectGroup({ data: { name: projectGroupName } }).unwrap();
    } else {
      await updateProjectGroup({ id: projectGroup!.id, data: { name: projectGroupName } }).unwrap();
    }
    onPublish();
  };
  const color = userCompany?.button_color || '#A4366D';
  return (
    <Overlay>
      <Modal>
        <CloseButton onClick={onClose}>
          <LuX size={24} />
        </CloseButton>
        <h2>{mode === 'create' ? formatMessage({ id: 'groupProject.createTitle', defaultMessage: 'Create Group Project' }) : formatMessage({ id: 'groupProject.editTitle', defaultMessage: 'Edit Group Project' })}</h2>
        <Form onSubmit={handleSubmit}>
          <Label>{formatMessage({ id: 'groupProject.nameLabel', defaultMessage: 'Group Project' })}</Label>
          <Input
            type="text"
            value={projectGroupName}
            onChange={(e) => setProjectGroupName(e.target.value)}
            required
          />
          <Button type="submit" color={color}>{mode === 'create' ? formatMessage({ id: 'create', defaultMessage: 'Create' }) : formatMessage({ id: 'save', defaultMessage: 'Save' })}</Button>
        </Form>
      </Modal>
    </Overlay>
  );
};


