import React, { useEffect, useRef, useContext, useState } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { useField } from '../hooks';
import { RedesignedPreviewFile } from './RedesignedPreviewFile';
import { addDialogClosedListener, resizeImage } from './utils';
import {
  DragAndDropArea,
  DragAndDropAreaDisclaimer,
  FileUploaderContainer,
  FilesContainer,
  Browse,
} from './RedesignedFileUpload.styles';
import FormContext from '../../FormContext';
import { Button } from '../../../';
import Message from '../ErrorMessage/Message';
import { getPlatform, Platform } from '../../../utils/platform';
import { PiUploadSimpleBold } from 'react-icons/pi';
import { formatMessage } from '../../../../locale';
import { useSelector } from 'react-redux';
const getFieldValue = (field: any): File[] =>
  (Array.isArray(field.value) ? field.value : compact([field.value])) ||
  ([] as File[]);

export type Value = File | File[] | string | string[];

export type RedesignedFileUploadProps = {
  name: string;
  value?: Value;
  noResize?: boolean;
  type?: 'photos' | 'documents';
  label?: JSX.Element | string;
  onChange?: (value?: File | null) => void;
  onClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void;
  onRemoveFile?: (file: File | string) => void;
  accept?: string;
  components?: {
    RedesignedPreviewFileContainer?: React.FC<{
      file?: File | string;
      onRemove?: (e: React.MouseEvent<HTMLImageElement>) => void;
    }>;
  };
  error?: string;
  disabled?: boolean;
  capture?: 'user' | 'environment';
  multiple?: boolean;
  onCloseDialog?: () => void;
};

export const RedesignedFileUpload = ({
                                       value: propsValue,
                                       type,
                                       onChange,
                                       onRemoveFile,
                                       components,
                                       name,
                                       error,
                                       capture,
                                       noResize = false,
                                       multiple = false,
                                       disabled = false,
                                       onCloseDialog,
                                       ...props
}: RedesignedFileUploadProps) => {
  const {userCompany} = useSelector((state: any) => state.auth);
  const form = useContext(FormContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const value =
    (Array.isArray(propsValue) ? propsValue : compact([propsValue])) ||
    ([] as File[]);
  const PreviewFile =
    components?.RedesignedPreviewFileContainer || RedesignedPreviewFile;

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = (await Promise.all(
      Array.from(event.target.files || []).map((file) => noResize?file:resizeImage(file))
    )) as File[];

    const file = files[0] || null;

    event.target.value = '';
    onChange?.(file);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (getPlatform() === Platform.DESKTOP) {
      setIsDraggingOver(true);
    }
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (getPlatform() === Platform.DESKTOP) {
      const files = (await Promise.all(
        Array.from(event.dataTransfer.files || []).map((file) =>
            noResize?file:resizeImage(file)
        )
      )) as File[];

      const file = files[0] || null;

      onChange?.(file);
    }

    setIsDraggingOver(false);
  };

  const hasValue = !!value.length;
  const showButton = multiple || !hasValue;
  const showField = !form.viewMode || hasValue;

  const allowedFileTypes =
    type === 'photos'
      ? 'image/jpg, image/jpeg, image/png, image/bmp, image/svg, image/webp'
      : 'application/pdf';

  const currentPlatform = getPlatform();

  const getButtonCaption = () => {
    return type === 'photos' ? 'Choose photo' : 'Choose document';
  };

  useEffect(
    () => addDialogClosedListener(inputRef.current, onCloseDialog),
    [onCloseDialog]
  );

  if (!showField) {
    return null;
  }

  const hasLimitError = !!(error && hasValue);
  const color = userCompany?.button_color || '#A4366D';
  return (
    <div>
      <FileUploaderContainer
        hasValue={hasValue}
        isDraggingOver={isDraggingOver}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        selectedFile={hasValue}
        error={error}
      >
        {showButton ? (
          <DragAndDropArea data-testid="drag-and-drop-area">
            <Browse
              data-testid="file-upload-button"
              onClick={() => inputRef.current?.click()}
            >
              <PiUploadSimpleBold style={{color: color}}/>
              <p style={{color: color}}>{getButtonCaption() === 'Choose photo' ? formatMessage({ id: 'imageUploader.choosePhoto', defaultMessage: 'Choose photo' })
                : formatMessage({ id: 'imageUploader.chooseFile', defaultMessage: 'Choose file' })
              }</p>
              <input
                ref={inputRef}
                name={name}
                hidden
                multiple={false}
                type="file"
                accept={allowedFileTypes}
                style={{ display: 'none' }}
                onChange={handleChange}
                capture={capture}
              />
            </Browse>
            {currentPlatform === Platform.DESKTOP && (
              <DragAndDropAreaDisclaimer>
                {formatMessage({ id: 'imageUploader.drag', defaultMessage: 'Or drag and drop ' })} {type === 'photos' ? formatMessage({ id: 'imageUploader.photo', defaultMessage: 'photo' }) : formatMessage({ id: 'imageUploader.document', defaultMessage: 'document' })} {formatMessage({ id: 'imageUploader.here', defaultMessage: 'here' })}
              </DragAndDropAreaDisclaimer>
            )}
          </DragAndDropArea>
        ) : null}

        {hasValue ? (
          <>
            <FilesContainer>
              {value.map((file, i) => {
                if (!file) {
                  return null;
                }
                return (
                  <PreviewFile
                    key={`${(file as File).name || file}-${i}`}
                    file={file}
                    name={name}
                    type="photos"
                    hasLimitError={hasLimitError}
                    onRemove={
                      disabled || !onRemoveFile
                        ? undefined
                        : (e) => {
                          e.preventDefault();
                          onRemoveFile(file);
                        }
                    }
                  />
                );
              })}
            </FilesContainer>
          </>
        ) : null}
      </FileUploaderContainer>

      {error ? <Message name={name}>{error}</Message> : null}
    </div>
  );
};

type FormikFileUploadProps = Omit<RedesignedFileUploadProps, 'onRemoveFile'> & {
  multiple?: boolean;
};

export const RedesignedFormikFileUpload = ({
                                             name,
                                             onChange,
                                             ...props
                                           }: FormikFileUploadProps) => {
  const [field, form] = useField({ name, onChange });

  const handleChange = (file?: File | null) => {
    if (file) {
      form.setFieldValue(name, file);
    } else {
      form.setFieldValue(name, null);
    }

    if (onChange) {
      onChange?.(file);
    }
  };

  const handleRemoveFile = (file: File | string) => {
    form.setFieldValue(name, null);

    if (onChange) {
      onChange?.(null);
    }
  };

  return (
    <RedesignedFileUpload
      {...props}
      {...field}
      name={name}
      onChange={handleChange}
      onRemoveFile={handleRemoveFile}
      multiple={false}
    />
  );
};

export default RedesignedFormikFileUpload;
