import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper' // Adjust import based on your intl context location
import { formatMessage } from '../../../../locale' // Adjust import if necessary
import Button from '../../../../ui-kit/Button/Button'
import { Actions, FormHeader, FormPaper, TabContent } from './CompanyForm.styles'
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper'
import { paths } from '../../../../config/paths'
import Form from '../../../../ui-kit/Form/Form'
import Row2 from '../../../../ui-kit/grid/Row2'
import { FormContent } from '../../../Courses/components/CourseForm/CourseForm.styles'
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useViewCompanyQuery,
  useGetCompaniesAsParentsQuery,
  useGetCompaniesAsParentsByIdQuery,
} from '../../../../slices/companiesApiSlice'
import { FormikInput } from '../../../../ui-kit'
import { LabelWrapper, StyledSelect } from '../../../Users/components/UsersForm/UsersForm.styles'
import { Field } from 'formik'
import { convertToFormData, handleImageData } from '../../../../slices/apiSlice'
import { Label } from '../../../../ui-kit/Form/components/Input/ui'
import { useSelector } from 'react-redux';

interface CompanyFormPageProps {
  mode: 'create' | 'edit';
}

interface Company {
  id: number;
  name: string;
  children?: Company[];
}

const flattenCompanies = (companies: Company[]): Company[] => {
  const result: Company[] = []
  const stack = [...companies]

  while (stack.length) {
    const company = stack.pop()!
    result.push(company)
    if (company.children) {
      stack.push(...company.children)
    }
  }

  return result
}

const flattenCompanies2 = (companies: Company[]): Company[] => {
  const result: Company[] = []
  const stack = [...companies]

  while (stack.length) {
    const company = stack.pop()!
    result.push(company)
    if (company.children) {
      stack.push(...company.children)
    }
  }

  return result
}

export const CompanyForm = ({ mode }: CompanyFormPageProps) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { parentId, companyId } = useParams<{ companyId: string, parentId: string }>()
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const [isClicked, setIsClicked] = useState(false)

  const handleFocus = () => setIsClicked(true)

  const getCompanyNameById = (id: number) => {
    const company = flattenedCompanies.find(company => company.id === id)
    return company ? company.name : ''
  }


  useEffect(() => {
    return () => {
      setIsClicked(false) // Reset state on unmount
    }
  }, [])

  const [companyData, setCompanyData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    organization_number: '',
    parent: {
      id: parentId || '',
    },
    main_color: userCompany.button_color,
    button_color: userCompany.button_color,
    logo: '',
  })


  const [createCompany] = useCreateCompanyMutation()
  const [updateCompany] = useUpdateCompanyMutation()

  const { data: company, error, isLoading, refetch } = useViewCompanyQuery({ id: companyId }, { skip: mode === 'create' })
  const [selectedCompanyById, setSelectedCompanyNameById] = useState(parentId || '')

  useEffect(() => {
    if (mode === 'edit' && companyId) {
      refetch();
    }
  }, [mode, companyId]);

  useEffect(() => {
    if (company) {
      setCompanyData(company.data)
    }
  }, [company])
  //for creaet compony dropdown
  const { data: companiesData } = useGetCompaniesAsParentsQuery({})
  const [flattenedCompanies, setFlattenedCompanies] = useState<Company[]>([])
  const { data: companiesDataById } = useGetCompaniesAsParentsByIdQuery({ id: companyId },{ skip: mode === 'create' });
  const [flattenCompaniesEdit, setFlattenCompaniesEdit] = useState<Company[]>([]);
  //For the parent company dropdown create and edit mode
  useEffect(() => {
    if (mode === 'create' && companiesData) {
      const companies = flattenCompanies(companiesData.data || []);
      setFlattenedCompanies(companies);
    } else if (mode === 'edit' && companyId && companiesDataById) {
      const companies = flattenCompanies2(companiesDataById.data || []);
      console.log('edit companies', companies);
      setFlattenCompaniesEdit(companies);
    }
  }, [mode, companyId, companiesData, companiesDataById]);


  useEffect(() => {
    if (company) {
      setCompanyData(company.data);
    }
  }, [company]);


  const handleSubmit = async (values: any) => {
    try {

      let data : any = {
        parent_id: selectedCompanyById !== ':parentId' ? selectedCompanyById : null,
        name: values.name,
        organization_number: values.organization_number || '',
        email: values.email,
        phone: values.phone || '',
        address: values.address,
        main_color: values.main_color || userCompany.button_color,
        button_color: values.button_color || userCompany.button_color,
        created_at: new Date().toISOString(),
      }

      data = handleImageData(values, data, 'logo');

      if (mode === 'create') {
        await createCompany({data:data}).unwrap()
        navigate(paths.company)
      } else if (mode === 'edit' && companyId) {
        await updateCompany({ id: companyId, data: data }).unwrap()
        navigate(paths.company)
      }
    } catch (error) {
      console.error('Failed to create or update company:', error)
    }
  }


  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompanyId = e.target.value
    console.log('Selected Company ID:', selectedCompanyId)
    setSelectedCompanyNameById('')
    setSelectedCompanyNameById(selectedCompanyId)
  }


  return (
    <FormPaper>
      <Form onSubmit={handleSubmit} initialValues={companyData}>
        <FormHeader>
          <div>
            <b>
              {mode === 'edit'
                ? formatMessage({ id: 'companyForm.edit', defaultMessage: 'Edit: ' }) + companyData?.name
                : formatMessage({ id: 'companyForm2create', defaultMessage: 'Create company' })}
            </b>
          </div>
          <Actions>
            <Button appearance="secondary" type="reset" onClick={() => navigate(paths.company)} style={{ borderColor: companyData?.button_color, color: companyData?.button_color }}>
              {formatMessage({ id: 'companyForm.cancel', defaultMessage: 'Cancel' })}
            </Button>
            <Button appearance="primary" type="submit" style={{ borderColor: companyData?.button_color, backgroundColor: companyData?.button_color }}>
              {mode === 'create'
                ? formatMessage({ id: 'companyForm.create', defaultMessage: 'Create' })
                : formatMessage({ id: 'companyForm.save', defaultMessage: 'Save' })}
            </Button>
          </Actions>
        </FormHeader>
        <TabContent>
          <FormContent>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.nameLabel', defaultMessage: 'COMPANY NAME' })}
                name="name"
                placeholder={formatMessage({
                  id: 'companyForm.namePlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />


              {mode === 'edit' ? (
                <LabelWrapper>
                  <Label>{formatMessage({
                    id: 'companyForm.parentCompanyLabel',
                    defaultMessage: 'PARENT COMPANY',
                  })}</Label>
                  <Field
                    as={StyledSelect}
                    name={isClicked ? 'parent_id' : 'parent.id'}
                    onChange={handleCompanyChange}
                    onFocus={handleFocus}
                  >
                    <option value="">{formatMessage({
                      id: 'companyForm.parentCompanyPlaceholder',
                      defaultMessage: 'No parent company',
                    })}</option>
                    {flattenCompaniesEdit.map((company: { id: number; name: string }) => (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    ))}
                  </Field>
                </LabelWrapper>
              ) : (
                  <LabelWrapper>
                  <Label>{formatMessage({
                    id: 'companyForm.parentCompanyLabel',
                    defaultMessage: 'PARENT COMPANY',
                  })}</Label>
                  <Field
                    as={StyledSelect}
                    name={isClicked ? 'parent_id' : 'parent.id'}
                    onChange={handleCompanyChange}
                    onFocus={handleFocus}
                    value={selectedCompanyById} // Set the value of the select field
                  >
                    <option value="">{formatMessage({
                      id: 'companyForm.parentCompanyPlaceholder',
                      defaultMessage: 'Choose here',
                    })}</option>
                    {flattenedCompanies.map((company: { id: number; name: string }) => (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    ))}
                  </Field>
                </LabelWrapper>
              )}
            </Row2>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.orgNumberLabel', defaultMessage: 'ORGANIZATIONAL NUMBER' })}
                name="organization_number"
                placeholder={formatMessage({
                  id: 'companyForm.orgNumberPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
              />

              <FormikInput
                label={formatMessage({ id: 'companyForm.emailLabel', defaultMessage: 'EMAIL ADDRESS' })}
                name="email"
                placeholder={formatMessage({
                  id: 'companyForm.emailPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
            </Row2>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.phoneLabel', defaultMessage: 'PHONE NUMBER' })}
                name="phone"
                placeholder={formatMessage({
                  id: 'companyForm.phonePlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
              />
              <FormikInput
                label={formatMessage({ id: 'companyForm.addressLabel', defaultMessage: 'ADDRESS' })}
                name="address"
                placeholder={formatMessage({
                  id: 'companyForm.addressPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
            </Row2>
          </FormContent>
          <FormContent style={{ marginBottom: 20 }}>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.cardColorLabel', defaultMessage: 'Main color' })}
                name="main_color"
                placeholder={formatMessage({ id: 'companyForm.cardColorPlaceholder', defaultMessage: 'Choose Color' })}
                autoComplete="off"
                type="color"
              />

              <FormikInput
                label={formatMessage({ id: 'companyForm.buttonColorLabel2', defaultMessage: 'Button color' })}
                name="button_color"
                placeholder={formatMessage({
                  id: 'companyForm.buttonColorPlaceholder',
                  defaultMessage: 'Choose Color',
                })}
                autoComplete="off"
                type="color"
              />
            </Row2>
            <div>
              <ImageUploadWrapper name={'logo'} type={'photos'} noResize={true} />
            </div>
          </FormContent>
        </TabContent>
      </Form>
    </FormPaper>
  )
} 

/* 
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper' // Adjust import based on your intl context location
import { formatMessage } from '../../../../locale' // Adjust import if necessary
import Button from '../../../../ui-kit/Button/Button'
import { Actions, FormHeader, FormPaper, TabContent } from './CompanyForm.styles'
import ImageUploadWrapper from '../../../../ui-kit/Form/components/FileUpload/ImageUploadWrapper'
import { paths } from '../../../../config/paths'
import Row2 from '../../../../ui-kit/grid/Row2'
import { FormContent } from '../../../Courses/components/CourseForm/CourseForm.styles'
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useViewCompanyQuery,
  useGetCompaniesAsParentsQuery,
  useGetCompaniesAsParentsByIdQuery,
} from '../../../../slices/companiesApiSlice'
import { FormikInput } from '../../../../ui-kit'
import { LabelWrapper, StyledSelect } from '../../../Users/components/UsersForm/UsersForm.styles'
import { Field } from 'formik'
import { handleImageData } from '../../../../slices/apiSlice'
import { Label } from '../../../../ui-kit/Form/components/Input/ui'
import { Formik, Form } from 'formik'
import { useSelector } from 'react-redux'
interface CompanyFormPageProps {
  mode: 'create' | 'edit';
}

interface Company {
  id: number;
  name: string;
  children?: Company[];
}

const flattenCompanies = (companies: Company[]): Company[] => {
  const result: Company[] = []
  const stack = [...companies]

  while (stack.length) {
    const company = stack.pop()!
    result.push(company)
    if (company.children) {
      stack.push(...company.children)
    }
  }

  return result
}

const flattenCompanies2 = (companies: Company[]): Company[] => {
  const result: Company[] = []
  const stack = [...companies]

  while (stack.length) {
    const company = stack.pop()!
    result.push(company)
    if (company.children) {
      stack.push(...company.children)
    }
  }

  return result
}
export const CompanyForm = ({ mode }: CompanyFormPageProps) => {
  const { userCompany } = useSelector((state: any) => state.auth);
  const { parentId, companyId } = useParams<{ companyId: string, parentId: string }>()
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const [isClicked, setIsClicked] = useState(false)

  const handleFocus = () => setIsClicked(true)

  const getCompanyNameById = (id: number) => {
    const company = flattenedCompanies.find(company => company.id === id)
    return company ? company.name : ''
  }

  const { data: company, error, isLoading, refetch } = useViewCompanyQuery({ id: companyId }, { skip: mode === 'create' })
  const [selectedCompanyById, setSelectedCompanyNameById] = useState(parentId || '')


  const [createCompany] = useCreateCompanyMutation()
  const [updateCompany] = useUpdateCompanyMutation()

  useEffect(() => {
    return () => {
      setIsClicked(false) 
    }
  }, [])

  const initialValues = {
    name: company?.data?.name || '',
    email: company?.data?.email || '',
    phone: company?.data?.phone || '',
    address: company?.data?.address || '',
    organization_number: company?.data?.organization_number || '',
    parent: {
      id: parentId || '',
    },
    main_color: company?.data?.main_color || '',
    button_color: company?.data?.button_color || userCompany.button_color,
    logo: company?.data?.logo || '',
  }


  useEffect(() => {
    if (mode === 'edit' && companyId) {
      refetch();
    }
  }, [mode, companyId]);


const { data: companiesData, refetch: comapanyDropdwonRefetch } = useGetCompaniesAsParentsQuery({})
  const [flattenedCompanies, setFlattenedCompanies] = useState<Company[]>([])
  const { data: companiesDataById } = useGetCompaniesAsParentsByIdQuery({ id: companyId },{ skip: mode === 'create' });
  const [flattenCompaniesEdit, setFlattenCompaniesEdit] = useState<Company[]>([]);
  //For the parent company dropdown create and edit mode
  useEffect(() => {
    if (mode === 'create' && companiesData) {
      const companies = flattenCompanies(companiesData.data || []);
      setFlattenedCompanies(companies);
    } else if (mode === 'edit' && companyId && companiesDataById) {
      const companies = flattenCompanies2(companiesDataById.data || []);
      console.log('edit companies', companies);
      setFlattenCompaniesEdit(companies);
    }
  }, [mode, companyId, companiesData, companiesDataById]);




  const handleSubmit = async (values: any) => {
    try {
      let data = {
        ...values,
        parent_id: selectedCompanyById !== ':parentId' ? selectedCompanyById : null,
        status: 'published',
      }

      data = handleImageData(values, data, 'logo');

      if (mode === 'create') {
        await createCompany({data: data}).unwrap()
        navigate(paths.company)
        comapanyDropdwonRefetch()
      } else if (mode === 'edit' && companyId) {
        await updateCompany({ id: companyId, data: data }).unwrap()
        navigate(paths.company)
        comapanyDropdwonRefetch()
      }
    } catch (error) {
      console.error('Failed to create or update company:', error)
    }
  }


  const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCompanyId = e.target.value
    console.log('Selected Company ID:', selectedCompanyId)
    setSelectedCompanyNameById('')
    setSelectedCompanyNameById(selectedCompanyId)
  }


  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values, setFieldValue }) => (
         <Form>
        <FormHeader>
          <div>
            <b>
              {mode === 'edit'
                ? formatMessage({ id: 'companyForm.edit', defaultMessage: 'Edit: ' }) + values?.name
                : formatMessage({ id: 'companyForm2create', defaultMessage: 'Create company' })}
            </b>
          </div>
          <Actions>
            <Button appearance="secondary" type="reset" onClick={() => navigate(paths.company)} >
              {formatMessage({ id: 'companyForm.cancel', defaultMessage: 'Cancel' })}
            </Button>
            <Button appearance="primary" type="submit" style={{ borderColor: values?.button_color, backgroundColor: values?.button_color }}>
              {mode === 'create'
                ? formatMessage({ id: 'companyForm.create', defaultMessage: 'Create' })
                : formatMessage({ id: 'companyForm.save', defaultMessage: 'Save' })}
            </Button>
          </Actions>
        </FormHeader>
        <TabContent>
          <FormContent>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.nameLabel', defaultMessage: 'COMPANY NAME' })}
                name="name"
                placeholder={formatMessage({
                  id: 'companyForm.namePlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />


{mode === 'edit' ? (
                <LabelWrapper>
                  <Label>{formatMessage({
                    id: 'companyForm.parentCompanyLabel',
                    defaultMessage: 'PARENT COMPANY',
                  })}</Label>
                  <Field
                    as={StyledSelect}
                    name={isClicked ? 'parent_id' : 'parent.id'}
                    onChange={handleCompanyChange}
                    onFocus={handleFocus}
                  >
                    <option value="">{formatMessage({
                      id: 'companyForm.parentCompanyPlaceholder',
                      defaultMessage: 'No parent company',
                    })}</option>
                    {flattenCompaniesEdit.map((company: { id: number; name: string }) => (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    ))}
                  </Field>
                </LabelWrapper>
              ) : (
                  <LabelWrapper>
                  <Label>{formatMessage({
                    id: 'companyForm.parentCompanyLabel',
                    defaultMessage: 'PARENT COMPANY',
                  })}</Label>
                  <Field
                    as={StyledSelect}
                    name={isClicked ? 'parent_id' : 'parent.id'}
                    onChange={handleCompanyChange}
                    onFocus={handleFocus}
                    value={selectedCompanyById} // Set the value of the select field
                  >
                    <option value="">{formatMessage({
                      id: 'companyForm.parentCompanyPlaceholder',
                      defaultMessage: 'Choose here',
                    })}</option>
                    {flattenedCompanies.map((company: { id: number; name: string }) => (
                      <option key={company.id} value={company.id}>{company.name}</option>
                    ))}
                  </Field>
                </LabelWrapper>
              )}
            </Row2>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.orgNumberLabel', defaultMessage: 'ORGANIZATIONAL NUMBER' })}
                name="organization_number"
                placeholder={formatMessage({
                  id: 'companyForm.orgNumberPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />

              <FormikInput
                label={formatMessage({ id: 'companyForm.emailLabel', defaultMessage: 'EMAIL ADDRESS' })}
                name="email"
                placeholder={formatMessage({
                  id: 'companyForm.emailPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
            </Row2>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.phoneLabel', defaultMessage: 'PHONE NUMBER' })}
                name="phone"
                placeholder={formatMessage({
                  id: 'companyForm.phonePlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
              <FormikInput
                label={formatMessage({ id: 'companyForm.addressLabel', defaultMessage: 'ADDRESS' })}
                name="address"
                placeholder={formatMessage({
                  id: 'companyForm.addressPlaceholder',
                  defaultMessage: 'Type here',
                })}
                autoComplete="off"
                required
              />
            </Row2>
          </FormContent>
          <FormContent style={{ marginBottom: 20 }}>
            <Row2>
              <FormikInput
                label={formatMessage({ id: 'companyForm.cardColorLabel', defaultMessage: 'Main color' })}
                name="main_color"
                placeholder={formatMessage({ id: 'companyForm.cardColorPlaceholder', defaultMessage: 'Choose Color' })}
                autoComplete="off"
                type="color"
              />

              <FormikInput
                label={formatMessage({ id: 'companyForm.buttonColorLabel2', defaultMessage: 'Button color' })}
                name="button_color"
                placeholder={formatMessage({
                  id: 'companyForm.buttonColorPlaceholder',
                  defaultMessage: 'Choose Color',
                })}
                autoComplete="off"
                type="color"
              />
            </Row2>
            <div>
              <ImageUploadWrapper name={'logo'} type={'photos'} noResize={true} />
            </div>
          </FormContent>
            </TabContent>
            </Form>
          )}
      </Formik>
    </FormPaper>
  )
}
         

 */
