import { apiSlice, convertToFormData } from './apiSlice'

export const handbooksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllHandbooks: builder.query({
      query: ({ search = '', page = 1 }) => ({
        url: `/api/v1/handbook?search=${encodeURIComponent(search)}&page=${page}`,
        method: 'GET',
      }),
    }),

    viewHandbook: builder.query({
      query: ({ id, update = false }) => ({
        url: `/api/v1/handbook/${id}` +   (update ? '?view=update' : ''),
        method: 'GET',
      }),
    }),

    createHandbook: builder.mutation({
      query: ({  data }) => ({
        url: `/api/v1/handbook`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    updateHandbook: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/handbook/${id}?_method=PATCH`,
        method: 'POST',
        body: convertToFormData(data),
      }),
    }),
    deleteHandbook: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/handbook/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetAllHandbooksQuery,
  useViewHandbookQuery,
  useCreateHandbookMutation,
  useUpdateHandbookMutation,
  useDeleteHandbookMutation,
} = handbooksApiSlice
