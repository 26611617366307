import { setToastMessage } from './toastSlice';

export const handleApiError = (dispatch: any, error: any) => {

  if(error && error.status === 401) {
    return;
  }

  let errorMessage = 'An unexpected error occurred';
  if (error?.data?.data?.message) {
    errorMessage = error.data.data.message;
  }
  if (error?.data?.message) {
    errorMessage = error.data.message;
  } else if (error?.data?.error) {
    errorMessage = error.data.error;
  } else if (error?.error) {
    errorMessage = error.error;
  }

  dispatch(setToastMessage(errorMessage));
};
