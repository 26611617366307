import React from 'react';
import { FormikInput, InputProps } from './Input';
import { FormikConfig, FormikValues } from 'formik';
import { SearchInputFormButton } from './ui';
import Form from '../../Form';
import { LuSearch } from 'react-icons/lu';

type SearchInputFormProps = FormikConfig<FormikValues> & {
  name: string;
  placeholder: string;
  required?: boolean;
  info?: string;
  infoWithIcon?: boolean;
  errorWithIcon?: boolean;
  onChange?: (value: string) => void;  // Value instead of event
  onSubmit?: (value: string) => void;  // Value instead of event
  inputProps?: InputProps;
  label: string;
};

export const SearchInputForm: React.FC<SearchInputFormProps> = ({
                                                                  name = 'search',
                                                                  onSubmit,
                                                                  onChange,
                                                                  placeholder,
                                                                  required,
                                                                  info,
                                                                  infoWithIcon,
                                                                  errorWithIcon,
                                                                  inputProps,
                                                                  label,
                                                                  ...props
                                                                }) => {
  return (
    <Form {...props} onSubmit={onSubmit}>
      <FormikInput
        label=""
        name={name}
        onChange={(event) => {
          const value = event;
          if (onChange) {
            onChange(value);  // Call custom onChange handler with the input value
          }
        }}
        placeholder={placeholder}
        endAdornment={
          <SearchInputFormButton type="submit">
            <LuSearch size={15} style={{ color: 'white !important' }} />
          </SearchInputFormButton>
        }
        endAdornmentProps={{
          style: {
            right: '2px',
          },
        }}
        info={info}
        infoWithIcon={infoWithIcon}
        errorWithIcon={errorWithIcon}
        required={required}
        {...inputProps}
      />
    </Form>
  );
};

export const SearchInput: React.FC<InputProps> = ({
                                                    name = 'search',
                                                    onChange,
                                                    ...props
                                                  }) => {
  return (
    <FormikInput
      name={name}
      onChange={(event) => {
        const value = event.target.value;
        if (onChange) {
          onChange(value);  // Call custom onChange handler with the input value
        }
      }}
      endAdornment={<LuSearch size={20} style={{ color: 'white !important' }} />}
      {...props}
    />
  );
};
