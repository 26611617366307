import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper'; // Adjust import based on your intl context location
import { formatMessage } from '../../../../locale'; // Adjust import if necessary
import  Button  from '../../../../ui-kit/Button/Button';
import { Actions, FormHeader, FormPaper, TabContent, CheckboxContainer, FormContent, StyledSelect, LabelWrapper } from './UsersForm.styles';
import { paths } from '../../../../config/paths';
import { Form, Formik, Field } from 'formik';
import Row2 from '../../../../ui-kit/grid/Row2';
import { useCreateUserMutation, useUpdateUserMutation, useViewUserQuery } from '../../../../slices/usersApiSlices'; 
import { useGetAllJobTitlesQuery } from '../../../../slices/jobTitleApiSlice'; 
import { useGetAllProjectGroupsQuery } from '../../../../slices/projectGroupApiSlice';
import { useGetAllDepartmentsQuery } from '../../../../slices/departmentsApiSlice';
import { useGetAllOfficesQuery } from '../../../../slices/officeApiSlice';
import { FormikInput } from '../../../../ui-kit'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { transformToTree, flattenOptions } from '../../../../slices/apiSlice'; 
interface UsersFormPageProps {
  mode: 'create' | 'edit';
}

export const UsersForm = ({ mode }: UsersFormPageProps) => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const { intl } = useIntlContext();
  const { permissions, isSuper  } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => { return permissions && !!permissions[permission] };


  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    locale: '',
    role_id: '',
    department_id: '',
    office_id: '',
    job_title_id: '',
    project_group_id: '',
    collar: '',
  });
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const [shouldFetch, setShouldFetch] = useState(false);
  const { data: user, error, isLoading,refetch } = useViewUserQuery({ id: userId }, { skip: mode === 'create' });
  const { data: offices } = useGetAllOfficesQuery({});
  const { data: departments } = useGetAllDepartmentsQuery({});
  const { data: jobTitles } = useGetAllJobTitlesQuery({});
  const { data: projectGroups } = useGetAllProjectGroupsQuery({});
  const [departmentTree, setDepartmentTree] = useState<any[]>([]);


  useEffect(() => {
    if (departments) {
      const tree = transformToTree(departments.data);
      const flattenedOptions = flattenOptions(tree);
      setDepartmentTree(flattenedOptions);
    }
  }, [departments]);

  useEffect(() => {
    if (mode === 'edit' && userId) {
      refetch();
    }
  }, [mode, userId]);

  useEffect(() => {
    if (user) {
      const { firstname, lastname, username, email, locale, role, department, office, collar, job_title, project_group } = user.data;
      setUserData({
        firstname,
        lastname,
        username,
        email,
        locale,
        role_id: role?.id,
        department_id: department?.id,
        office_id: office?.id,
        job_title_id: job_title?.id,
        project_group_id:  project_group?.id,
        collar,
      });
    }
  }, [user]);

  const handleSubmit = async (values: any) => {
   console.log('Form values:', values);

    try {
      if (mode === 'create') {
        await createUser({ data: values }).unwrap();
        navigate(paths.users);
      } else if (mode === 'edit' && userId) {
        await updateUser({ id: userId, data: values }).unwrap();
        navigate(paths.users);
      }
    } catch (error) {
      console.error('Failed to create/update user:', error);
    }
  };

  return (
    <FormPaper>
      <Formik initialValues={userData} onSubmit={handleSubmit} enableReinitialize>
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <FormHeader>
              <div>
                <b>
                  {mode === 'edit'
                    ? formatMessage({ id: 'users.edit', defaultMessage: 'Edit user' }) + ' ' + userData?.firstname + ' ' + userData?.lastname
                    : formatMessage({ id: 'users.create', defaultMessage: 'Create user' })}
                </b>
              </div>
              <Actions>
                <Button appearance="secondary" type="reset" onClick={() => navigate(paths.users)}>
                  {formatMessage({ id: 'users.cancel', defaultMessage: 'Cancel' })}
                </Button>
                <Button appearance="primary" type="submit">
                  {mode === 'create'
                    ? formatMessage({ id: 'users.create', defaultMessage: 'Create' })
                    : formatMessage({ id: 'users.save', defaultMessage: 'Save' })}
                </Button>
              </Actions>
            </FormHeader>
            <TabContent>
              <FormContent>
                <Row2>
                  <FormikInput
                    label={formatMessage({ id: 'users.firstname', defaultMessage: 'First Name' })}
                    name="firstname"
                    placeholder={formatMessage({ id: 'users.firstnamePlaceholder', defaultMessage: 'Type here' })}
                    autoComplete="off"
                    required
                  />
                  <FormikInput
                    label={formatMessage({ id: 'users.lastname', defaultMessage: 'Last Name' })}
                    name="lastname"
                    placeholder={formatMessage({ id: 'users.lastnamePlaceholder', defaultMessage: 'Type here' })}
                    autoComplete="off"
                    required
                  />
                </Row2>
                <Row2>
                  <FormikInput
                    label={formatMessage({ id: 'users.email', defaultMessage: 'Email Address' })}
                    name="email"
                    placeholder={formatMessage({ id: 'users.emailPlaceholder', defaultMessage: 'Type here' })}
                    autoComplete="off"
                    required
                  />

                  <FormikInput
                    label={formatMessage({ id: 'userList.password', defaultMessage: 'Password' })}
                    name="password"
                    type="password"
                    placeholder={formatMessage({ id: 'users.usernamePlaceholder', defaultMessage: 'Type here' })}
                    autoComplete="off"
                    required
                  />

                </Row2>

                <Row2>
                  <LabelWrapper>
                    <label>{formatMessage({ id: 'users.locale', defaultMessage: 'Language *' })}</label>
                    <Field as={StyledSelect} name="locale" required>
                      <option value="">{formatMessage({ id: 'users.localePlaceholder', defaultMessage: 'Choose here' })}</option>
                      <option value="en">English</option>
                      <option value="sv">Swedish</option>
                    </Field>
                  </LabelWrapper>
                  <LabelWrapper>
                    <label>{formatMessage({ id: 'users.role', defaultMessage: 'Role *' })}</label>
                    <Field as={StyledSelect} name="role_id" required>
                      <option value="">{formatMessage({ id: 'users.rolePlaceholder', defaultMessage: 'Choose here' })}</option>
                      {isSuper && (
                        <option value="1">{formatMessage({
                          id: 'users.roleDescriptions.super_user',
                          defaultMessage: 'Super User',
                        })}</option>
                      )}
                      <option value="2">{formatMessage({
                        id: 'users.roleDescriptions.power_user',
                        defaultMessage: 'Power User' })}</option>
                      <option value="3">{formatMessage({ id: 'users.roleDescriptions.admin', defaultMessage: 'Admin' })}</option>
                      <option value="4">{formatMessage({ id: 'users.roleDescriptions.user', defaultMessage: 'User' })}</option>
                      <option value="5">{formatMessage({ id: 'users.roleDescriptions.manager', defaultMessage: 'Manager' })}</option>
                      <option value="6">{formatMessage({ id: 'users.roleDescriptions.content_manager', defaultMessage: 'Content Manager' })}</option>
                      <option value="7">{formatMessage({ id: 'users.roleDescriptions.course_manager', defaultMessage: 'Course Manager' })}</option>
                      <option value="8">{formatMessage({ id: 'users.roleDescriptions.team_leader', defaultMessage: 'Team Leader' })}</option>
                    </Field>
                  </LabelWrapper>
                </Row2>
                <Row2>
                <LabelWrapper>
               <label>{formatMessage({ id: 'department.departmentLabel', defaultMessage: 'Department / Business Unit / Team' })}</label>
               <Field as={StyledSelect} name="department_id">
                 <option value="">{formatMessage({ id: 'users.departmentPlaceholder', defaultMessage: 'Choose here' })}</option>
                 {departmentTree.map((department: { value: string | number; label: string }) => (
                   <option key={department.value} value={department.value}>{department.label}</option>
                 ))}
               </Field>
              </LabelWrapper>

                  <LabelWrapper>
                    <label>{formatMessage({ id: 'users.office', defaultMessage: 'Office *' })}</label>
                    <Field as={StyledSelect} name="office_id" >
                      <option value="">{formatMessage({ id: 'users.officePlaceholder', defaultMessage: 'Choose here' })}</option>
                      {offices?.data.map((office: any) => (
                        <option key={office.id} value={office.id}>{office.name}</option>
                      ))}
                    </Field>
                  </LabelWrapper>
                 </Row2>
                <Row2>

                  <LabelWrapper>
                    <label>{formatMessage({ id: 'users.jobTitle', defaultMessage: 'Job Title' })}</label>   
                    <Field as={StyledSelect} name="job_title_id" >
                      <option value="">{formatMessage({ id: 'users.jobTitlePlaceholder', defaultMessage: 'Choose here' })}</option>
                      {jobTitles?.data.map((jobTitle: any) => (
                        <option key={jobTitle.id} value={jobTitle.id}>{jobTitle.name}</option>
                      ))}
                    </Field>
                  </LabelWrapper>

                  <LabelWrapper>
                    <label>{formatMessage({id: 'users.groupProject', defaultMessage: 'Group Project'})}</label>
                    <Field as={StyledSelect} name="project_group_id" >
                      <option value="">{formatMessage({ id: 'users.groupProjectPlaceholder', defaultMessage: 'Choose here' })}</option>
                      {projectGroups?.data.map((projectGroup: any) => (
                        <option key={projectGroup.id} value={projectGroup.id}>{projectGroup.name}</option>
                      ))}
                    </Field>
                  </LabelWrapper>
                </Row2>


                <CheckboxContainer>
                  <label>
                    <Field type="radio" name="collar" value="blue" /> {formatMessage({ id: 'users.collar.blue', defaultMessage: 'Blue collar' })}
                  </label>
                  <label>
                    <Field type="radio" name="collar" value="white" /> {formatMessage({ id: 'users.collar.white', defaultMessage: 'White collar' })}
                  </label>
                </CheckboxContainer>
              </FormContent>
            </TabContent>
          </Form>
        )}
      </Formik>
    </FormPaper>
  );
};
