import { apiSlice, convertToFormData } from './apiSlice'; 


export const supportApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllSupport: builder.query({
            query: ({ search = '', page = 1 }) => ({
                url: `/api/v1/supports?search=${encodeURIComponent(search)}&page=${page}`,
                method: 'GET',
            }),
        }),

        viewSupport: builder.query({
            query: ({ id, update = false }) => ({
                url: `/api/v1/supports/${id}` +  (update ? '?view=update' : ''),
                method: 'GET',
            }),
        }),
        createSupport: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/supports/`,
                method: 'POST',
                body: convertToFormData(data),
            }),
        }),
        updateSupport: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api/v1/supports/${id}?_method=PATCH`,
                method: 'POST',
                body: convertToFormData(data),
            }),
        }),
        deleteSupport: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/supports/${id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useGetAllSupportQuery,
    useViewSupportQuery,
    useCreateSupportMutation,
    useUpdateSupportMutation,
    useDeleteSupportMutation,
} = supportApiSlice;