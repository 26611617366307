import { useEffect, useRef } from 'react'

type EventCallback = (event: Event) => void

const useEventListener = (
  eventType: keyof WindowEventMap,
  callback: EventCallback,
  element: EventTarget | null = window
) => {
  const callbackRef = useRef<EventCallback>(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (element == null) {
      return
    }

    const handler: EventListener = (e) => callbackRef?.current(e)

    element?.addEventListener(eventType, handler)

    return () => element?.removeEventListener(eventType, handler)
  }, [eventType, element])
}

export default useEventListener
