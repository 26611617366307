import React, { useCallback } from 'react'
import styled from 'styled-components'
import mapValues from 'lodash/mapValues'
import isEqual from 'lodash/isEqual'
import {
  Form as FormikForm,
  FormikFormProps,
  Formik,
  FormikProps,
  FormikValues,
  FormikConfig,
  FormikHelpers,
  yupToFormErrors,
  setNestedObjectValues,
} from 'formik'
import { Schema } from 'yup'
import Fieldset from './Fieldset'
import FormContext from './FormContext'

function validateSchema<T>(
  schema: Schema<T>,
  { transformValues = (v: T) => v }: any = {}
) {
  return async (values: T) => {
    const transformedValues = transformValues(values)

    if (!schema || !isEqual(transformedValues, values)) {
      return null
    }

    let errors = null
    await schema.validate(values, { abortEarly: false }).catch((e) => {
      errors = yupToFormErrors(e)
    })
    return errors
  }
}

const StyledForm = styled(FormikForm)<{ $isSubmitting: boolean }>`
  ${({ $isSubmitting }) => ($isSubmitting ? 'filter: blur(1px);' : '')}
`

type TransformValuesOptions = {
  [name: string]: {
    trim?: boolean
  }
}

function transformSubmitValues<T extends FormikValues>(
  values: T,
  options?: TransformValuesOptions
) {
  const trimmedValues = mapValues(values, (value: any, name: string) => {
    if (value && (!options || !options[name] || options[name].trim !== false)) {
      if (typeof value === 'string') {
        return value?.trim() ?? value
      }
    }

    return value
  })

  return trimmedValues
}

type FormProps<T extends FormikValues> = Omit<
  FormikConfig<T>,
  'initialValues'
> & {
  initialValues?: T
  onSubmit?: (values: T, helpers: FormikHelpers<T>) => void | Promise<any>
  onError?: (formikProps: FormikProps<any>) => void | Promise<any>
  formElementProps?: FormikFormProps
  transformSubmitValuesOptions?: TransformValuesOptions
  className?: string
  disabled?: boolean
  viewMode?: boolean
  transformValues?: (values: T) => T | Promise<T>
}

function Form<T extends FormikValues>({
  enableReinitialize = true,
  validateOnChange = true,
  validateOnBlur = false,
  initialStatus = {},
  initialValues,
  transformValues,
  transformSubmitValuesOptions,
  children,
  validationSchema,
  onError,
  onSubmit,
  formElementProps,
  className,
  disabled,
  viewMode,
  ...props
}: FormProps<T>) {
  const handleSubmit = useCallback(
    async (values: T, helpers: FormikHelpers<T>) => {
      onSubmit &&
        (await onSubmit(
          transformSubmitValues(values, transformSubmitValuesOptions),
          helpers
        ))
      helpers.setSubmitting(false)
    },
    [onSubmit, transformSubmitValuesOptions]
  )

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      initialStatus={initialStatus}
      initialValues={(initialValues || {}) as T}
      validate={validateSchema<T>(validationSchema, transformValues)}
      {...props}
      onSubmit={handleSubmit}
    >
      {(formikProps: FormikProps<T>) => {
        const formContextValue = {
          disabled: formikProps.isSubmitting || disabled,
          viewMode,
        }

        const handleSubmit = async (e: React.FormEvent) => {
          e.preventDefault()

          if (formikProps.isSubmitting) {
            return
          }

          const errors = await formikProps.validateForm()
          const isValid = !Object.keys(errors).length

          if (!isValid) {
            formikProps.setTouched(setNestedObjectValues(errors, true))

            if (onError) {
              return onError({ ...formikProps, isValid, errors } as FormikProps<
                T | FormikValues
              >)
            }

            return
          }

          return formikProps.submitForm()
        }

        return (
          <StyledForm
            $isSubmitting={!!formikProps.isSubmitting}
            onSubmit={handleSubmit}
            {...formElementProps}
          >
            <Fieldset className={className} {...formContextValue}>
              <FormContext.Provider value={formContextValue}>
                {typeof children === 'function'
                  ? children(formikProps)
                  : children}
              </FormContext.Provider>
            </Fieldset>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

export type AdditionalFieldProps = {
  error?: string
}

export default Form
