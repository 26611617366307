import React, { useState, useEffect } from 'react';
import { LuX } from 'react-icons/lu';
import {
  Overlay,
  Modal,
  CloseButton,
  Form,
  Label,
  Select,
  Button,
} from './LessonAddPopup.styles';
import { formatMessage } from '../../../../locale';
import { useGetAllLessonsQuery } from '../../../../slices/lessonsApiSlice';
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';

interface LessonAddPopupProps {
  onClose: () => void;
  onAdd: (lessonId: string) => void;
}

const LessonAddPopup: React.FC<LessonAddPopupProps> = ({ onClose, onAdd }) => {
  const { intl } = useIntlContext();
  const [selectedLesson, setSelectedLesson] = useState('');

  const { data: lessons, error, isLoading } = useGetAllLessonsQuery({});

  useEffect(() => {
    if (lessons?.data && lessons.data.length > 0) {
      setSelectedLesson(lessons.data[0].id.toString());
    }
  }, [lessons]);

  const handleAdd = () => {
    onAdd(selectedLesson);
  };

  return (
    <Overlay>
      <Modal>
        <CloseButton onClick={onClose}>
          <LuX size={24} />
        </CloseButton>
        <h2>{formatMessage({ id: 'lessons.attachLesson', defaultMessage: 'Attach a new lesson' })}</h2>
        <Form>
          <Label>{formatMessage({ id: 'lessons.chooseLabel', defaultMessage: 'Choose a Lesson' })}</Label>
          {isLoading ? (
            <div>{formatMessage({ id: 'loading', defaultMessage: 'Loading...' })}</div>
          ) : error ? (
            <div>{formatMessage({ id: 'lesson.errorLoadingLessons', defaultMessage: 'Error loading lessons' })}</div>
          ) : (
            <Select
              value={selectedLesson}
              onChange={(e) => setSelectedLesson(e.target.value)}
              required
            >
              <option value="">{formatMessage({ id: 'lessons.chooseHere', defaultMessage: 'Choose here' })}</option>
              {lessons?.data.map((lesson: any) => (
                <option key={lesson.id} value={lesson.id}>
                  {lesson.title}
                </option>
              ))}
            </Select>
          )}
          <Button type="button" onClick={handleAdd}>{formatMessage({ id: 'lessons.add', defaultMessage: 'Add' })}</Button>
        </Form>
      </Modal>
    </Overlay>
  );
};

export default LessonAddPopup;
