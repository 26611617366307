import React, { useState, FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useResetPasswordMutation } from '../../../slices/usersApiSlices';
import { FormikInput, Button } from '../../../ui-kit/';
import { LoginContainer, FormContainer, Logo, Title, Message } from './Login.styles';
import { paths } from '../../../config/paths'

const ResetPassword: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const urlParams = new URLSearchParams(location.search);
  const token = urlParams.get('token');

  const email = urlParams.get('email');

  // Form validation schema
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[@$!%*?&#]/, 'Password must contain at least one special character')
      .required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Password confirmation is required'),
  });

  const handleSubmit = async (values: { password: string, passwordConfirmation: string }) => {
    try {
      await resetPassword({ token, email, password: values.password, password_confirmation: values.passwordConfirmation }).unwrap();
      setSuccessMessage('Password has been reset successfully.');
      setErrorMessage('');
      setTimeout(() => {
        navigate(paths.root); // Redirect to the login page
      }, 3000);
    } catch (err: any) {
      setSuccessMessage('');
      setErrorMessage(err?.data?.message || 'An error occurred while resetting your password.');
    }
  };

  if (!token || !email) {
    return <div>Invalid password reset link.</div>;
  }

  return (
    <LoginContainer>
      <FormContainer>
        <Logo>
          <img src="/assets/logo.svg" alt="Logo" />
        </Logo>
        <Title>Reset Password</Title>
        <Formik
          initialValues={{ password: '', passwordConfirmation: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormikInput
                label="New Password"
                name="password"
                type="password"
                placeholder="Enter new password"
                autoComplete="off"
                required
              />
              <div style={{ marginTop: '20px' }}></div>
              <FormikInput
                label="Confirm Password"
                name="passwordConfirmation"
                type="password"
                placeholder="Confirm new password"
                autoComplete="off"
                required
              />
              <div style={{ marginTop: '20px' }}></div>
              {errorMessage && <Message error>{errorMessage}</Message>}
              {successMessage && <Message success>{successMessage}</Message>}
              <div style={{ marginTop: '20px' }}></div>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? 'Resetting...' : 'Reset Password'}
              </Button>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </LoginContainer>
  );
};

export default ResetPassword;
