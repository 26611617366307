import styled, { css } from 'styled-components'

export interface LabelProps {
  multiline?: boolean | undefined
  disabled: boolean | undefined
  error: string | boolean | undefined
  border?: boolean | undefined
  value?: string | boolean | Date
  hasPlaceholder?: boolean
}

export const FieldWrapper = styled.div<LabelProps>`
  position: relative;
  display: block;
  flex: 1;
  height: 3rem;
  transition: 180ms ease;

  ${({ theme }) => ` 
    border-radius: ${theme.shape.borderRadiusMedium};
    border: 0.063rem solid ${theme.palette.stroke};
    background-color: ${theme.palette.windowBackground};
    
    @media (max-width: ${theme.breakPoints.md}) {
      border-radius: ${theme.shape.borderRadiusSmall};
    }

    @media (max-width: ${theme.breakPoints.sm}) {
      height: 2.25rem;
    }
  `}

  ${({ border }) =>
    border === false &&
    css`
      border: none;
    `}
  
  & svg {
    color: ${({ theme }) => theme.palette.visualCue};
  }

  &:hover,
  &:focus,
  &:focus-within {
    outline: inherit;
    border-color: ${({ theme }) => theme.palette.primary};

    & svg {
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.palette.shape};
      border-color: ${theme.palette.shape};

      & svg {
        color: ${theme.palette.stroke};
      }

      &:hover,
      &:focus,
      &:focus-within {
        border-color: ${theme.palette.shape};

        & svg {
          color: ${theme.palette.stroke};
        }
      }
    `}

  ${({ multiline }) =>
    multiline &&
    css`
      align-items: baseline;
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.palette.statusRed} !important;
    `}
`

export default FieldWrapper
