import { ButtonBaseProps } from './base/ButtonBase'
import ButtonPrimary from './components/ButtonPrimary'
import ButtonSecondary from './components/ButtonSecondary'
import ButtonTransparent from './components/ButtonTransparent'
import ButtonIconPrimary from './components/ButtonIconPrimary'
import ButtonIconSecondary from './components/ButtonIconSecondary'
import ButtonIconTransparent from './components/ButtonIconTransparent'
import ButtonDanger from './components/ButtonDanger'

export const componentsMap = {
  primary: ButtonPrimary,
  danger: ButtonDanger,
  secondary: ButtonSecondary,
  iconPrimary: ButtonIconPrimary,
  iconSecondary: ButtonIconSecondary,
  transparent: ButtonTransparent,
  iconTransparent: ButtonIconTransparent,
}

export type GetComponentProps = {
  appearance?: keyof typeof componentsMap
}

export const getComponent = ({ appearance }: GetComponentProps) =>
  (appearance && componentsMap[appearance]) || componentsMap.primary

export type ComponentProps = ButtonBaseProps & GetComponentProps
