import { object, string } from 'yup'
import { formatErrorMessage } from '../../../locale'

export const loginSchema = object().shape({
  email: string()
    .required(formatErrorMessage('required', { fieldName: 'Email' }))
    .matches(
      /^[^\s@]+@[a-zA-Z]+\.[a-zA-Z]{2,}$/,
      'Please provide a valid email address'
    ),

  password: string().required(
    formatErrorMessage('required', { fieldName: 'Password' })
  ),
})
