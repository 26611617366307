import { Link } from 'react-router-dom';
import React from 'react';
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { formatMessage } from '../../locale';
import { Aside, Title } from './Sidebar.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ASIDE_NAV } from '../../constants/asideNav';
import { paths } from '../../config/paths';
import { Logo } from '../../ui-kit/Header/Header.styles';

// Define the types for ASIDE_NAV
interface NavItem {
  name: string;
  path: string;
  icon: JSX.Element;
  admin?: boolean;
}

interface AsideNav {
  [key: string]: NavItem[];
}

export const Sidebar: React.FC = () => {
  const { intl } = useIntlContext();
  const { userInfo,permissions, userCompany  } = useSelector((state: RootState) => state.auth);
  const canDo = (permission: string) => { return permissions && !!permissions[permission] };
  const userRole = userInfo?.data?.role?.name;

  // Filter the ASIDE_NAV based on the user's role
  const filteredNav = Object.entries(ASIDE_NAV).reduce((acc, [key, value]) => {
    acc[key] = value.filter((item:any) => canDo(item.permission));
    return acc;
  }, {} as AsideNav);
  const color = userCompany?.main_color || '#A4366D';
  return (
    <Aside color={color}>
      <div style={{ marginTop: '80px' }}></div>
      {Object.entries(filteredNav).map(([key, value]) => (
        value.length > 0 && ( // Check if value has items before rendering
          <div key={key}>
            <Title>
              {formatMessage({ id: 'side_menu.' + key, defaultMessage: 'Activities' })}
            </Title>
            <ul>
              {value.map((item, idx) => (
                <li key={idx}>
                  <Link to={item.path}>
                    <>
                      {item.icon}
                      {formatMessage({ id: 'side_menu.' + item.name, defaultMessage: item.name })}
                    </>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )
      ))}
    </Aside>
  );

};

export default Sidebar;
