import styled from 'styled-components';
import { Button } from '../../ui-kit'

export const FormPaper = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const SubPageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

export const Title = styled.h1`
    font-size: 24px;
    font-weight: bold;
    color: #333;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
`;

export const Content = styled.div`
    margin-top: 20px;
`;

export const QuestionContainer = styled.div`
    margin-bottom: 20px;
`;

export const QuestionTitle = styled.h2`
    font-size: 20px;
    font-weight: bold;
    color: #333;
    text-align: center;
`;

export const ChoicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const ChoiceItem = styled.label`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const NextButton = styled(Button)`
    margin-top: 20px;
`;

export const PreviousButton = styled(Button)`
    margin-top: 20px;
`;

export const SubmitButton = styled(Button)`
    margin-top: 20px;
`;

export const ResultMessage = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #4CAF50;
    text-align: center;
    margin-top: 20px;
`;

export const SubTitle = styled.p`
    font-size: 14px;
    color: #777;
    text-align: center;
`;

export const QuestionNumber = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
`;

export const Divider = styled.hr`
    margin: 20px 0;
`;

export const PassingScoreText = styled.p`
    font-size: 14px;
    color: #777;
    text-align: center;
    margin-bottom: 10px;
`;

export const ResultContainer = styled.div<{ isPassed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: ${({ isPassed }) => (isPassed ? '#e0f7e0' : '#f7e0e0')};
  border: 1px solid ${({ isPassed }) => (isPassed ? '#4CAF50' : '#F44336')};
  border-radius: 10px;
`;

export const ResultText = styled.p<{ isPassed?: boolean }>`
  font-size: 18px;
  color: ${({ isPassed }) => (isPassed ? '#4CAF50' : '#F44336')};
  font-weight: bold;
  text-align: center;
  margin: 5px 0;
`;
