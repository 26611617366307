import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntlContext } from '../../locale/IntlProviderWrapper'
import { formatMessage } from '../../locale'
import { paths } from '../../config/paths'
import { LuPlus } from 'react-icons/lu'
import ActioPlanList from './components/ActionsPlanList'
import { Button, Paper } from '../../ui-kit'
import { SearchInputForm } from '../../ui-kit/Form/components/Input/SearchInput'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { HideOnMobile } from '../../components/BaseLayout/BaseLayout.styles'
import Hero from '../../ui-kit/Hero/Hero'

export const ActionsPlan: React.FC = () => {
  const navigate = useNavigate()
  const { intl } = useIntlContext()
  const { permissions } = useSelector((state: RootState) => state.auth)
  const canDo = (permission: string) => {
    return permissions && !!permissions[permission]
  }


  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div></div>
        <div style={{ paddingBottom: '20px' }}>
          {canDo('action plan: create') && (
            <Button
              appearance="primary"
              onClick={() => navigate(paths.createActionsPlan)}
            >
              <LuPlus size={20} />
              <HideOnMobile>
                {formatMessage({ id: 'investigation.newInvestigationButton', defaultMessage: 'New page' })}
              </HideOnMobile>
            </Button>
          )}
        </div>
      </div>
      <Hero
        description={formatMessage({
          id: 'hero.description',
          defaultMessage: 'Work environment management creates conditions for well-being, commitment and performance. A systematic work environment management involves a structured approach to managing and improving the work environment. At its core it includes continuous investigation, risk assessments, implementing preventive measures as well as identifying positive aspects and strengthening that. In short, a systematic work environment is about creating a sustainable, dynamic workplace where both the organization and its employees can thrive.',
        })}
        imagePath={'assets/hero2.jpg'}
        header={formatMessage({ id: 'hero.header', defaultMessage: 'Welcome to the ' })}
        subHeader={formatMessage({
          id: 'hero.subHeaderActionsPlan',
          defaultMessage: 'Here you will find Actions plan.',
        })}
      ></Hero>
      <ActioPlanList/>
    </Paper>
  )
}
