import { Errors } from './Errors'

export const sv = {
  Error: {
    ...Errors,
  },
  ...{
    menu: {
      company: 'Företag',
      handbook: 'Handbok',
      courses: 'Kurser',
      library: 'Bibliotek',
      logout: 'Logga ut',
    },

    side_menu: {
      Activities: 'Aktiviteter',
      Organization: 'Organisation',
      virtualAssistant: 'AI assistant',
      instructions: 'Instruktioner för plattformen',
      Support: 'Support',
      Archive: 'Arkiv',
      actionsPlan: 'Handlingsplan',
      claims: 'Rapportering',
      investigations: 'Risk bedömning',
      dashboard: 'Dashboard',
      annualPlan: 'Årsplan',
      profile: 'Profil',
      users: 'Användare',
      lessons: 'Lektioner',
    },

    dashboard: {
      myCourses: 'Mina kurser',
      courseStatistics: 'Kursstatistik',
      performanceStatistics: 'Kursresultat',
      completed: 'Avslutad',
      inProgress: 'Pågående',
      toBegin: 'Att börja',
      completedSuccessfully: 'Klarade Quiz',
      completedUnsuccessfully: 'Ej klarade Quiz',
    },

    courseCard: {
      continue: 'FORTSÄTT',
      start: 'STARTA',
      review: 'GRANSKA',
      startDate: 'Startdatum',
      endDate: 'Slutdatum',
      lessons: 'Lektioner',
    },

    filters: {
      withExpiry: 'Kurser med slutdatum',
      withoutExpiry: 'Kurser utan slutdatum',
      quickFilter: 'Snabbfilter',
    },

    handbook: {
      searchPlaceholder: 'Sök',
      newHandbookButton: 'Ny sida',
      goBackHandbook: 'TILLBAKA TILL HANDBOKEN',
    },

    handbookForm: {
      edit: 'Redigera: ',
      create: 'Skapa sida',
      cancel: 'Avbryt',
      publish: 'Publicera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange rubrik',
      descriptionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Ange ett kort beskrivning',
      contentLabel: 'Innehåll',
      contentPlaceholder: 'Ange innehåll',
      cardColorLabel: 'Kortfärg',
      cardColorPlaceholder: 'Ange en kortfärg',
      departmentLabel: 'Avdelning/Enhet/Team',
      officeLabel: 'Kontor',
      jobTitleLabel: 'Jobbtitel',
      groupProjectLabel: 'Projektgrupp',
      userLabel: 'Användare',
      checkBox: 'Alla användare',
      placeholderMultiSelect: 'Välj...',
    },

    profileCircle: {
      profile: 'Profil',
      logout: 'Logga ut',
    },

    department: {
      noDepartment: 'Ingen överordnad avdelning',
      createTitle: 'Skapa avdelning',
      editTitle: 'Redigera avdelning',
      nameLabel: 'Avdelningsnamn *',
      parentLabel: 'Överordnad avdelning',
      companyLabel: 'Företag',
      headLabel: 'Chef för Affärsenhet / Team',
      errorLoadingUsers: 'Fel vid laddning av användare',
      errorLoadingDepartments: 'Fel vid laddning av avdelningar',
      departmentLabel: 'Avdelning/Enhet/Team',
    },

    company2: {
      companiesTab: 'Företag',
      structureTab: 'Företagsstruktur',
      officesTab: 'Kontor',
      jobTitlesTab: 'Jobbtitel',
      projectGroupsTab: 'Projektgrupp',
      newCompanyButton: 'Nytt företag',
    },
    companyForm: {
      edit: 'Redigera: ',
      create: 'Skapa företag',
      cancel: 'Avbryt',
      save: 'Spara',
      nameLabel: 'Företagsnamn',
      namePlaceholder: 'Ange företagsnamn',
      parentCompanyLabel: 'Moderföretag',
      parentCompanyPlaceholder: 'Inget moderföretag',
      orgNumberLabel: 'Organisationsnummer',
      orgNumberPlaceholder: 'Ange organisationsnummer',
      emailLabel: 'E-mail',
      emailPlaceholder: 'Ange e-postadress',
      phoneLabel: 'Telefon',
      phonePlaceholder: 'Ange telefonnummer',
      addressLabel: 'Adress',
      addressPlaceholder: 'Ange adress',
      cardColorLabel: 'Huvudfärg',
      buttonColorLabel2: 'Knappfärg',
    },

    office: {
      createTitle: 'Skapa kontor',
      editTitle: 'Redigera kontor',
      nameLabel: 'Kontorsnamn',
      newOfficeButton: 'Nytt kontor',
      errorLoadingOffices: 'Fel vid laddning av kontor',
    },

    jobTitle: {
      createTitle: 'Skapa jobbtitel',
      editTitle: 'Redigera jobbtitel',
      nameLabel: 'Jobbtitel',
      newJobTitleButton: 'Ny jobbtitel',
      errorLoadingJobTitles: 'Fel vid laddning av jobbtitlar',
    },

    groupProject: {
      createTitle: 'Skapa projektgrupp ',
      editTitle: 'Redigera projektgrupp',
      nameLabel: 'Projektgrupp Namn',
      newGroupButton: 'Ny projektgrupp',
      errorLoadingGroups: 'Fel vid laddning av projektgrupper',
    },

    loading: 'Laddar...',
    chooseHere: 'Välj här',
    create: 'Skapa',
    save: 'Spara',
    company: 'Företag',
    userRole: {
      super_admin: 'Super Admin',
      admin: 'Administratör',
      power_user: 'Power User',
      course_manager: 'Kursansvarig',
      manager: 'Chef',
      content_manager: 'Innehållsansvarig',
      team_leader: 'Teamledare',
      user: 'Användare',
    },

    userStatus: {
      active: 'Aktiv',
      inactive: 'Inaktiv',
    },

    userList: {
      name: 'Namn',
      email: 'E-post',
      role: 'Roll',
      status: 'Status',
      action: 'Åtgärder',
    },

    imageUploader: {
      chooseFile: 'Välj fil',
      choosePhoto: 'Välj foto',
      drag: 'Eller dra och släpp ',
      here: 'här',
      photo: 'foto',
      document: 'dokument',
    },

    profile: {
      buttonProfile: 'Profil',
      buttonCertificates: 'Certifikat',
      buttonDocuments: 'Dokument',
    },

    courses: {
      title: 'Kurser',
      manageCourses: 'Hantera kurser',
      newCourseButton: 'Ny kurs',
      titleLabel: 'Titel',
      statusLabel: 'Status',
      startedLabel: 'Startad',
      expiredLabel: 'Stängd',
      actionsLabel: 'Åtgärder',
      returnCourse: 'Gå tillbaka till kurs',
      completeLesson: 'Avsluta lektion',
      copyToCompany: 'Kopiera kurs till annat företag',
      chooseLabel: 'Välj företag',
      multiSelectLabel: 'Välj företag',
      copy: 'Kopiera',
    },

    createEditCourse: {
      title: 'Skapa kurs',
      titleLabel: 'Titel',
      decsLabel: 'Beskrivning',
      subComponyLabel: 'Dotterbolag',
      publish: 'Publicera',
      cancel: 'Avbryt',
      save: 'Spara',
      create: 'Skapa',
      courseDetails: 'Kurs detaljer',
      lessons: 'Lektioner',
      quiz: 'Quiz',
      participants: 'Deltagare',
      schedule: 'Schema',
      courseAlert: 'Fyll i alla fält för att publicera',
    },

    lessons: {
      pageTitle: 'Lektioner',
      title: 'Titel',
      actions: 'Åtgärder',
      titlePlaceholder: 'Ange lektionstitel',
      addLesson: 'Lägg till lektion',
      newLesson: 'Ny lektion',
      emptyLesson: 'Inga skapade lektioner. Skapa ny lektion för att försätta',
      cancel: 'Avbryt',
      back: 'Tillbaka',
      edit: 'Redigera',
      view: 'Visa',
      save: 'Spara',
      editLesson: 'Redigera lektion',
      createLesson: 'Skapa ny lektion',
      viewOnlyCourse: 'Välj lektion till vänster för förhandsvisning',
      attachLesson: 'Bifoga lektion',
      chooseLabel: 'Välj en lektion',
      add: 'Lägg till',
      chooseHere: 'Välj här',
    },

    quiz: {
      title: 'Quiz detaljer',
      noQustioons: 'Inga skapade frågor. Skapa nya frågor för att fortsätta',
      newQuestion: 'Ny fråga',
      addQuestion: 'Lägg till fråga',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange en quiztitel',
      passLabel: 'Passeringsprocent',
      passPlaceholder: 'Ange passeringsprocent',
      attemptLabel: 'Försök',
      attemptPlaceholder: 'Ange antal försök',
      create: 'Skapa',
      save: 'Spara',
      editQestion: 'Redigera fråga',
      createQestion: 'Skapa fråga',
      qestionLabel: 'Fråga',
      qestionPlaceholder: 'Skriv fråga här',
      correct: 'Korrekt',
      choice: 'Val',
      actions: 'Åtgärder',
      questions: 'Val',
      newChoice: 'Nytt val',
      returnCourse: 'Gå tillbaka till kurs',
      yourScore: 'Dina poäng',
      isPassed: 'Grattis! Du har klarat quizet',
      failed: 'Tyvärr, du har misslyckats med quizet',
      question: 'Fråga',
      needScore: 'Du behöver',
      toPass: 'för att klara kursen!',
      selectAnswears: 'Vänligen välj rätt svar:',
      prvsQuestion: 'Föregående fråga',
      nextQuestion: 'Nästa fråga',
      sendQuiz: 'Skicka quiz',
      choicePlaceholder: 'Skriv in val här',
    },

    participants: {
      title: 'Redigera deltagare',
      department: 'Avdelning/Affärsenhet/Team',
      office: 'Kontor',
      jobTitle: 'Jobbtitel',
      groupProject: 'Projektgrupp',
      user: 'Användare',
      isMandatory: 'Obligatorisk',
      save: 'Spara ändringar',
      users: 'Alla användare',
    },

    schedule: {
      title: 'Redigera schema',
      startedAt: 'Start datum *',
      expiredAt: 'Slut datum',
      saveChanges: 'Spara ändringar',
      noEndDate: 'Ingen slut datim',
    },

    library: {
      searchPlaceholder: 'Sök',
      newLibraryButton: 'Ny bibliotek',
      title: 'Skapa bibliotek',
      edit: 'Redigera: ',
      cancel: 'Avbryt',
      publish: 'Publisera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Skriv in bibliotek titel',
      describtionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Skriv in beskrivning till bibliotek',
      cardColorLabel: 'Kort färg',
      cardColorPlaceholder: 'Välj färg till kort för bibliotek',
      company: 'Företag',
      releaseDate: 'Releasedatum',
      userRoll: 'Användarroller',
      goBackLibrary: 'TILLBAKA TILL BIBLIOTEK LISTA',
    },

    certificates: {
      title: 'Dina uppladdade certifikater',
      fileName: 'Fill namn',
      uploadDate: 'Uppladdningsdatum',
      actions: 'Åtgärder',
      delete: 'Radera',
      noCertificates: 'Inga certifikater uppladdade än',
      upload: 'Ladda up ny certifikat ',
      uploading: 'Laddar upp...',
      uploadd: 'Ladda upp fil',
    },

    documents: {
      title: 'Dina uppladdade dokument',
      noDocuments: 'Inga dokument uppladdade än',
      upload: 'Ladda upp nytt dokument',
    },

    investigation: {
      searchPlaceholder: 'Sök',
      newInvestigationButton: 'Ny sida',
      goBackToInvestigation: 'TILLBAKA TILL RISKBEDÖMNING LISTA',
    },

    investigationForm: {
      edit: 'Redigera: ',
      create: 'Skapa sida',
      cancel: 'Avbryt',
      publish: 'Publicera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange en handbokstitel',
      descriptionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Ange en handbokbeskrivning',
      contentLabel: 'Innehåll',
      contentPlaceholder: 'Ange ett handboksinnehåll',
      cardColorLabel: 'Kortfärg',
      cardColorPlaceholder: 'Ange en kortfärg',
      departmentLabel: 'Avdelning/Affärsenhet/Team',
      officeLabel: 'Kontor',
      jobTitleLabel: 'Jobbtitel',
      groupProjectLabel: 'Grupp',
      userLabel: 'Användare',
      checkBox: 'Alla användare',
      placeholderMultiSelect: 'Välj...',
    },

    claim: {
      searchPlaceholder: 'Sök',
      newClaimButton: 'Ny sida',
      goBackToClaim: 'TILLBAKA TILL RAPPORTERING LISTA',
    },

    claimForm: {
      edit: 'Redigera: ',
      create: 'Skapa sida',
      cancel: 'Avbryt',
      publish: 'Publicera',
      save: 'Spara',
      titleLabel: 'Titel',
      titlePlaceholder: 'Ange en handbokstitel',
      descriptionLabel: 'Beskrivning',
      descriptionPlaceholder: 'Ange en handbokbeskrivning',
      contentLabel: 'Innehåll',
      contentPlaceholder: 'Ange ett handboksinnehåll',
      cardColorLabel: 'Kortfärg',
      cardColorPlaceholder: 'Ange en kortfärg',
      departmentLabel: 'Avdelning/Affärsenhet/Team',
      officeLabel: 'Kontor',
      jobTitleLabel: 'Jobbtitel',
      groupProjectLabel: 'Grupp',
      userLabel: 'Användare',
      checkBox: 'Alla användare',
      placeholderMultiSelect: 'Välj...',
    },

    actionsPlan: {
      goBackToActionsPlan: 'TILLBAKA TILL HANDELPLAN LISTA',
    },

    virtualAssistant: {
      create: 'Skapa Virtuell assistent',
      goBackToVirtualAssistant: 'TILLBAKA TILL AI ASSISTANT LISTA',
    },

    instructions: {
      create: 'Skapa Instruktion Lista',
      goBackToInstructions: 'TILLBAKA TILL INSTRUKTIONER LISTA',
    },

    support: {
      create: 'Skapa Support',
      goBackToSupport: 'TILLBAKA TILL SUPPORT ',
    },

    hero: {
      description:
        'Arbetsmiljöarbete skapar förutsättningar för välbefinnande, engagemang och prestation. Ett systematiskt arbetsmiljöarbete innebär ett strukturerat tillvägagångssätt för att hantera och förbättra arbetsmiljön. I dess kärna ingår kontinuerliga undersökningar, riskbedömningar, implementering av förebyggande åtgärder samt identifiering av positiva aspekter och stärkande av dessa. Kort sagt handlar ett systematiskt arbetsmiljöarbete om att skapa en hållbar, dynamisk arbetsplats där både organisationen och dess anställda kan trivas.',
      header: 'Välkommen till ',
      subHeaderHandbook: 'Här hittar du Handböcker.',
      subHeaderCourses: 'Här hittar du Kurser.',
      subHeaderLibrary: 'Här hittar du Bibliotek.',
      subHeaderInvestigations: 'Här hittar du Riskbedömning.',
      subHeaderActionsPlan: 'Här hittar du Handlingsplan.',
      subHeaderClaims: 'Här hittar du Rapportering.',
      subHeaderVirtualAssistant: 'Här hittar du AI assistant.',
      subHeaderInstructions: 'Här hittar du Instruktioner.',
      subHeaderSupport: 'Här hittar du Support.',
      subHeaderDashboard: 'Här hittar du Dashboard.',
      header_22: ', välkommen till ',
      name: 'Arbetsmiljö portalen',
    },

    errorLoadingUsers: 'Fel vid laddning av användare',
    users: {
      searchPlaceholder: 'Sök',
      newUserButton: 'Ny användare',
      edit: 'Redigera användare',
      create: 'Skapa användare',
      cancel: 'Avbryt',
      save: 'Spara',
      firstname: 'Förnamn',
      firstnamePlaceholder: 'Skriv här',
      lastname: 'Efternamn',
      lastnamePlaceholder: 'Skriv här',
      username: 'Användarnamn',
      usernamePlaceholder: 'Skriv här',
      email: 'E-postadress',
      emailPlaceholder: 'Skriv här',
      locale: 'Språk',
      localePlaceholder: 'Välj här',
      role: 'Roll',
      rolePlaceholder: 'Välj här',
      department: 'Avdelning',
      departmentPlaceholder: 'Välj här',
      office: 'Kontor',
      officePlaceholder: 'Välj här',
      collar: {
        blue: 'Blå krage',
        white: 'Vit krage',
      },

      roleDescriptions: {
        super_admin: 'Super Admin',
        admin: 'Administratör',
        power_user: 'Power User',
        course_manager: 'Kursansvarig',
        manager: 'Chef',
        content_manager: 'Innehållsansvarig',
        team_leader: 'Teamledare',
        user: 'Användare',
      },
    },
  },
}

export default sv
