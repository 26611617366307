import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CompanyState {
  companyId: number | null;
}

const initialState: CompanyState = {
  companyId: 1, // Set default companyId to 1
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyId(state, action: PayloadAction<number | null>) {
      state.companyId = action.payload;
    },
  },
});

export const { setCompanyId } = companySlice.actions;

export default companySlice.reducer;
