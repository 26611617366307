const palette = {
  primary: '#aa1167',
  primaryHover: '#DDC73A',
  primaryActive: '#C4B134',

  // functional colors
  statusRed: '#C52C1A',
  statusRedHover: '#A12018',
  statusRedActive: '#C4635D',
  statusRedDisabled: '#E2958C',

  statusGreen: '#39BB5B',
  statusGreenHover: '#1B9D3D',
  statusGreenActive: '#5EC278',
  statusGreenDisabled: '#8FD9A3',

  statusBlue: '#0793E0',

  // contrast colors
  contrastHigh: '#191B1F',
  contrastMedium: '#797F87',
  contrastLow: '#8A8A8E',
  visualCue: '#A5A9AF',

  // additional colors
  stroke: '#D2D4D7',
  windowBackground: '#FFFFFF',
  shape: 'rgb(240, 237, 241, 0.28)',
  background: '#F3F5F7',
  transparent: 'transparent',
} as const

export default palette
