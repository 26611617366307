// IntlProviderWrapper.tsx
import React, { createContext, useContext, useState, ReactNode, FC } from 'react';
import { IntlProvider } from 'react-intl';
import { createIntl, IntlShape } from '@formatjs/intl';
import intl, { setLocale as setIntlLocale, messages } from './index';

interface IntlProviderWrapperProps {
  children: ReactNode;
}

const IntlContext = createContext<{
  intl: IntlShape;
  setLocale: (locale: 'en' | 'sv') => void;
}>({
  intl,
  setLocale: () => {},
});

export const useIntlContext = () => useContext(IntlContext);

export const IntlProviderWrapper: FC<IntlProviderWrapperProps> = ({ children }) => {
  const [currentIntl, setIntl] = useState<IntlShape>(intl);

  const setLocale = (locale: 'en' | 'sv') => {
    console.log(messages[locale]);
    setIntlLocale(locale);
    setIntl(createIntl({ locale, messages: messages[locale] }));
  };

  return (
    <IntlContext.Provider value={{ intl: currentIntl, setLocale }}>
      <IntlProvider locale={currentIntl.locale} messages={currentIntl.messages}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
