import React, { useState } from 'react';
import { LuX } from 'react-icons/lu';
import {
  Overlay,
  Modal,
  CloseButton,
  Form,
  Label,
  Button,
  Message,
} from './CopyCoursePopup.styles'; // Assuming you have a Message styled-component for green/red messages
import { useIntlContext } from '../../locale/IntlProviderWrapper';
import { useGetAllCompaniesQuery } from '../../slices/companiesApiSlice';
import { formatMessage } from '../../locale';
import { MultiSelect } from '../../ui-kit/MultiSelect/MultiSelect';
import { useCopyCourseToOtherCompanyMutation } from '../../slices/coursesApiSlice';

interface CopyCoursePopupProps {
  courseId: number;
  onClose: () => void;
  onCopySuccess: () => void;
}

const CopyCoursePopup: React.FC<CopyCoursePopupProps> = ({ courseId, onClose, onCopySuccess }) => {
  const { intl } = useIntlContext();
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [messages, setMessages] = useState<{ success: boolean; text: string }[]>([]);
  const { data: companies, error, isLoading } = useGetAllCompaniesQuery({});

  const [copyCourseToOtherCompany] = useCopyCourseToOtherCompanyMutation();

  const handleCopy = async () => {
    const newMessages = [];

    let companiesMap :any = {};

    companies?.data?.forEach((c:any)=>{companiesMap[c.id] = c.name});



    for (const companyId of selectedCompanies) {
      try {
        await copyCourseToOtherCompany({ id: courseId, companyId }).unwrap();
        newMessages.push({ success: true, text: `To ${companiesMap[companyId]} was successfully copied.` });
      } catch (err:any) {
        console.log(err);
        newMessages.push({ success: false, text: `To ${companiesMap[companyId]} encountered an error during copy - ${err.data.message}` });
      }
    }
    setMessages(newMessages);
    onCopySuccess(); // Optionally refresh the courses or perform any other action
  };

  const handleCompanyChange = (field: string, value: string[]) => {
    setSelectedCompanies(value);
  };

  return (
    <Overlay>
      <Modal>
        <CloseButton onClick={onClose}>
          <LuX size={24} />
        </CloseButton>
        <Form>
          <label style={{fontSize: 23, marginBottom: 10}}>{formatMessage({ id: 'courses.copyToCompany', defaultMessage: 'Copy course to other company' })}</label>
            <>
              <MultiSelect
                label={formatMessage({ id: 'courses.multiSelectLabel', defaultMessage: 'Select Companies' })}
                name="selected_companies"
                options={companies?.data?.map((company: any) => ({ value: company.id, label: company.name })) || []}
                values={selectedCompanies}
                onChange={handleCompanyChange}
              />
              {messages.map((message, index) => (
                <Message key={index} success={message.success}>
                  {message.text}
                </Message>
              ))}
              <Button type="button" onClick={handleCopy}>
                {formatMessage({ id: 'courses.copy', defaultMessage: 'Copy' })}
              </Button>
            </>
        </Form>
      </Modal>
    </Overlay>
  );
};

export default CopyCoursePopup;
