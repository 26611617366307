import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Button } from '../../../../ui-kit'
import styles from './DatePicker.module.css'
import {
  FormPaper,
  Content,
  Title,
  Actions,
  CheckboxWrapper,
  LabelWrapper,
} from './Schedule.styles'
import { useUpdateCourseScheduleMutation } from '../../../../slices/coursesApiSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { formatMessage } from '../../../../locale'
import { useIntlContext } from '../../../../locale/IntlProviderWrapper';
interface ScheduleProps {
  course?: any;
  onChanged?: () => void;
}

const Schedule: React.FC<ScheduleProps> = ({ course, onChanged }) => {
  const [updateCourseSchedule] = useUpdateCourseScheduleMutation()
  const { canEditCourse } = useSelector((state: RootState) => state.auth)
  const [noEndDate, setNoEndDate] = useState<boolean>(course?.expired_at === '' || course?.expired_at === null)

  const initialValues = {
    started_at: course?.started_at ? new Date(course.started_at) : null,
    expired_at: course?.expired_at ? new Date(course.expired_at) : null,
  }

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await updateCourseSchedule({
        id: course.id,
        data: {
          started_at: values.started_at?.toISOString().split('T')[0],
          expired_at: noEndDate ? '' : values.expired_at?.toISOString().split('T')[0],
        },
      }).unwrap()
      onChanged?.()
    } catch (error) {
      console.error('Failed to update schedule:', error)
    }
  }

  const handleNoEndDateChange = () => {
    setNoEndDate(!noEndDate)
  }

  return (
    <FormPaper>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Title>
              {formatMessage({ id: 'schedule.title', defaultMessage: 'Edit Schedule' })}
            </Title>
            <Content>
              <LabelWrapper>
                <label htmlFor="started_at">
                  {formatMessage({ id: 'schedule.startedAt', defaultMessage: 'Start Date *' })}
                </label>
                <DatePicker
                  selected={values.started_at}
                  onChange={(date) => setFieldValue('started_at', date)}
                  name="started_at"
                  dateFormat="yyyy-MM-dd"
                  className={styles.datePicker}
                  calendarClassName={styles.calendar}
                  autoComplete="off"
                />
              </LabelWrapper>
              <CheckboxWrapper>
                <input
                  type="checkbox"
                  id="noEndDate"
                  checked={noEndDate}
                  onChange={handleNoEndDateChange}
                />
                <label htmlFor="noEndDate">
                  {formatMessage({ id: 'schedule.noEndDate', defaultMessage: 'No End Date' })}
                </label>
              </CheckboxWrapper>
              {!noEndDate && (
                <LabelWrapper>
                  <label htmlFor="expired_at">
                    {formatMessage({ id: 'schedule.expiredAt', defaultMessage: 'End Date' })}
                  </label>
                  <DatePicker
                    selected={values.expired_at}
                    onChange={(date) => setFieldValue('expired_at', date)}
                    name="expired_at"
                    dateFormat="yyyy-MM-dd"
                    minDate={values.started_at || new Date()}
                    className={styles.datePicker}
                    calendarClassName={styles.calendar}
                    autoComplete="off"
                  />
                </LabelWrapper>
              )}
            </Content>
            {canEditCourse && (
              <Actions>
                <Button appearance="primary" type="submit">
                  {formatMessage({ id: 'schedule.saveChanges', defaultMessage: 'Save Changes' })}
                </Button>
              </Actions>
            )}
          </Form>
        )}
      </Formik>
    </FormPaper>
  )
}

export default Schedule
