import styled from 'styled-components'
import ButtonBase from '../base/ButtonBase'
import size from '../css/size'

const ButtonTransparent = styled(ButtonBase)`
  background-color: transparent;

  :hover {
    background-color: ${({ theme }) => theme.palette.shape};
  }

  :active {
    background-color: ${({ theme }) => theme.palette.shape};
  }

  :disabled {
    background-color: transparent;
  }

  ${size}
`

export default ButtonTransparent
