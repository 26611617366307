import styled from 'styled-components'

const Container = styled.div<{ size?: 'lg' | 'md' | 'sm' }>`
  width: 100%;
  margin: auto;
  padding: 0 1rem;

  ${({ size }) =>
    size === 'lg' &&
    `
    max-width: 86rem;
  `}

  ${({ size }) =>
    size === 'md' &&
    `
    max-width: 71.37rem;
  `}

  ${({ size }) =>
    size === 'sm' &&
    `
    max-width: 45.25rem;
  `}
`

export default Container
