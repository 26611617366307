import { apiSlice } from "./apiSlice";

const jobTitleApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllJobTitles: builder.query({
            query: () => ({
                url: "/api/v1/admin/companies/job-titles",
                method: "GET",
            }),
        }),
        viewJobTitle: builder.query({
            query: ({ id }) => ({
                url: `/api/v1/admin/companies/job-titles/${id}`,
                method: "GET",
            }),
        }),
        createJobTitle: builder.mutation({
            query: ({ data }) => ({
                url: `/api/v1/admin/companies/job-titles`,
                method: "POST",
                body: data,
            }),
        }),
        updateJobTitle: builder.mutation({
            query: ({ id, data }) => ({
                url: `/api/v1/admin/companies/job-titles/${id}`,
                method: "PUT",
                body: data,
            }),
        }),
        deleteJobTitle: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/admin/companies/job-titles/${id}`,
                method: "DELETE",
            }),
        }),
    }), 
});

export const {
    useGetAllJobTitlesQuery,
    useViewJobTitleQuery,
    useCreateJobTitleMutation,
    useUpdateJobTitleMutation,
    useDeleteJobTitleMutation,
} = jobTitleApiSlice;