import React from 'react';
import '@fontsource/roboto/500.css';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineEmail } from "react-icons/md";
import { MdLanguage } from "react-icons/md";
import { FaIdCardAlt } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import './Css/ProfileDetails2.css';
import { useIntlContext } from '../../../locale/IntlProviderWrapper';
import { LuGroup, LuUser, LuUser2 } from 'react-icons/lu'
import { useSelector } from 'react-redux';
interface ProfileInfoProps {
  label: string;
  value: any;
}

const labels: { [key: string]: { [key: string]: string } } = {
  en: {
    Username: 'Username',
    Email: 'Email',
    Language: 'Language',
    Role: 'Role',
    Department: 'Department',
    Office: 'Office',
    JobTitle: 'Job title',
    ProjectGroup: 'Project group',
  },
  sv: {
    Username: 'Användarnamn',
    Email: 'E-postadress',
    Language: 'Språk',
    Role: 'Roll',
    Department: 'Avdelning',
    Office: 'Kontor',
    JobTitle: 'Jobbtitel',
    ProjectGroup: 'Projektgrupp',
  }
};

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ label, value }) => {
  const { intl, setLocale } = useIntlContext();
  const selectedLabels = labels[intl.locale];
  const { userCompany } = useSelector((state: any) => state.auth);

  const displayValue = () => {
    if (intl.locale === 'en' && (label === 'Department' || label === 'Office' || label === 'JobTitle' || label === 'ProjectGroup')) {
      return !value || value.trim() === '' ? 'Not chosen' : value;
    } else if (intl.locale === 'sv' && (label === 'Department' || label === 'Office' || label === 'JobTitle' || label === 'ProjectGroup')) {
      return !value || value.trim() === '' ? 'Inte vald' : value;
    }
    return value;
  };
  const color = userCompany?.main_color || '#A4366D';

  return (
    <div style={{ alignSelf: 'flex-start' }}>
      <div className='styledDiv'>
        <label>{selectedLabels[label]}</label>
        <div className='divContet' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='placeholder'>
            {label === 'Username' ? <CgProfile size={20} style={{ color: color, alignSelf: 'center' }} /> :
              label === 'Email' ? <MdOutlineEmail size={20} style={{ color: color, alignSelf: 'center' }} /> :
              label === 'Language' ? <MdLanguage size={20} style={{ color: color, alignSelf: 'center' }} /> :
              label === 'Role' ? <FaIdCardAlt size={20} style={{ color: color, alignSelf: 'center' }} /> :
              label === 'Department' ? <FaPeopleGroup size={20} style={{ color: color, alignSelf: 'center' }} /> :
              label === 'Office' ? <HiOutlineBuildingOffice2 size={20} style={{ color: color, alignSelf: 'center' }} /> :
              label === 'JobTitle' ? <LuUser size={20} style={{ color: color, alignSelf: 'center' }} /> :
              label === 'ProjectGroup' ? <LuGroup size={20} style={{ color: color, alignSelf: 'center' }} /> :
              null
            }
            {label === 'Language' ? (
           <select value={intl.locale}  className='no-border' onChange={(e) => setLocale(e.target.value as 'en' | 'sv')}>
           <option value="en">English</option>
           <option value="sv">Svenska</option>
         </select>
            ) : (
                <p>
                {displayValue()}
            </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};